import React from 'react'
import styled from "styled-components";
import { grey1, grey10, primary2, subC } from '../../../constants/color';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  scales,
} from 'chart.js';
import { Chart , Bar} from 'react-chartjs-2';
import { instanceAxios } from '../../../api/axios';
import ErrorLabel from '../../../error/ErrorLabel';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);



export default function SubscribersBox({response, startErr, endErr}) {
  const labels = response.map(ele => ele.title);
  const options = {
    // scales: {
    //   y: {
    //     min: 0,
    //     ticks: {
    //       stepSize: 10,
    //     },
    //   },
    // },
  }
  const data = {
    labels,
    datasets: [
      {
        type: 'line',
        label: '오늘 구독자 수',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 2,
        fill: false,
        data:  response.map(ele => ele.count),
      },
      
      {
        type: 'bar',
        label: '총 구독자 수',
        stepSize: 1,
        backgroundColor: 'rgb(167, 210, 245)',
        data:  response.map(ele => ele.total_count),
        borderColor: 'white',
        borderWidth: 2,
      },
    ],
  };

  return (
    <ProceedingSection>
    <BottomH3>PUSH 구독자 수</BottomH3>
    {startErr.length > 0 && <ErrorLabel data={startErr}/>}  
    {endErr.length > 0 && <ErrorLabel data={endErr}/>}  
    <GraphBox>      
      <Chart type='bar' data={data}  style={{width: "800px", margin:"0 auto"}} options={options}/>
    </GraphBox>
  </ProceedingSection>
  )
}
const ProceedingSection = styled.section`
  width: calc(100%);
  box-sizing: border-box;
`

const BottomH3 = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${grey10};
  padding: 0 0 16px;
  position: relative;
  width: fit-content;
  display: block;
  
  ::after {
    display: block;
    position: absolute;
    content: '';
    left: -5px;
    top: 10px;
    background: ${primary2};
    height: 10px;
    width: 100%;
    opacity: 0.4;
    padding: 0 5px;
    z-index: -1;
  }
`

const GraphBox = styled.article`
  background: ${grey1};
  box-shadow: 4px 4px 14px rgba(0,0,0,0.16);
  padding: 10px;
  border-radius: 16px;
  /* height: 300px; */
`
