import { SketchPicker } from "react-color";
import { grey1, grey4, grey5, grey7 } from "../constants/color";
import styled from "styled-components";


function ColorPicker({color,changeColor ,setOpen, isOpen }) {
  const presetColors= ['#EB144C','#FF6900', '#FCB900','#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#F78DA7', '#cbb6fd', '#d9e3f0'];

  return (
    <div>
      <DefaultColorBox style={{background: color}} onClick={setOpen}></DefaultColorBox>
      {isOpen &&
        <PickerPositionDiv>
          <SketchPicker
            presetColors={presetColors}
            color={color}
            onChangeComplete={changeColor}
          />
        </PickerPositionDiv>
      }
    </div>
  )
}


export default ColorPicker

const DefaultColorBox = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  border: 3px solid ${grey4};
  cursor: pointer;
`
const PickerPositionDiv = styled.div`
  position: absolute;
  left: 60px;
  top: 0;
  z-index: 1;
`