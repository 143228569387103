import styled from "styled-components";
import SizeSlider from "../../slider";
import { error3, grey5, grey7, grey8, primary1, primary2, primary3 } from "../../../constants/color";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Temp_bg, Temp_opacity, Temp_position, Temp_shape, Temp_size } from "../../../atom/templeteAtom";
import { useEffect } from "react";
import { useState } from "react";
import ColorPicker from "../../ColorPicker";



export const TemplateLayout = ({typeCode}) => {
  const [temp_position, set_temp_position] = useRecoilState(Temp_position);
  const [temp_shape,set_temp_shape] = useRecoilState(Temp_shape);
  const [temp_opacity,set_temp_opacity] = useRecoilState(Temp_opacity);
  const [temp_size,set_temp_size] = useRecoilState(Temp_size);
  const [opacity, setOpacity] = useState(temp_opacity);
  const [shape, setShape] = useState(temp_shape);
  const [width, setWidth] = useState(temp_size.width);
  const [height, setHeight] = useState(temp_size.height);

  const [temp_bg, set_temp_bg] = useRecoilState(Temp_bg); 
  const [bgPicker, setbgPicker] = useState(false);
  const changeBgColor = (color) => {
    set_temp_bg(color.hex);
  };

  const closeColorPicker = (e) => {
    if(bgPicker) setbgPicker(false);
    return false;
  };

  
  const handlePosition = (e) => {
    console.log(temp_position)
    if(e.target.id === 'top-left') {
      set_temp_position({top :'0', left: '0', bottom: 'unset', right: 'unset', transform: 'translate(0,0)'});
    }
    if(e.target.id === 'top-right') {
      set_temp_position({top :'0', left: 'unset', bottom: 'unset', right: '0', transform: 'translate(0,0)'});
    }
    if(e.target.id === 'bottom-left') {
      set_temp_position({top :'unset', left: '0', bottom: '0', right: 'unset', transform: 'translate(0,0)'});
    }
    if(e.target.id === 'bottom-right') {
      set_temp_position({top :'unset', left: 'unset', bottom: '0', right: '0', transform: 'translate(0,0)'});
    }
  }


  // 모양
  useEffect(() => {
    set_temp_shape(shape);
  }, [shape]);
  // 투명도
  useEffect(() => {
    set_temp_opacity(opacity);
  }, [opacity]);
  // 크기  
  useEffect(() => {
    set_temp_size({width: width.toString(), height: height.toString()});
  }, [width, height]);



  return (
    <WrapCont onClick={closeColorPicker}>
      <div style={{position: 'relative', width:'fit-content', zIndex: '1'}}>
        <SubTitle>레이아웃 설정</SubTitle>
      </div>
      <AlignSet>
        <SetTitle>너비</SetTitle>
        <WrapController>
          <SizeSlider setValue={setWidth} totalValue={parseInt(width)} max={500} min={0} stepUnit={1} defaultValue={250} unit={'PX'}/>
        </WrapController>
      </AlignSet>
      <AlignSet>
        <SetTitle>높이</SetTitle>
        <WrapController>
          <SizeSlider setValue={setHeight}  totalValue={parseInt(height)}  max={500} min={0} stepUnit={1} defaultValue={250}  unit={'PX'}/>
        </WrapController>
      </AlignSet>
      <AlignSet>
        <SetTitle>위치</SetTitle>
        <WrapController style={{display: "flex"}} onClick={handlePosition}>
          <WrapRadio>
            <RadioInput type="radio" name="position" value="0" id="top-left" />
            <Label htmlFor="top-left">좌측 상단</Label>
          </WrapRadio>
          <WrapRadio>
            <RadioInput type="radio" name="position" value="16px" id="top-right"/>
            <Label htmlFor="top-right">우측 상단</Label>
          </WrapRadio>
          <WrapRadio>
            <RadioInput type="radio" name="position" value="50%" id="bottom-left"/>
            <Label htmlFor="bottom-left">좌측 하단</Label> 
          </WrapRadio>
          <WrapRadio>
            <RadioInput type="radio" name="position" value="50%" id="bottom-right"/>
            <Label htmlFor="bottom-right">우측 하단</Label> 
          </WrapRadio>
        </WrapController>
      </AlignSet>
    <AlignSet>
      <SetTitle>배경 색상</SetTitle>
      <WrapController>
        <ColorPicker color={temp_bg} setColor={set_temp_bg} isOpen={bgPicker} setOpen={() => setbgPicker(true)} changeColor={changeBgColor}/>
      </WrapController>
    </AlignSet>
      <AlignSet>
        <SetTitle>모서리</SetTitle>
        <WrapController style={{display: "flex"}}>
          <SizeSlider setValue={setShape} totalValue={parseInt(shape)} max={100} min={0} stepUnit={1} defaultValue={0}/>
        </WrapController>
      </AlignSet>
      <AlignSet>
        <SetTitle>투명도</SetTitle>
        <WrapController>
          <SizeSlider setValue={setOpacity} totalValue={parseInt(opacity)} max={1} min={0} stepUnit={0.01} defaultValue={1}/>
        </WrapController>
      </AlignSet>
    </WrapCont>    
  )
}

const WrapCont = styled.div`
  margin: 40px 0 80px;
`
const AlignSet = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  gap: 20px;
  margin: 0 0 60px;
`
const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: ${grey8};
  width: 100%;
  padding-bottom: 40px;
  ::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    top: 8px;
    padding: 0 10px;
    background: ${primary1};
    width:100%;
    height: 14px;
    opacity: 0.7;
    z-index: -1;
  }
`
const SetTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: ${grey8};
  width: 200px;
`
const WrapController = styled.div`
  width: 100%;
  position: relative;
`


// ------------ 라디오 css -----------
const Label = styled.label`
  font-size: 16px;
  margin-right: 14px;
  cursor: pointer;
`
  
const RadioInput = styled.input`
  appearance: none;
  border: max(2px, 0.1em) solid gray;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  transition: border 0.2s ease-in-out;

  &:checked {
    border: 0.4em solid ${primary3};
  }
  
  &:focus-visible {
    outline-offset: max(2px, 0.1em);
    outline: max(2px, 0.1em) dotted  ${primary3};
  }

  &:hover {
    box-shadow: 0 0 0 max(4px, 0.2em) lightgray;
    cursor: pointer;
    }

`
const WrapRadio = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const NoticeP = styled.p`
  font-size: 12px;
  color: ${grey7};
  line-height: 1.2;
  padding-top: 10px;
`