import styled from "styled-components";
import { InsertScriptBox } from "../../components/containers/homepage/HomepageBox";
import Layout from "../../templates/Layout";
import { DropboxInput, InputGroup } from "../../components/inputs/InputGroups";
import UpdateProfile from "../../components/buttons/ProfileButtons";
import { instanceAxios } from "../../api/axios";
import { useEffect, useRef, useState } from "react";
import {
  grey1,
  grey4,
  primary4,
  grey3,
  grey8,
  grey5,
  grey11,
  primary3,
} from "../../constants/color";
import {
  SelectedHomepage,
  SelectHomepage,
  AfterCopy,
  BeforeCopy,
} from "../../components/buttons/HompageButtons";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AlertMessage,
  IsAlertOpen,
  MyProject,
  MyPushProject,
} from "../../atom/Atom";
import eventImg from '../../assets/images/img-event01.png';
import insertDemo from '../../assets/images/img-insert-demo.png';
import arrow from "../../assets/images/ico-arrow-small.png"
// 탑 메뉴
import layoutIcon from "../../assets/images/ico-layout.svg"
import imageIcon from "../../assets/images/ico-img.svg"
import textIcon from "../../assets/images/ico-text.svg"
import btnIcon from "../../assets/images/ico-clickBtn.svg"

import { useCallback } from "react";
import { PushPopup } from "../../components/pushPopup/PushPopup";
import { CategoryDropbox, EmailDropbox, TemplateDropbox } from "../../components/dropbox/dropbox";
import  {TemplateLayout}  from "../../components/containers/template/TemplateLayout";
import TemplateImage from "../../components/containers/template/TemplateImage";
import TemplateText from "../../components/containers/template/TemplateText";
import TemplateButton from "../../components/containers/template/TemplateButton";
import zIndex from "@mui/material/styles/zIndex";
import { useMemo } from "react";
import { Temp_position } from "../../atom/templeteAtom";


export default function InsertPush() {
  const [myProject, setMyProject] = useRecoilState(MyProject);
  const [myPushProject, setMyPushProject] = useRecoilState(MyPushProject);
  const [pid, setPid] = useState(myPushProject.pid);

  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);

  // script
  const [script, setScript] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [copyScript, setCopyScript] = useState("");

  const getOneHomepage = async () => {
    try {
      const response = await instanceAxios.get(`/${pid}`);
      if (response.status === 200) {
        setMyPushProject(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (pid) {
      getOneHomepage();
    }
  }, [pid]);

  const handleGetScript = async () => {
    try {
      const response = await instanceAxios.get(`/${pid}/resource`);
      console.log(response);
      if (response.status === 200) {
        console.log("출력하기 성공");
        setScript(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (pid) {
      handleGetScript();
    }
  }, [pid]);

  useEffect(() => {
    setCopyScript(
      // `<script>let dmpush_title = "${title}";</script> \n` +
      //   `<script>let dmpush_content = "${content}";</script> \n` +
        script
    );
  }, [title, content, script]);

  const handleCopyScript = (text) => {
    try {
      navigator.clipboard.writeText(text);
      setIsAlertOpen(true);
      setAlertMessage("클립보드에 복사되었습니다.");
      if(!title || !content) {
        // setIsAlertOpen(true);
        // setAlertMessage("타이틀과 내용을 작성해주세요.");
      } else {
        // navigator.clipboard.writeText(text);
        // setIsAlertOpen(true);
        // setAlertMessage("클립보드에 복사되었습니다.");
      }
    } catch (error) {
      setIsAlertOpen(true);
      setAlertMessage("복사에 실패하였습니다. 다시 시도해주세요.");
    }
  };

  const handleCopytextAreaValue = () => {
    setCopyScript(
      `<script>let dmpush_title = "${title}";</script>` +
      `<script>let dmpush_content = "${content}";</script>` +
      script
    );
  };

  const handleRenderBtns = () => {
    return (
      <>
        {myProject?.map(({ name, pid }) => {
          if (pid === myPushProject.pid) {
            return (
              <li key={pid}>
                <SelectedHomepage
                  setValue={() => {
                    setPid(pid);
                  }}
                >
                  {name}
                </SelectedHomepage>
              </li>
            );
          } else {
            return (
              <li key={pid}>
                <SelectHomepage
                  setValue={() => {
                    setPid(pid);
                  }}
                >
                  {name}
                </SelectHomepage>
              </li>
            );
          }
        })}
      </>
    );
  };


  // -------type 관리-------
  const arrList = [
    {name: "버튼 타입", code: "1"},
    {name: "사진 타입", code: "2",},
  ];
  const [type, setType] = useState(arrList[0].name);
  const [typeCode, setTypeCode] = useState(arrList[0].code);
  const [openTypeDrop, setOpenTypeDrop] = useState(false);
  const handleType = (e) => {
    setType(e.target.value);
    setTypeCode(e.target.id)
    setOpenTypeDrop(false);
  }
  // -------- 템플릿 메뉴 리스트 ----------
  const [menuType, setMenuType] = useState('layout');

  const temp_position = useRecoilValue(Temp_position);
  const [showDemo1, setShowDemo1] = useState(true);
  const [showDemo2, setShowDemo2] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(window.scrollY);

  useEffect(() => {
    document.addEventListener('scroll', scrollEvent, {passive: true})
  }, [scrollHeight])
  
  const scrollEvent = () => {
    if( 760 > scrollHeight > 0) setScrollHeight(window.scrollY);
    if( scrollHeight > 750 ) {
      setShowDemo1(false);
      setShowDemo2(true);
      return;
    } else {
      setShowDemo1(true);
      setShowDemo2(false);
      return;
    } 
  }
  return (
    <Layout>
      <InsertScriptBox>
        <TopAlign>
          <WrapHomepages>{handleRenderBtns()}</WrapHomepages>
          {/* <GetScript>출력하기</GetScript> */}
        </TopAlign>
        {/* 오픈 팝업 데모 */}
        {/* <DemoArticle>
          {title && <DemoTitle>{title}</DemoTitle>}
          {!title && <DemoTitle default>타이틀</DemoTitle>}
          {content && <DemoCont>{content}</DemoCont>}
          {!content && <DemoCont default>내용</DemoCont>}

          {content && <DemoCont readOnly value={content} placeholder="내용"></DemoCont>}
          <DemoBtnAlign>
            <DemoBtn>다음에</DemoBtn>
            <DemoBtn>알림 받기</DemoBtn>
          </DemoBtnAlign>
         
        </DemoArticle> */}
        <DemoArticle style={{}}>
          <LabelStyle>데모로 보기</LabelStyle>
            <WrapDemoImg>
              <DemoSiteImg src={insertDemo} alt="" />
              {showDemo1 &&
                <PushPopup typeCode={typeCode} />
              }
            </WrapDemoImg>
        </DemoArticle>
          
        {showDemo2 && 
          <div style={{position: 'fixed', top:temp_position.top, left:temp_position.left, right: temp_position.right, bottom: temp_position.bottom, width: '100%', zIndex: 50 }}>
            <PushPopup typeCode={typeCode} />
          </div>
        }


        <DemoArticle>
          <DemoFlex>
            <TypeClickInput>
              <DropboxInput
              type="text"
              value={type}
              id="type"
              readOnly={true}
              placeholder={'템플릿 타입'}
              handleClick={() => {setOpenTypeDrop(openTypeDrop ? false : true)}}
              />
              {openTypeDrop &&
              <CategoryInputArrow up src={arrow} alt="" />              
              }
              {!openTypeDrop &&
                <CategoryInputArrow src={arrow} alt="" />              
              }
            </TypeClickInput>
            {openTypeDrop && 
              <TemplateDropbox 
                arrList={arrList} 
                ver="42px"
                hor="-1px"
                width="208px"
                handleClick={handleType}
              />
            }
            <MenuList>
              <MenuLi onClick={() => setMenuType('layout')}>
                <MenuIcon src={layoutIcon} alt="레이아웃 아이콘" typeCode={typeCode}/>
                <p>레이아웃</p>
              </MenuLi>
              {typeCode == 1 && 
                <MenuLi onClick={() => setMenuType('text')}>
                  <MenuIcon src={textIcon} alt="텍스트 아이콘" />
                  <span>텍스트</span>
                </MenuLi>
              }
              <MenuLi onClick={() => setMenuType('image')}>
                <MenuIcon src={imageIcon} alt="이미지 아이콘" />
                <span>이미지</span>
              </MenuLi>
              {typeCode == 1 && 
                <MenuLi onClick={() => setMenuType('button')}>
                  <MenuIcon src={btnIcon} alt="버튼 설정 아이콘" />
                  <span>버튼</span>
                </MenuLi>
              }
            </MenuList>
          </DemoFlex>
        </DemoArticle>
        {/* 컴포넌트 */}
        <DemoArticle>
          {/* 레이아웃 */}
          {menuType === 'layout' && <TemplateLayout />}
          {/* 텍스트 */}
          {menuType === 'text' && <TemplateText />}
          {/* 이미지 */}
          {menuType === 'image' && <TemplateImage />}
          {/* 버튼 */}
          {menuType === 'button' && <TemplateButton />}
        </DemoArticle>
     
        {/* <WrapInputs>
          <LabelStyle htmlFor="link">타이틀</LabelStyle>
          <div>
            <InputGroup 
            refname={titleRef}
            setValue={setTitle} 
            type="text" 
            placeholder="최대 28자 입력 가능"
            />
          </div>
        </WrapInputs>
        <WrapInputs>
          <LabelStyle htmlFor="link">내용</LabelStyle>
          <div>
            <ContentTxtarea 
            maxLength={100}
            placeholder="최대 98자 입력 가능"
            defaultValue={content}
            ref={contnetRef}
            onChange={(e) => {setContent(e.target.value); resizingWriting()}}
            ></ContentTxtarea>
          </div>
        </WrapInputs> */}



        {/* 스크립트 복사존 */}
        <WrapInputs>
          {copyScript &&
            <TxtBox readOnly={true} value={copyScript}></TxtBox>
          }
        </WrapInputs>
        <input
          onChange={handleCopytextAreaValue}
          type="text"
          value={copyScript}
          hidden
          readOnly
        />
        <WrapButton>
          {!script && <BeforeCopy>복사하기</BeforeCopy>}
          {script && (
            <AfterCopy
              handleCopyScript={() => {
                handleCopyScript(copyScript);
              }}
            >
              복사하기
            </AfterCopy>
          )}
        </WrapButton>
      </InsertScriptBox>
    </Layout>
  );
}

const WrapInputs = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const LabelStyle = styled.label`
  display: flex;
  font-size: 16px;
  margin-bottom: 10px;
`;
const WrapButton = styled.div`
  width: 180px;
  margin: 40px auto 40px;
`;

const TopAlign = styled.ul`
  display: flex;
  gap: 10px;
  position: relative;
  margin-bottom: 40px;
  justify-content: space-between;

  ::after {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 2px;
    background-color: ${grey4};
    bottom: -20px;
    left: 0;
  }
`;

const WrapHomepages = styled.ul`
  display: flex;
  gap: 10px;
  position: relative;
  align-items: center;
`;

const TxtBox = styled.textarea`
  width:calc(100% - 40px);
  height: -27px ;
  background-color: ${grey3};
  border-radius: 8px;
  padding: 20px;
  font-size: 14px;
  color: ${grey8};
  margin-top: -12px;
  border: none;
  overflow-y: scroll;
  display: block;
  resize: none;
  outline: none;
  
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${primary3};
    border-radius: 4px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: none;
    border-radius: 4px;
  }
`;
const ContentTxtarea = styled.textarea`
  width: 100%;
  height: 37px;
  border:  1px solid ${grey5};
  outline: none;
  border-radius: 4px;
  overflow: hidden;
  resize: none;
  padding: 10px 12px;
  box-sizing: border-box;
  :focus {
    border:  1px solid ${primary4};
  }
  ::placeholder {
    font-size: 12px;
  }
`
const DemoArticle = styled.article`
  position: relative;
  width:100%;
  height: auto;
  margin: 20px auto;
  box-sizing: border-box;
  background: ${grey1};
`
const DemoFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`
const MenuList = styled.ul`
  display: flex;
  align-items: center;
  justify-content:  flex-end;
  gap: 10px;
`
const MenuLi = styled.li`
  width: 120px;
  height: 38px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${grey5};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 12px;
  cursor: pointer;
`
const MenuIcon = styled.img`
  width: 20px;
`

const TypeClickInput = styled.div`
  display: flex;
  position: relative;
`

const CategoryInputArrow = styled.img`
  width: 10px;
  height: 10px;
  position: absolute;
  right: 13px;
  top: 15px;

  rotate: ${props => props.up ? "180deg" : null};;
`


const WrapDemoImg = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: ${grey1};
  border: 1px solid ${grey5};
  margin: 0 auto;
  overflow: hidden;
`
const DemoSiteImg = styled.img`
  width: 100%;
  height: 500px;
`
const DemoTitle = styled.p`
  color: ${(props) => (props.default ? `${grey5}` : `${grey11}`)};
  font-size: 18px;
  font-weight: 600;  
  padding-bottom:16px;
  word-wrap:normal;
  word-break: break-all;
  white-space:break-spaces;
`
const DemoCont = styled.p`
  color: ${(props) => (props.default ? `${grey5}` : `${grey11}`)};
  font-size: 16px; 
  white-space: pre-wrap;
  word-wrap:normal;
  word-break: break-all;
  width: 100%;
`
const DemoBtnAlign = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: center;
  gap: 12px;
`
const DemoBtn = styled.button`
  cursor: pointer;
  display: block;
  width: 90px;
  border: none;
  padding: 6px 8px;
  border-radius: 4px;
  background: ${primary4};
  color: ${grey1};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`
