import React, { useEffect, useState } from "react";
import Layout from "../../templates/Layout";
import styled from "styled-components";
import { PushListBoxs } from "../../components/containers/push/PushBox";
import activeCheck from "../../assets/images/active-check.png";
import inActiveCheck from "../../assets/images/inactive-check.png";
import {
  error3,
  grey1,
  grey10,
  grey5,
  grey7,
  grey8,
  grey9,
  primary3,
  primary5,
} from "../../constants/color";
import { useRecoilState } from "recoil";
import { AlertMessage, IsAlertOpen, MyPushProject, MyPushList } from "../../atom/Atom";
import Pagination from "../../components/pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { getPushList, handleDelete} from "../../api/message";

const PushList = () => {
  //React 요소
  const navigate = useNavigate();
  //현재 날짜
  const offset = 1000 * 60 * 60 * 9;
  const koreaNow = new Date(new Date().getTime() + offset).toISOString();

  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);
  const [myPushList, setMyPushList] = useRecoilState(MyPushList);

  //state
  const [myPushProject, setMyPushProject] = useRecoilState(MyPushProject);
  const [isReserve, setIsReserve] = useState(true);
  const [isProceed, setIsProceed] = useState(true);
  const [isComplete, setIsComplete] = useState(true);
  const [isAll, setIsAll] = useState(false);
  const [isFailed, setIsFailed] = useState(true);
  const [pushList, setPushList] = useState([]);
  const [filterList, setFilterList] = useState([
    {
      id: 1,
      state: "waiting",
    },
    {
      id: 2,
      state: "shipping",
    },
    {
      id: 3,
      state: "complete",
    },
    {
      id: 4,
      state: "failed",
    },
  ]);
  // 페이지네이션
  const [currentPage, setCurrrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  // const [custPageGroup, setCutPageGroup] = useState(0);

  const currentPosts = pushList
    .filter((item) => {
      for (var i = 0; i < filterList.length; i++) {
        if (item.state === filterList[i].state) return true;
      }
      return false;
    })
    .sort(function (a, b) {
      if (a.create_time > b.create_time) {
        return -1;
      }
      if (a.create_time < b.create_time) {
        return 1;
      }
      // a must be equal to b
      return 0;
    })
    .slice(firstPostIndex, lastPostIndex);
  const arryByState = pushList.filter((item) => {
    for (var i = 0; i < filterList.length; i++) {
      if (item.state === filterList[i].state) return true;
    }
    return false;
  });
  useEffect(() => {
    if (isReserve && isProceed && isComplete && isFailed) {
      setIsAll(true);
    }
    if (!isReserve || !isProceed || !isComplete || !isFailed) {
      setIsAll(false);
    }
  }, [isReserve, isProceed, isComplete, isFailed]);
  useEffect(() => {
    getPushList(myPushProject, setPushList);
  }, [myPushProject]);

  const handleReserve = () => {
    if (isReserve) {
      setIsReserve(false);
      setFilterList(filterList.filter((user) => user.id !== 1));
    } else {
      setIsReserve(true);
      setFilterList([
        ...filterList,
        {
          id: 1,
          state: "waiting",
        },
      ]);
    }
  };
  const handleProceed = () => {
    if (isProceed) {
      setIsProceed(false);
      setFilterList(filterList.filter((user) => user.id !== 2));
    } else {
      setIsProceed(true);
      setFilterList([
        ...filterList,
        {
          id: 2,
          state: "shipping",
        },
      ]);
    }
  };
  const handleComplete = () => {
    if (isComplete) {
      setIsComplete(false);
      setFilterList(filterList.filter((user) => user.id !== 3));
    } else {
      setIsComplete(true);
      setFilterList([
        ...filterList,
        {
          id: 3,
          state: "complete",
        },
      ]);
    }
  };
  const handleFailed = () => {
    if (isFailed) {
      setIsFailed(false);
      setFilterList(filterList.filter((user) => user.id !== 4));
    } else {
      setIsFailed(true);
      setFilterList([
        ...filterList,
        {
          id: 4,
          state: "failed",
        },
      ]);
    }
  };

  const handleAllClick = () => {
    if (isAll === false) {
      setIsAll(true);
      setFilterList([
        {
          id: 1,
          state: "waiting",
        },
        {
          id: 2,
          state: "shipping",
        },
        {
          id: 3,
          state: "complete",
        },
        {
          id: 4,
          state: "failed",
        },
      ]);
      if (isProceed === false) {
        setIsProceed(true);
      }
      if (isComplete === false) {
        setIsComplete(true);
      }
      if (isReserve === false) {
        setIsReserve(true);
      }
      if (isFailed === false) {
        setIsFailed(true);
      }
    } else {
      setFilterList([]);
      setIsProceed(false);
      setIsComplete(false);
      setIsReserve(false);
      setIsFailed(false);
      setIsAll(false);
    }
  };

  const renderAllPush = () => {
    if(currentPosts.length <= 0) {
      return (
        <tr>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td style={{padding:"60px 0"}}>
            작성한 메세지가 없습니다.
          </Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
        </tr>
      )
    } else {
      return currentPosts.map((item, index) => {
        return (
        <tr key={item.mid}>
          {/* 상태 */}
          {item.state === "complete" && (
            <Td status>발송완료</Td>
          )}
          {item.state === "waiting" && <Td status>예약중</Td>}
          {item.state === "shipping" && <Td status>진행중</Td>}
          {item.state === "failed" && <Td status>실패</Td>}
          {/* 제목 */}
          <Td title='true'>
          {item.title.length > 20
            ? item.title.substring(0, 20) + "..."
            : item.title}
          </Td>
          {/* 내용 */}
          <Td content='true'>
          {item.content.length > 36 ? item.content.substring(0, 36) + "..."
            : item.title }
          </Td>
          {/* 전체 */}
          <Td total='true'>
          {item.totalCount}
          </Td>
          {/* 성공 */}
          <Td success='true'>{(parseInt(item.totalCount) - parseInt(item.failedCount)).toLocaleString()}</Td>
          {/* 날짜*/}
          <Td date>
            {item.sendTime.replace("T", " ").substring(0, 16)}
          </Td>
          {/*상세보기, 삭제하기*/}
          <Td btns>
            {item.mid &&
              <TableBtnList>
                {/* <TableBtn>재전송</TableBtn> */}
                <TableBtn onClick={() => navigate(`/pushdetail/${item.mid}`)}>상세보기</TableBtn>
                {myPushProject.expiryDate ? null : <TableBtn delete  onClick={() => handleDelete(item.mid, myPushProject, setIsAlertOpen, setAlertMessage, setPushList)}>삭제하기</TableBtn>}
              </TableBtnList>
            }
          </Td>
        </tr>
        );
      });
    }
  };

  return (
    <Layout>
      <PageWrapper>
        {/* <Title>
          {myPushProject.name ? myPushProject.name : "프로젝트를 선택해주세요"}
          {myPushProject.expiryDate ? (
            <> {myPushProject.expiryDate.slice(0, 10)}에 삭제 예정입니다</>
          ) : null}
        </Title> */}
        <PageTitle>
          PUSH 리스트 
          <Message>고객들에게 전송한 웹푸시 리스트입니다</Message>
        </PageTitle>

       
        <PushListBoxs>
          <PushButtonWrapper>
            <RadioList>
              <RadioLi onClick={handleAllClick}>
                {!isAll && <RadioIcon src={inActiveCheck} alt="웹푸시 체크 아이콘" />}
                {isAll && <RadioIcon src={activeCheck} alt="웹푸시 체크 아이콘" />}
                전체
              </RadioLi>
              <RadioLi onClick={handleReserve}>
                {!isReserve && (
                  <RadioIcon src={inActiveCheck} alt="웹푸시 체크 아이콘" />
                )}
                {isReserve && (
                  <RadioIcon src={activeCheck} alt="웹푸시 체크 아이콘" />
                )}
                예약중
              </RadioLi>
              <RadioLi onClick={handleProceed}>
                {!isProceed && (
                  <RadioIcon src={inActiveCheck} alt="모바일푸시 체크 아이콘" />
                )}
                {isProceed && (
                  <RadioIcon src={activeCheck} alt="모바일푸시 체크 아이콘" />
                )}
                진행중
              </RadioLi>
              <RadioLi onClick={handleComplete}>
                {!isComplete && (
                  <RadioIcon src={inActiveCheck} alt="모바일푸시 체크 아이콘" />
                )}
                {isComplete && (
                  <RadioIcon src={activeCheck} alt="모바일푸시 체크 아이콘" />
                )}
                발송완료
              </RadioLi>
              <RadioLi onClick={handleFailed}>
                {!isFailed && (
                  <RadioIcon src={inActiveCheck} alt="모바일푸시 체크 아이콘" />
                )}
                {isFailed && (
                  <RadioIcon src={activeCheck} alt="모바일푸시 체크 아이콘" />
                )}
                실패
              </RadioLi>
            </RadioList>
          </PushButtonWrapper>
        </PushListBoxs>
        {/* 발송 메세지 리스트 */}
        <Table>
          <thead>
            <tr>
              <Th>상태</Th>
              <Th>제목</Th>
              <Th>내용</Th>
              <Th>전체</Th>
              <Th>성공</Th>
              <Th>발송시간</Th>
              <Th></Th>
            </tr>
          </thead>
          <tbody>
            {renderAllPush()}
          </tbody>
        </Table>
        <Pagination
          totalPost={arryByState.length}
          postsPerPage={postsPerPage}
          currentPage={currentPage}
          setCurrrentPage={setCurrrentPage}
        />
      </PageWrapper>
    </Layout>
  );
};

export default PushList;

const PageWrapper = styled.div`
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  background-color: ${grey1};
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 40px;
`;
const Title = styled.p`
  display: flex;
  font-size: 24px;
  font-weight: 700;
  color: ${primary5};
  padding: 0 0 10px;
  border-bottom: 2px solid ${grey5};
`;

const Message = styled.p`
  display: flex;
  width: 35%;
  color: ${grey7};
  font-weight: 400;
  padding-top: 10px;
  font-size: 14px;
`;
const DetailMessage = styled.p`
  display: flex;
  justify-content: center;
  width: 35%;
  color: ${grey9};
  margin-bottom: 5px;
  font-size: 14px;
  border-right: 1px solid black;
`;
const PageTitle = styled.h2`
  color: ${primary5};
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 12px;
`;
const PushListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const PushButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;
const RadioList = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  gap: 16px;
`;

const RadioLi = styled.li`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const RadioIcon = styled.img`
  width: 18px;
  height: 18px;
`;
  
//------- table ---------
const Table = styled.table`
  width: 100%;
  margin: auto;
  text-align: center;
  border-collapse: collapse;
  border-radius: 32px;
  background: ${grey1};
  /* table-layout: fixed; */
`

const Th  = styled.th`
  padding: 16px 8px; 
  border-bottom: 1px solid ${grey5};
  background: ${primary3};
  color: ${grey1};
  font-weight: 500;
  font-size: 16px;
`
const Td  = styled.td`
  box-sizing: border-box;
  padding: 16px 6px;
  border-bottom: 1px solid ${grey5};
  color: ${grey8};
  font-weight: 500;
  font-size: 14px;
  text-align: ${(props) => (props.content ? `left` : null)};
  width: ${(props) => (props.status ? `100px` : null)};
  width: ${(props) => (props.title ? `220px` : null)};
  width: ${(props) => (props.total ? `120px` : null)};
  width: ${(props) => (props.success ? `120px` : null)};
  width: ${(props) => (props.date ? `160px` : null)};
  width: ${(props) => (props.btns ? `200px` : null)};

  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all; */
`
const TableBtnList  = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
`
const TableBtn  = styled.button`
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => (props.delete ? `${error3}` : `${primary5}`)};
`
