import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { instanceAxios } from '../../api/axios';
import Layout from '../../templates/Layout';
import styled from 'styled-components';
import iconSurvey from '../../assets/images/ico-survey.png'
import { MAIN_BACKGROUND_COLOR,grey1, grey10, grey4,grey6, grey8, grey9,primary3, primary5 } from '../../constants/color';
import { useRecoilState } from 'recoil';
import { AlertMessage, IsAlertOpen, MyProfile } from '../../atom/Atom';
import inactiveRadio from '../../assets/images/inactive-radio.png'
import activeRadio from '../../assets/images/active-radio.png'
import bin from '../../assets/images/ico-paper-bin.png'
import { logout } from '../../api/member/logout';
export default function Secession() {
  const navgiate = useNavigate();
  const [myProfile, setMyProfile] = useRecoilState(MyProfile);
  const [isEct, setIsEct] = useState(false);
  const [radioValue, setRadioValue] = useState('');
  const [pwd, setPwd] = useState('');
  const [openWarning, setOpenWarning] = useState(false);
  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);
  // error Type
  const [isErrorType, setIsErrorType] = useState(null);
  // error func
  const commonErrorPopup = (msg, type) => {
    setIsAlertOpen(true);
    setAlertMessage(msg);
    // 함수는 () => 함수이름
    // 바로 작동하면 안됨
    setIsErrorType(type);
  };

  const writeEctReason = (e) => {
    setRadioValue(e.target.value);
  }
  
  const handleIsEct = (e) => {
    if(e.target.id !== "4") {
      setIsEct(false);
      setRadioValue(e.target.value);
    } else if(e.target.id === "4" && e.target.checked){
      setIsEct(true);
      setRadioValue('');
    }
  }
  
  const handleCheckPwd = (e) => {
    setPwd(e.target.value);
  }

  const handleOpenWarning = (e) => {
    e.preventDefault();
    if(e.target.id === 'cancelBtn') {
      setOpenWarning(false);
    }
    openWarning ? setOpenWarning(false) : setOpenWarning(true);
  }

  const secessionData = {
    "currentPassword": pwd,
    "reason": radioValue
  }

  const handleSubmitBtnActive = async (e) => {
    e.preventDefault();
    try{
      console.log(secessionData)
      const response = await instanceAxios.post('/member/secession', secessionData);
      if(response.status === 200) {
        logout();
        navgiate('/goodbye');
      }
    } catch (err){
      console.log(err);
      if(err.response.status === 404) {
        commonErrorPopup('비밀번호를 다시 확인해주세요.', setOpenWarning(false));
      } else if(err.response.status === 422) {
        commonErrorPopup('탈퇴 사유와 비밀번호를 모두 입력해주세요.', setOpenWarning(false));
      }

    }

  }

  return (
    <Layout>
      <SecessionSection>
        <SecessionH2>회원 탈퇴</SecessionH2>
        <TitleDesc><MemberName>{myProfile.name}님</MemberName>, 회원탈퇴를 원하시나요?</TitleDesc>
        <TitleDesc>신중하게 다시 한번 생각해주세요!</TitleDesc>
          <SurveyIcon src={iconSurvey} alt="surveyIcon" loading='eager'/>
       
        <GuideBox>
          <GuideH3>회원 탈퇴 안내 사항</GuideH3>
          <GuideDesc>계정을 삭제하면 회원님의 모든 콘텐츠와 정보가 사라집니다.</GuideDesc>
          <GuideDesc>또한, 탈퇴한 계정으로 다시 재가입이 불가능합니다.</GuideDesc>
        </GuideBox>

        <SurveySection>
          <SurveyH3>탈퇴하시는 이유가 무엇인가요?</SurveyH3>
          <SurveySubTitle>계정을 삭제하시려는 용도를 알려주세요! 콘텐츠 개선을 위해 활용하겠습니다.</SurveySubTitle>
          <Form>
            <InPutGroup>
              <InputRadio onClick={handleIsEct} type="radio" id='1' value={"사용빈도가 낮아서"} name='secession'/>
              <Label htmlFor="1">사용빈도가 낮아서</Label>
            </InPutGroup>
            <InPutGroup>
              <InputRadio onClick={handleIsEct} type="radio" id='2' value={"이용이 불편하고 장애요소가 많아서"} name='secession'/>
              <Label htmlFor="2">이용이 불편하고 장애요소가 많아서</Label>
            </InPutGroup>
            <InPutGroup>
              <InputRadio onClick={handleIsEct} type="radio" id='3' value={"원하는 데이터를 얻을 수 없어서"} name='secession'/>
              <Label htmlFor="3">원하는 데이터를 얻을 수 없어서</Label>
            </InPutGroup>
            <InPutGroup>
              <InputRadio onClick={handleIsEct} type="radio" id='4' value={"기타"} name='secession'/>
              <Label htmlFor="4">기타</Label>
            </InPutGroup>
            <WrapTextarea>
              {isEct && 
              <Textarea name="" id="" cols="30" rows="10" placeholder={'기타 의견을 입력해주세요.'} onChange={writeEctReason}></Textarea>
              }
            </WrapTextarea>
            <InPutGroupPw>
              <LabelPwd pwd htmlFor="">비밀번호 확인</LabelPwd>
              <InputPwd type="password" onChange={handleCheckPwd}/>
            </InPutGroupPw>
              {(!pwd || radioValue.length <= 0) &&  <GreySubmitBtn disabled>탈퇴하기</GreySubmitBtn>}
              {(pwd && radioValue.length > 0) &&  <SubmitBtn onClick={handleOpenWarning} type='submit'>탈퇴하기</SubmitBtn>}
          </Form>
        </SurveySection>
        {openWarning &&
          <AlertBox>
            <AlertCont>
              <BinImg src={bin} alt="" loading='eager'/>
              <AlertP>정말 탈퇴하시겠어요?</AlertP>
              <AlertBtnList>
                <li>
                  <AlertBtns secession='true' id='secessionBtn' onClick={handleSubmitBtnActive}>탈퇴하기</AlertBtns>
                </li>
                <li>
                  <AlertBtns id='cancelBtn' onClick={handleOpenWarning}>취소하기</AlertBtns>
                </li>
              </AlertBtnList>
            </AlertCont>
          </AlertBox>
        }
      </SecessionSection>
    </Layout>
  )
}

const SecessionSection = styled.section`
  padding: 40px 40px 60px;
`

const SecessionH2 = styled.h2`
  color: ${grey10};
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 14px;
`

const TitleDesc = styled.p`
  color: ${grey10};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
`

const MemberName = styled.span`
  font-weight: 700;
  position: relative;

  ::after {
    display: block;
    position: absolute;
    content: "";
    background: #fdcf56;
    width: 100%;
    height: 8px;
    padding: 0 5px;
    left: -5px;
    bottom: 0;
    z-index: -1;
    opacity: 0.5;
  }
`

const SurveyIcon = styled.img`
  width: 115px;
  display: block;
  margin: 40px auto 24px;
`
// 가이드 
const GuideBox = styled.div`
  max-width: 620px;
  padding: 20px;
  box-sizing: border-box;
  background: ${MAIN_BACKGROUND_COLOR};
  border-radius: 8px;
  margin: 24px auto 40px;
  text-align: center;
`
const GuideH3 = styled.h3`
  font-size: 16px;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 12px;
  color: ${grey9};
  `
const GuideDesc = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: ${grey9};
`

const SurveySection = styled.div`
  margin-top: 32px;
  max-width: 620px;
  margin: 0 auto;
`

const SurveyH3= styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: ${grey10};
`
const SurveySubTitle= styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${grey6};
  padding-top: 5px;
`

const Form = styled.form`
  width: 100%;
  margin-top: 40px;
`

const InPutGroup = styled.div`
  margin-bottom: 20px;
  margin-left: 15px;
  display: flex;
  align-items: center;
`
const InputRadio = styled.input`
  display: none;
  
  &:checked + label::after{
    display:block;
    position: absolute;
    content: url(${activeRadio});
    left:-25px;
    top:0;
  }
  
`
const Label = styled.label`
  position: relative;
  margin-left: ${(props) => (props.pwd ? `0` :  `10px` )};
  
  &::after {
    display:block;
    position: absolute;
    content: url(${inactiveRadio});
    left:-25px;
    top:0;
  }
`

const WrapTextarea = styled.div`
  width: 100%;
`
const Textarea = styled.textarea`
  padding: 12px;
  width: calc(100% - 40px);
  border: ${primary5} 1px solid;
  outline: none;
  height: 130px;
  resize: unset;
  box-sizing: border-box;
  border-radius: 8px;
  color: ${grey8};
`

const InPutGroupPw = styled.div`
  margin: 24px 0 0;
  width: 100%;
  border-top: 1px solid ${grey6};
  padding-top: 24px;
`

const InputPwd = styled.input`
  border-radius: 8px;
  border: 1px solid ${primary5};
  max-width: 280px;
  padding: 12px;
  height: 40px;
  box-sizing: border-box;
  margin: 16px 0 0;
  display: block;
`

const LabelPwd = styled.label`
  margin-left: ${(props) => (props.pwd ? `0` :  `10px` )};
`

const SubmitBtn = styled.button`
  width: 300px;
  display: block;
  box-sizing: border-box;
  padding: 16px 0;
  background: ${primary3};
  border-radius: 8px;
  color: ${grey1};
  font-weight: 600;
  font-size: 18px;
  margin: 80px auto 40px;
`

const GreySubmitBtn = styled.button`
  width: 300px;
  display: block;
  box-sizing: border-box;
  padding: 16px 0;
  background: ${grey4};
  border-radius: 8px;
  color: ${grey1};
  font-weight: 600;
  font-size: 18px;
  margin: 80px auto 40px;
`

const AlertBox = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0,0,0,0.3);
`
const AlertCont = styled.div`
  background: ${grey1};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  width: 300px;
  border-radius: 8px;
  padding: 30px;
`

const BinImg = styled.img`
  width: 60px;
  display: block;
  margin: 0 auto 20px;
`
const AlertP = styled.p`
  line-height: 1.3;
  font-size: 16px;
  text-align: center;
`

const AlertBtnList = styled.ul`
  margin: 20px auto 0;
`

const AlertBtns = styled.button`
  display: block;
  margin: ${(props) => (props.secession ? `0 auto 6px` :  `0 auto` )};
  background: ${(props) => (props.secession ? `${primary3}` :  `${grey1}` )};
  color: ${(props) => (props.secession ? `${grey1}` :  `${grey6}` )};
  padding: 8px 25px;
  border-radius: 4px;

`