import styled from "styled-components";
import SizeSlider from "../../slider";
import { grey5, grey8, primary1, primary3, primary4 } from "../../../constants/color";
import { useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import ColorPicker from "../../ColorPicker";
import { useRecoilState } from "recoil";
import { Temp_btn_color, Temp_btn_shape, Temp_btn_txt, Temp_btn_txt_color, Temp_btn_txt_weight } from "../../../atom/templeteAtom";

function TemplateButton() {
  // ----- textarea ------ 
  //--- 버튼 텍스트 내용---
  const [temp_txt, set_temp_txt] = useRecoilState(Temp_btn_txt);
  const txtRef = useRef();
  const resizingWriting = useCallback((ref) => {
    if(ref === null || ref.current === null) {
      return;
    }
    ref.current.style.height = '37px'
    ref.current.style.height = ref.current.scrollHeight + "px";
  }, []);
  
  
  // 제목 글자수 제한
  useEffect(() => {
    // resizingWriting(titleRef);
    if(temp_txt.length > 100) {
      set_temp_txt(txtRef.current.value.substr(0, 20));
      return;
    } 
  },[temp_txt])



  //  ------- color picker ----------
  const [bgPicker, setBgPicker] = useState(false);
  const [fontPicker, setFontPicker] = useState(false);
  // 버튼 배경
  const [temp_btn_color, set_temp_btn_Color] = useRecoilState(Temp_btn_color); 
  // 글씨 색상
  const [temp_btn_txt_color, set_temp_btn_txt_Color] = useRecoilState(Temp_btn_txt_color); 
  const changeBgColor = (color) => {
    set_temp_btn_Color(color.hex)
  };
  const changeFontColor = (color) => {
    set_temp_btn_txt_Color(color.hex);
  };

  const closeColorPicker = (e) => {
    if(bgPicker) setBgPicker(false);
    if(fontPicker) setFontPicker(false);
    return false;
  };

  // --- 텍스트 두께 --- 
  const [temp_txt_weight, set_temp_txt_weight] = useRecoilState(Temp_btn_txt_weight);
  const weightRef = useRef();
  const selectWeight = (e) => {
    if(e.target.id === 'normal') set_temp_txt_weight('normal');
    if(e.target.id === 'bold') set_temp_txt_weight('bold');
  };
  


  // ---  버튼 모서리 ---
  const [temp_btn_shape, set_temp_btn_shape] = useRecoilState(Temp_btn_shape);
  const [btnShape, setBtnShape] = useState(temp_btn_shape);  
  useEffect(() => {
    set_temp_btn_shape(btnShape.toString());
  }, [btnShape]);


  return (
    <WrapCont onClick={closeColorPicker}>
    <div style={{position: 'relative', width:'fit-content', zIndex: '1'}}>
      <SubTitle>버튼 설정</SubTitle>
    </div>
    <AlignSet>
      <SetTitle>내용</SetTitle>
      <WrapController>
      <ContentTxtarea 
        maxLength={20}
        placeholder="최대 20자 입력 가능"
        defaultValue={temp_txt}
        ref={txtRef}
        onChange={(e) => {set_temp_txt(e.target.value); resizingWriting()}}
        ></ContentTxtarea>
      </WrapController>
    </AlignSet>
    <AlignSet>
      <SetTitle>바탕 색상</SetTitle>
      <WrapController>
        <ColorPicker color={temp_btn_color} setColor={set_temp_btn_Color} isOpen={bgPicker} setOpen={() => setBgPicker(true)} changeColor={changeBgColor}/>
      </WrapController>
    </AlignSet>
    <AlignSet>
      <SetTitle>텍스트 색상</SetTitle>
      <WrapController>
        <ColorPicker color={temp_btn_txt_color} setColor={set_temp_btn_txt_Color} isOpen={fontPicker} setOpen={() => setFontPicker(true)} changeColor={changeFontColor}/>
      </WrapController>
    </AlignSet>
    <AlignSet>
      <SetTitle>텍스트 두께</SetTitle>
      <WrapController style={{display: "flex"}} onClick={selectWeight}>
        <WrapRadio>
          <RadioInput type="radio" name="weight" value="normal" id="normal" ref={weightRef} />
          <Label htmlFor="normal" style={{fontWeight: 'normal'}}>보통</Label>
        </WrapRadio>
        <WrapRadio>
          <RadioInput type="radio" name="weight" value="bold" id="bold" />
          <Label htmlFor="bold" style={{fontWeight: 'bold'}}>굵게</Label> 
        </WrapRadio>
      </WrapController>
    </AlignSet>
    <AlignSet>
      <SetTitle>모서리</SetTitle>
      <WrapController style={{display: "flex"}}>
        <SizeSlider setValue={setBtnShape} totalValue={parseInt(btnShape)} max={50} min={0} stepUnit={1} defaultValue={0}/>
      </WrapController>
    </AlignSet>
  </WrapCont>        
  )
}  



export default TemplateButton

const WrapCont = styled.div`
  margin: 40px 0 80px;
`
const AlignSet = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  gap: 20px;
  margin: 0 0 40px;
`
const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: ${grey8};
  width: 100%;
  padding-bottom: 40px;
  ::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    top: 8px;
    padding: 0 10px;
    background: ${primary1};
    width:100%;
    height: 14px;
    opacity: 0.7;
    z-index: -1;
  }
`
const SetTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: ${grey8};
  width: 200px;
`
const WrapController = styled.div`
  width: 100%;
  position: relative;
`


// ------------ 라디오 css -----------
const Label = styled.label`
  font-size: 16px;
  margin-right: 14px;
  cursor: pointer;
`
  
const RadioInput = styled.input`
  appearance: none;
  border: max(2px, 0.1em) solid gray;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  /* transition: border 0.2s ease-in-out; */

  &:checked {
    border: 0.4em solid ${primary3};
  }
  
  /* &:focus-visible {
    outline-offset: max(2px, 0.1em);
    outline: max(2px, 0.1em) dotted  ${primary3};
  } */

  &:hover {
    box-shadow: 0 0 0 max(4px, 0.2em) lightgray;
    cursor: pointer;
    }

`

const WrapRadio = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

// textarea
const ContentTxtarea = styled.textarea`
  width: 80%;
  height: 37px;
  border:  1px solid ${grey5};
  outline: none;
  border-radius: 4px;
  overflow: hidden;
  resize: none;
  padding: 10px 12px;
  box-sizing: border-box;
  line-height: 1.3;
  :focus {
    border:  1px solid ${primary4};
  }
  ::placeholder {
    font-size: 12px;
  }
`