import styled from "styled-components";
import { LoginBox } from "../../components/containers/auth/AuthBox";
import {
  MAIN_BACKGROUND_COLOR,
  grey11,
  grey10,
  error3,
} from "../../constants/color";
import logo from "../../assets/images/logo.png";
import {
  LoginButton,
  BeforeLoginButton,
  GoSignupButton,
} from "../../components/buttons/AuthButtons";
import activeCheck from "../../assets/images/active-radio.png";
import inActiveCheck from "../../assets/images/inactive-radio.png";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { instanceAxios } from "../../api/axios";
import {
  setAccessTokenToCookie,
  setCookie,
  setRefreshTokenToCookie,
  setRememberEmail,
} from "../../api/member/logout";
import {
  InputGroup,
} from "../../components/inputs/InputGroups";
import { useRecoilState } from "recoil";
import {
  AlertCode,
  AlertMessage,
  IsAlertOpen,
  IsLogoutOpen,
  MyCategory,
  MyProfile,
  MyProject,
  MyPushProject,
} from "../../atom/Atom";
import Cookies from "universal-cookie";
import AlertModal from "../../components/modals/AlertModal";
import { LogoutMadal, NewpasswordMadal } from "../../components/modals/LogoutMadal";
import ErrorLabel from "../../error/ErrorLabel";
import ErrorAlert from "../../error/ErrorAlert";
import { useDeviceData } from "../../components/hooks/useDeviceData";

//--------------로그인 페이지--------------------------
export default function Login() {
  const navigate = useNavigate();
  const [iscapslock, setIsCapsLock] = useState(false);
  const [email, setEmail] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [password, setPassword] = useState("");

  // Recoil 부분
  const [myProfile, setMyProfile] = useRecoilState(MyProfile);
  const [myProject, setMyProject] = useRecoilState(MyProject);
  const [myPushProject, setMyPushProject] = useRecoilState(MyPushProject);
  const [myCategory, setMyCategory] = useRecoilState(MyCategory);
  
  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertCode, setAlertCode] = useRecoilState(AlertCode);
  const [isLogoutOpen, setIsLogoutOpen] = useRecoilState(IsLogoutOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);
  // error Type
  const [isErrorType, setIsErrorType] = useState(null);
  const commonErrorPopup = (msg, type) => {
    setIsAlertOpen(true);
    setAlertMessage(msg);
    // 함수는 () => 함수이름
    // 바로 작동하면 안됨
    setIsErrorType(type);
  };

  //이메일 저장 날짤 설정
  let today = new Date();
  today.setDate(today.getDate() + 1);

  //capsLock 여부
  const handleCheckCapsLock = (e) => {
    if (e.getModifierState("CapsLock")) {
      setIsCapsLock(true);
    } else {
      setIsCapsLock(false);
    }
  };
  // cookie 새로 설정
  const loginCookie = new Cookies();

  useEffect(() => {
    const cid = window.location.href.split('cid=')[1];
    const getCid = loginCookie.get('cid');
    if(cid) {
      loginCookie.set("cid", cid);
    } else if(!cid && getCid !== undefined){
      window.history.pushState({ page: 1 }, "title 1", "?cid=" + getCid);
      loginCookie.set("cid", cid);
    } else if(!cid && !getCid){
      navigate('/', {replace: true});
    }

    setTimeout(() => {
      const getCid = loginCookie.get('cid');
      if(getCid) navigate('/', {replace: true});
    }, 1000);
  },[])

  useEffect(() => {
    if (loginCookie.get("rememberEmail") !== undefined) {
      setEmail(loginCookie.get("rememberEmail"));
      setIsCheck(true);
    } else {
      setIsCheck(false);
      loginCookie.remove("rememberEmail");
    }
    loginCookie.remove("accessToken");
    loginCookie.remove("refreshToken");
  }, []);

  const handleCheckBox = () => {
    isCheck ? setIsCheck(false) : setIsCheck(true);
    if (isCheck) {
      loginCookie.remove("rememberEmail");
    }
  };

  const handleGoSignup = (e) => {
    e.preventDefault();
      navigate("/signup");
  };

  const [emailErr, setEmailErr] = useState([]);
  const [pwdErr, setPwdErr] = useState([]);
  const [resultErr, setResultErr] = useState([]);
  const resetErr = () => {
    setEmailErr([]);
    setPwdErr([]);
    setResultErr([]);
  }
  // 로그인 data
  const userDeivceData = useDeviceData(window.navigator.userAgent);
  const [browserName, setBrowserName] = useState(userDeivceData.browser.name.toUpperCase().replaceAll(' ','_'));
  const [osName, setOsName] = useState(userDeivceData.os.name.toUpperCase().replaceAll(' ','_'));
  const [ipAddress, setIpAddress] = useState("");
  
  const BROWSER_TYPE = [
    'CHROME',
    'FIREFOX',
    'OPERA',
    'YANDEX',
    'SAFARI',
    'INTERNET_EXPLORER',
    'EDGE',
    'CHROMIUM',
    'IE',
    'MOBILE_SAFARI',
    'EDGE_CHROMIUM',
    'MIUI_BROWSER',
    'SAMSUNG_BROWSWER',
  ];
  const OS_TYPE = [
    'IOS',
    'ANDROID',
    'WINDOWS_PHONE',
    'WINDOWS',
    'MAC_OS',	
  ]

  const handleTypeName = () => {
    if(browserName) {
      if(!BROWSER_TYPE.includes(browserName)) setBrowserName('ETC');
    };

    if(osName) {
      if(!OS_TYPE.includes(osName)) setOsName('ETC');
    }
  }
  useEffect(() => {
    handleTypeName();
  }, [])
  
  useEffect( () => {   
    const getIpAddress = async() => {
     fetch('https://geolocation-db.com/json/', {
      header : {
        "Origin" : 'https://geolocation-db.com/'
      }
     })
     .then((res) => res.json())
     .then((data) => {
       setIpAddress(data.IPv4);
     })
    }
    getIpAddress();
  },[]);
  
  const loginData = {
    deviceInfo: {
        ip: ipAddress, 
        deviceType: osName,
        browserType: browserName, 
    },
    email: email,
    password: password,
    // cid: loginCookie.get('cid');
  };
  
  // 로그인 요청
  // 로그인 > 기존 정보 지우기 >me > project
  const requestLogin = async (e) => {
    e.preventDefault();
    resetErr();
    window.localStorage.removeItem("recoil-persist");
    instanceAxios.defaults.headers.common["Authorization"] = null;
    setAlertCode(0);
    handleTypeName();
    try {
      const response = await instanceAxios.post("/auth/login", loginData);
      if (response.status === 200) {
        const refreshToken = response.data.refreshToken;
        const accessToken = response.data.accessToken;
        const tokenType = response.data.tokenType;
        const headersToken = tokenType + accessToken;
        setRefreshTokenToCookie(refreshToken);
        setAccessTokenToCookie(headersToken);
        setAlertCode(0);
        instanceAxios.defaults.headers.common["Authorization"] = headersToken;
       
        if(refreshToken && accessToken) {
          checkAccount();
          getCategory();
          navigate("/dashboard");
        }
      }
    } catch (err) {
      console.error(err.response.data);
      // const errData = err.response.data.data;

      // -------- 예외처리 ---------
      // 403 관리자 승인 여부 추가
      // if(errData.result) setResultErr(errData.result);
      // if(errData.email) setEmailErr(errData.email);
      // if(errData.password) setPwdErr(errData.password);

      // 프론트 처리
      if(err.response.status === 401) {
        commonErrorPopup("이메일과 비밀번호를 다시 확인해주세요.", null);
      } else if(err.response.status === 404) {
        commonErrorPopup("이메일과 비밀번호를 다시 확인해주세요.", null);
      } else if(err.response.status === 403 ) {
        commonErrorPopup("관리자 승인 후 로그인이 가능합니다.", null);
      } else {
        commonErrorPopup("이메일과 비밀번호를 다시 확인해주세요.", null);
      } 

      // --------- 예외처리 ---------
      const cookies = new Cookies();
      cookies.remove("refreshToken");
      cookies.remove("accessToken");
    }
  };
  
  const getCategory = async () => {
    try {
      const response = await instanceAxios.get("/category/all");
      setMyCategory(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };


  const checkAccount = async () => {
    try {
      const response = await instanceAxios.post("/member/me");
      if (response.status === 200) {
        if (isCheck) {
          setRememberEmail(email);
        }
        setMyProfile(response.data.data);
        // checkProject();
      }
    } catch (err) {
      // login yet
      navigate("/");
      console.error(err);
    }
  };

  const checkProject = async () => {
    try {
      const response = await instanceAxios.get("/all");
      if (response.status === 200) {
        setMyProject(response.data);
        
        if (
          response.data.filter((item) => item.expiryDate === null)
            .length > 0 // 프로젝트가 여러개
        ) {
          setMyPushProject(
            response.data.filter(
              (item) => item.expiryDate === null
            )[0]
          );
        } else {
          setMyPushProject(response.data[0]);  // 그 외
        }
      }
    } catch (err) {
      // login yet
      console.error(err);
    }
  };
  return (
    <Section>
      {resultErr.length > 0 && <ErrorAlert data={resultErr}/>}
      <InputSection>
        <h1 className="ir">회원가입</h1>
        <LoginBox signup>
          <WrapLogo>
            <>Welcome to</>
            <Logo src={logo} alt="메인로고" />
          </WrapLogo>
          <WrapContents>
            <form action="post">
              <IDInputWrap>
                <SubTitle>이메일</SubTitle>
                <InputGroup
                  setValue={setEmail}
                  value={email}
                  type="text"
                  placeholder="이메일을 입력하세요"
                />
              {emailErr.length > 0 && <ErrorLabel data={emailErr}/>}
              </IDInputWrap>
              <PwdInputWrap>
                <SubTitle>비밀번호</SubTitle>
                <InputGroup
                  setValue={setPassword}
                  isKeyDown={handleCheckCapsLock}
                  value={password}
                  last
                  autoComplete={"off"}
                  type="password"
                  placeholder="비밀번호를 입력하세요"
                />
                {pwdErr.length > 0 && <ErrorLabel data={pwdErr}/>}
                {iscapslock && (
                  <LabelWarning>Caps Lock이 켜져있습니다!</LabelWarning>
                )}
              </PwdInputWrap>
              <RadioList>
                <RadioLi>
                  {!isCheck && (
                    <RememberEmail
                      type="text"
                      readOnly
                      checked={isCheck}
                      onClick={handleCheckBox}
                    />
                    // <img src={inActiveCheck} alt="아이디저장하기 체크 아이콘"  />
                  )}
                  {isCheck && (
                    <RememberEmail
                      active
                      type="text"
                      readOnly
                      checked={isCheck}
                      onClick={handleCheckBox}
                    />
                    // <img src={activeCheck} alt="아이디저장하기 체크 아이콘" />
                  )}
                  이메일 저장
                </RadioLi>
                <WrapFindAuth>
                  <li>
                    <LinkStyle to="/findEmail">이메일 찾기</LinkStyle>
                  </li>
                  <li>|</li>
                  <li>
                    <LinkStyle to="/findPassword">비밀번호 찾기</LinkStyle>
                  </li>
                </WrapFindAuth>
              </RadioList>

              {(!email || !password) && (
                <BeforeLoginButton>로그인</BeforeLoginButton>
              )}
              {email && password && (
                <LoginButton type="submit" requestLogin={requestLogin}>
                  로그인
                </LoginButton>
              )}
            </form>
            <ButtonWrap>
              <GoSignupButton handleGoSignup={handleGoSignup}>
                회원가입
              </GoSignupButton>
            </ButtonWrap>
          </WrapContents>
        </LoginBox>
      </InputSection>
      {/* alert */}
      {isAlertOpen && <AlertModal isErrorType={isErrorType}></AlertModal>}
      {/* {isLogoutOpen && <LogoutMadal></LogoutMadal>} */}
      {isLogoutOpen && <NewpasswordMadal></NewpasswordMadal>}
      {/* alert */}
    </Section>
  );
}

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: "Pretendard";
  background: ${MAIN_BACKGROUND_COLOR};
  /* padding: 186px 0; */
`;
const ImageSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: "Pretendard";
  /* padding: 186px 0; */
  background-image: ${MAIN_BACKGROUND_COLOR};
`;

const InputSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: "Pretendard";
  /* padding: 186px 0; */
  
`;

const WrapLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 0px;
  width: 335px;
  height: 131px;
  font-weight: 700;
  margin-bottom: 20px;
  color: ${grey10};
  font-size: 30px;
`;

const Logo = styled.img`
  width: 270px;
`;
const MainImage = styled.img`
  width: 712px;
  height: 654px;
`;

const WrapContents = styled.div`
  width: 100%;
`;
const ButtonWrap = styled.div`
  width: 100%;
  margin-top: 12px;
`;

const IDInputWrap = styled.div`
  width: 399px;
`;

const PwdInputWrap = styled.div`
  width: 399px;
  margin-top: 20px;
`;

const RadioList = styled.ul`
  display: flex;
  margin: 24px 0 36px 0;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

const SubTitle = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
`;

const RadioLi = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const WrapFindAuth = styled.div`
  display: flex;
  gap: 10px;
`;

const LinkStyle = styled(Link)`
  text-decoration: none;
  color: ${grey11};
`;

const RememberEmail = styled.input`
  background: ${(props) =>
    props.active ? `url(${activeCheck})` : `url(${inActiveCheck})`};
  /* background: url(${inActiveCheck}); */
  background-repeat: no-repeat;
  background-size: 15px;
  width: 15px;
  border: none;
  cursor: pointer;
`;

const LabelWarning = styled.span`
  display: block;
  color: ${error3};
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: ${(props) => (props.email ? "8px" : "0")};
`;
