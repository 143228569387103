import styled from "styled-components";
import React from "react";
import { MAIN_BACKGROUND_COLOR, grey1, grey5, grey6, primary5 } from "../../constants/color";
// 이메일 직접 입력
const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${grey5};
  background: ${grey1};

  cursor: ${props => props.dropbox ? "pointer" : null};
  
  &:focus {
    border: 1px solid ${primary5};
  }

  &::placeholder {
    color: ${grey6};
    font-size: 12px;
  }

  &:read-only {
    background: ${MAIN_BACKGROUND_COLOR};
  }
`;

export const InputGroup = ({
  type = "text",
  placeholder = "",
  value,
  setValue,
  id,
  minLength,
  maxLength,
  readonly = false,
  isKeyDown,
  inputFunc,
  refname,
  autoComplete,
}) => {
  return (
    <Input
    type={type}
    placeholder={placeholder}
    value={value}
    id={id}
    readOnly={readonly}
    minLength={minLength}
    maxLength={maxLength}
    onInput={inputFunc}
    onChange={(e) => setValue(e.target.value)}
    onKeyDown={isKeyDown}
    ref={refname}
    autoComplete={autoComplete}
    />
    );
  };
  
export const InputValidateGroup = ({
  type = "text",
  placeholder = "",
  value,
  setValue,
  id,
  name,
  maxLength,
  isKeyDown,
  readonly = false,
  refName,
  autoComplete
}) => {
  return (
    <Input
      type={type}
      placeholder={placeholder}
      value={value}
      id={id}
      readOnly={readonly}
      maxLength={maxLength}
      name={name}
      onChange ={setValue}
      ref={refName}
      autoComplete={autoComplete}
    />
  );
};

export const DropboxInput = ({
  type,
  placeholder,
  setValue,
  handleClick,
  value,
  name,
  id,
  maxLength,
  readOnly= false
}) => {
  return (
    <Input 
      dropbox
      type={type}
      placeholder={placeholder}
      value={value}
      id={id}
      name={name}
      onChange={setValue}
      onClick={handleClick}
      readOnly={readOnly}
      maxLength={maxLength}
    />
  );
};
