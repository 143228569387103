
import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { grey3, grey4, grey5, muiColorTheme, primary3 } from '../constants/color';
import styled from 'styled-components';
import { useState } from 'react';


function SizeSlider({totalValue,setValue, max, min, defaultValue, stepUnit, unit}) {
  const [slideValue, setSlideValue] = useState(totalValue);
  const [inputValue, setInputValue] = useState(totalValue);
  const changeSlide = (value) => {
    setValue(value);
    setSlideValue(value);
    setInputValue(value);
  }

  return (
    <SliderBox>
      <Box sx={{ width: 300 }}>
        <Slider 
        defaultValue={defaultValue}  
        max={max}
        min={min}
        value={slideValue}
        aria-label="Default" 
        valueLabelDisplay="auto"
        onChange={(value) => changeSlide(value.target.value)}
        color={muiColorTheme.primaryColor}
        step={stepUnit}
        />
      </Box>
      {unit && 
        <SizeInput 
          placeholder={unit} 
          max={max}
          min={min}
          type='number'
          value={inputValue}
          onChange={(e)=> changeSlide(e.target.value)}
        />
      }
    </SliderBox>
  )
}



export default SizeSlider;

const SliderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
`
const SizeInput = styled.input`
  width: 60px;
  height: 30px;
  border: 2px solid ${grey5};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 5px;
  text-align: center;

  ::placeholder{
    color: ${grey5};
    font-size: 12px;
    text-align: center;
  }
`