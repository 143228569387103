import styled from "styled-components";
import {grey1, grey2, grey4, grey7, primary1, primary2, primary3, primary4} from '../../constants/color'
import nextIcon from '../../assets/images/ico-next.svg'
import prevIcon from '../../assets/images/ico-prev.svg'
import firstIcon from '../../assets/images/ico-first.svg'
import lastIcon from '../../assets/images/ico-last.svg'
import { useEffect, useState } from "react";

const WrapPagination = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 30px 0;
  gap: 6px;
`

const LiStyle = styled.li`
  border-radius: 8px;
  `

const BtnStyle = styled.button`
  color: ${grey1};
  text-align: center;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  background-color: ${(props) => (props.isActivebtn ? `${primary3}` : `${grey4}`)};
`

const NextPrevBtn = styled.button`
  color: ${grey7};
  width: 20px;
  height: 35px;
`

const NextPrevIcon = styled.img`
  margin: 6px auto 0;
  width: ${(props) => (props.big ? `16px` : `16px`)};
`


function Pagination({totalPost, postsPerPage, setCurrrentPage, currentPage}) {
  const MAX_PAGE = 5;
  let totalPage = Math.ceil(totalPost/postsPerPage);
  let pageGroup = Math.ceil(currentPage/MAX_PAGE);
  let lastPage = pageGroup * MAX_PAGE;
  let firstPage = lastPage - (MAX_PAGE - 1) <= 0 ? 1 : lastPage - (MAX_PAGE - 1);
  let next = lastPage + 1;
  let prev = firstPage - 1;
  if(lastPage > totalPage) lastPage = totalPage;
  let pages = [...Array(totalPage).keys()].map(el => el + 1);
  const [isActivebtn, setIsActiveBtn] = useState(false);
  const onPageChange = function (e) {
    e.preventDefault();
    const pagingId = e.target.id; 
    console.log(pagingId)
    if(pagingId === "next") setCurrrentPage(next);
    if(pagingId === "prev") setCurrrentPage(prev);
    if(pagingId === "first") setCurrrentPage(1);
    if(pagingId === "last") setCurrrentPage(totalPage);
  }

  return (
  <WrapPagination>
    {prev > 0 &&  
      <>
        {/* <NextPrevBtn onClick={onPageChange}>
        <NextPrevIcon id="first" src={firstIcon} alt="first" />
        </NextPrevBtn> */}
        <NextPrevBtn onClick={onPageChange}>
          <NextPrevIcon id="prev" src={prevIcon} alt="prev" />
        </NextPrevBtn>
      </>
    } 
    {pages.slice(firstPage - 1, lastPage).map((page, index)=> {
      return (
        <LiStyle key={index}>
          <BtnStyle onClick={() => {setCurrrentPage(page)}} isActivebtn={currentPage === page ? true : false}>
            {page}
          </BtnStyle>
        </LiStyle>
        )   
      })
    }
    
    {lastPage <  totalPage &&  
    <>
      <NextPrevBtn onClick={onPageChange}>
        <NextPrevIcon id="next" src={nextIcon} alt="next"/>
      </NextPrevBtn>
      {/* <NextPrevBtn  onClick={onPageChange}>
        <NextPrevIcon big='true' id="last" src={lastIcon} alt="last" />
      </NextPrevBtn> */}
    </>
    }
  </WrapPagination>
  )
}

export default Pagination