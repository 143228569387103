import styled from "styled-components";
import SizeSlider from "../../slider";
import { error2, error3, grey10, grey5, grey8, primary1, primary3, primary4 } from "../../../constants/color";
import { useRef } from "react";
import { useState } from "react";
import { AlertMessage, IsAlertOpen } from "../../../atom/Atom";
import { useRecoilState } from "recoil";
import { Temp_img_file, Temp_img_round, Temp_img_url } from "../../../atom/templeteAtom";
import { useEffect } from "react";


function TemplateImage() {
   // Alert Modal
   const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
   const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);

   const [temp_round,set_temp_round] = useRecoilState(Temp_img_round);
   const [imgRound, setImgRound] = useState(temp_round);
   
   //  이미지 모서리
   useEffect(() => {
     set_temp_round(imgRound)
    }, [imgRound])
    
// 이미지 업로드
  const [temp_url, set_temp_url] = useRecoilState(Temp_img_url);
  const [temp_file, set_temp_file] = useRecoilState(Temp_img_file);
  const imageInputRef = useRef(null);
  const formData = new FormData();
  const onImgInputBtnClick = (e) => {
    e.preventDefault();
    imageInputRef.current.click();
  };
  const deleteUploadImage = (e) => {
    set_temp_file("");
    set_temp_url("");
  };
  const handleUploadImage = (e) => {
    e.preventDefault();
    let fileList = e.target.files;
    const isSizeOk = limitImgSize(fileList[0], 'image');
    if(!isSizeOk) return;

    const imageUrl = URL.createObjectURL(fileList[0]);
    set_temp_url(imageUrl); // 미리보기 이미지 주소   
    set_temp_file(fileList[0]); // formData에 올릴 파일

    e.target.value = ''; 
  };
  function limitImgSize (iconImg, type) {
    if (iconImg) {
      let maxSize = 10 * 1024 * 1024;
      let fileSize = iconImg.size;
  
      if(fileSize > maxSize){
        if(type === "image") {
          set_temp_file("");
          set_temp_url("");
        } 

        setIsAlertOpen(true);
        setAlertMessage("파일 용량이 초과되었습니다.");

        return false;
      } else {
        return true;
      }
    }
  }

  return (
    <>
    <WrapCont>
        <div style={{position: 'relative', width:'fit-content', zIndex: '1'}}>
          <SubTitle>이미지 설정</SubTitle>
        </div>
        <AlignSet>
          <SetTitle>이미지 업로드</SetTitle>
          <WrapController>
            <ImageArea>
              <ImageInput
                placeholder="이미지를 등록하세요"
                value={temp_file ? temp_file.name : ""}
                name="image"
                readOnly={true}
              ></ImageInput>
              <ImageInput
                style={{ display: "none" }}
                placeholder="이미지를 등록하세요"
                type="file"
                accept="image/*"
                ref={imageInputRef}
                onChange={handleUploadImage}
              ></ImageInput>
              {(temp_file && temp_url) && 
                <DeleteFileUploadBtn onClick={deleteUploadImage}>
                  이미지 삭제
                </DeleteFileUploadBtn>
              }
              {(!temp_file || !temp_file) && 
                <FileUploadBtn onClick={onImgInputBtnClick}>
                  이미지 등록
                </FileUploadBtn>
              }     
            </ImageArea>
          </WrapController>
        </AlignSet>
        <AlignSet style={{marginTop: '-40px'}}>
          <SetTitle></SetTitle>
          <WrapController>
            <div>
              <NoticeP>* 버튼 타입 이미지 크기는 레이아웃 너비에 따라 자동 조절됩니다.</NoticeP>
              <NoticeP>* 이미지 타입의 이미지는 꼭 레이아웃 크기에 맞춰 제작해주실 바랍니다.</NoticeP>
            </div>
          </WrapController>
        </AlignSet>
      </WrapCont>   
    </>
  )
}

export default TemplateImage

const WrapCont = styled.div`
  margin: 40px 0 80px;
`
const AlignSet = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  gap: 20px;
  margin: 0 0 60px;
`
const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: ${grey8};
  width: 100%;
  padding-bottom: 40px;
  ::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    top: 8px;
    padding: 0 10px;
    background: ${primary1};
    width:100%;
    height: 14px;
    opacity: 0.7;
    z-index: -1;
  }
`
const SetTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: ${grey8};
  width: 200px;
`
const WrapController = styled.div`
  width: 100%;
  position: relative;
`

// ------------ 라디오 css -----------
const Label = styled.label`
  font-size: 16px;
  margin-right: 14px;
  cursor: pointer;
`
  
const RadioInput = styled.input`
  appearance: none;
  border: max(2px, 0.1em) solid gray;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  /* transition: border 0.2s ease-in-out; */

  &:checked {
    border: 0.4em solid ${primary3};
  }
  
  /* &:focus-visible {
    outline-offset: max(2px, 0.1em);
    outline: max(2px, 0.1em) dotted  ${primary3};
  } */

  &:hover {
    box-shadow: 0 0 0 max(4px, 0.2em) lightgray;
    cursor: pointer;
    }

`

const WrapRadio = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`


// 파일 업로드 영역
const ImageArea = styled.div`
  display: flex;
  align-items: center;
  max-width: 500px;
  gap: 20px;
`
const ImageInput = styled.input`
  width: calc(100% - 170px);
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${grey5};
  color: ${grey10};
`;

const FileUploadBtn = styled.button`
  display: block;
    border: none;
    width: 150px;
    height: 48px;
    padding: 10px;
    margin: 0 0 00;
    cursor: pointer;
    font-size: 16px;
    border-radius: 8px;
    color: ${primary4};
    border: 1px solid ${primary4};

    :hover {
      color: #fff;
      background: ${primary4};
    }
`

const DeleteFileUploadBtn = styled.button`
    display: block;
    border: none;
    width: 150px;
    height: 48px;
    padding: 10px;
    margin: 0 0 00;
    cursor: pointer;
    font-size: 16px;
    border-radius: 8px;
    color: ${error3};
    border: 1px solid ${error3};
`

const NoticeP = styled.p`
  font-size: 14px;
  color: ${error3};
  line-height: 1.5;
`