import logo from "../assets/images/logo.png";
import alarm from "../assets/images/ico-time-fast.svg";
import plus from "../assets/images/plus.png";
import member from "../assets/images/member.png";
import logoutIcon from "../assets/images/logout.png";
import profile from "../assets/images/profile.png";
import password from "../assets/images/password.png";
import appsIcon from "../assets/images/ico-apps.svg";
import chatIcon from "../assets/images/ico-push.svg";
import infoIcon from "../assets/images/ico-info.png";
import boardIcon from "../assets/images/ico-board.svg";
import settingIcon from "../assets/images/ico-settings-sliders.svg";
import styled from "styled-components";
import {
  grey3,
  grey1,
  primary4,
  grey7,
  grey10,
  grey5,
  grey6,
  grey9,
  primary1,
  MAIN_BACKGROUND_COLOR,
} from "../constants/color";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { instanceAxios } from "../api/axios";
import { deviceDetect } from "react-device-detect";
import {
  getCookie,
  setAccessTokenToCookie,
  setRefreshTokenToCookie,
  logout,
} from "../api/member/logout";
import { useRecoilState } from "recoil";
import {
  MyProfile,
  MyProject,
  MyPushProject,
  IsAlertOpen,
  AlertMessage,
  AlertCode,
  IsLogoutOpen,
  MyCategory,
} from "../atom/Atom";
import ProjectModal from "../components/modals/ProjectModal";
import settingHomepage from "../assets/images/homepageSetting.png";
import AlertModal from "../components/modals/AlertModal";

export default function Layout({ children }) {
  const navigate = useNavigate();
  const [isOpenMyMenu, setIsOpenMyMenu] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSession, setIsSession] = useState(true);
  const [minutes, setMinutes] = useState(59);
  const [seconds, setSeconds] = useState(59);
  const [refreshToken, setRefreshToken] = useState(getCookie("refreshToken"));
  const [myProfile, setMyProfile] = useRecoilState(MyProfile);
  const [myProject, setMyProject] = useRecoilState(MyProject);
  const [myPushProject, setMyPushProject] = useRecoilState(MyPushProject);
  const [myCategory, setMyCategory] = useRecoilState(MyCategory);
  const [subNavs, setSubNavs] = useState({
    pushNav: false,
    boardNav: false,
  });
  const { pushNav, boardNav } = subNavs;
  const openSubNav = (e) => {
    closeSubNav();
    const { value, name } = e.target;
    if(name === 'pushNav') subNavs.pushNav = true;
    if(name === 'boardNav') subNavs.boardNav = true;
  };
  const closeSubNav = () => {
    subNavs.pushNav = false;
    subNavs.boardNav = false;
  };
  
  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [isLogoutOpen, setIsLogoutOpen] = useRecoilState(IsLogoutOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);
  const [alertCode, setAlertCode] = useRecoilState(AlertCode);
  const isRefreshCookie = getCookie('refreshToken');
  // error Type
  const [isErrorType, setIsErrorType] = useState(null);

  const para = document.location.href;
  const params = para.search("pushdetail");
  const commonErrorPopup = (msg, type) => {
    setIsAlertOpen(true);
    setAlertMessage(msg);
    // 함수는 () => 함수이름
    // 바로 작동하면 안됨
    setIsErrorType(type);
  };

  // 로그아웃
  const handlelogout = () => {
    setAlertCode(-1);
    // setIsLogoutOpen(true);
    logout();
  };

  // refreshToken 만료
  const logoutTimer = () => {
    // 모달 열기
    setAlertCode(-1);
    setIsAlertOpen(true);
    setAlertMessage("세션이 만료되었습니다. 다시 로그인해주세요.");
    logout();
  };

  // 토큰 재발급
  const requestAccessToken = async () => {
    try {
      if(alertCode === -1) return;
      const response = await instanceAxios.post("/auth/refresh", {
        refreshToken: refreshToken,
      });
      setMinutes(59);
      setSeconds(59);
      const tokenType = response.data.tokenType;
      const headersToken = tokenType + response.data.accessToken;
      setAccessTokenToCookie(headersToken);
      setRefreshTokenToCookie(response.data.refreshToken);
      instanceAxios.defaults.headers.common["Authorization"] = headersToken;
      // console.log(response, "토큰 초기화");
    } catch (err) {
      console.error(err);
      if(err.response.status === 400) {
        commonErrorPopup("로그인이 필요한 서비스입니다.", navigate('/'));
        return;
      }
      if(err.response.status === 406) {
        commonErrorPopup("세션이 만료되었습니다. 다시 로그인해주세요.", navigate("/"));
      }
    }
  };

  const checkProject = async () => {
    try {
      if(alertCode === -1) return;
      const response = await instanceAxios.get("/all");
      if (response.status === 200) {
        if(response.data.length <= 0) {
          commonErrorPopup("유효한 홈페이지가 존재하지 않습니다.", () => logout );
          return;
        }
        // 1. 프로젝트 리스트 recoil 담기.
        setMyProject(response.data); 
        // 1차 카테고리 불러오기
        // 2. 현재 선택된 프로젝트 recoil 담기
        if (
          response.data.filter((item) => item.expiryDate === null)
          .length > 0
          ) {
            setMyPushProject(
              response.data.filter((item) => item.expiryDate === null)[0]
              );
            } else {
              setMyPushProject(response.data[0]);
            }
            
            // 3. 첫번째 프로젝트에서 code가 없을 때 모달 열기 & 카테고리 불러오기
        if (response.data[0].categoryCode === "") {
          // 1차 받고
          setIsOpenModal(true);
        }
      }
    } catch (err) {
      // login yet
      console.error(err);
    }
  };
 
  useEffect(() => {
    if (!refreshToken) {
      // 로그인 안되면 어떤 페이지들 바로 out!
      navigate("/");
    } else {
      checkProject();
    }

    requestAccessToken();
  }, []);

  // const handleOpenNav = () => {
  //   !isOpenNav ? setIsOpenNav(true) : setIsOpenNav(false);
  // };

  const handleOpenMyMenu = () => {
    !isOpenMyMenu ? setIsOpenMyMenu(true) : setIsOpenMyMenu(false);
  };
  const handlePushProject = (
    categoryCode,
    pid,
    name,
    projectUrl,
    expiryDate
  ) => {
    let body = {
      categoryCode: categoryCode,
      projectUrl: projectUrl,
      pid: pid,
      name: name,
      expiryDate: expiryDate,
    };
    setMyPushProject(body);
    console.log(myPushProject)
  };

  const handleAddProject = () => {
    if (myProject.length > 2) {
      setIsAlertOpen(true);
      setAlertMessage("프로젝트는 3개까지 가능합니다.");
    } else {
      setIsOpenModal(true);
    }
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          logoutTimer();
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  return (
    <>
    <WrapDiv>
      {/* 왼쪽 */}
      <Nav>
        {/* ----- 홈페이지 프로젝트 모달 ----- */}
        {isOpenModal && <ProjectModal setIsOpenModal={setIsOpenModal} />}
        <Link to="/dashboard">
          <MainLogo src={logo} alt="메인 로고" />
        </Link>
        <NavLi>
          <LI noline='true'>
            <WrapBell onClick={requestAccessToken}>
              <FlexTimer>
                <Bell src={alarm} alt="alarm" />
                <span>로그인 연장하기</span>
              </FlexTimer>
              <p style={{paddingLeft: "24px"}}>
                {minutes} : {seconds < 10 ? "0" + seconds : seconds}
              </p>
            </WrapBell>
          </LI>
          <LI style={{marginLeft: '24px'}}>
            <img src={appsIcon} alt="" />
            <LinkStyle to="/dashboard">대시보드</LinkStyle>
          </LI>
          <LI onClick={openSubNav} style={{marginLeft: '24px'}}>
          <img src={chatIcon} alt="" />
            <LinkStyle href="#none" name="pushNav">PUSH 관리</LinkStyle>
          </LI>
          {pushNav && (
            <SubNav>
              <SubLI firstSub='true'>
                <LinkStyle sub='true' to="/makePush">
                  PUSH 작성
                </LinkStyle>
              </SubLI>
              <SubLI>
                <LinkStyle sub='true' to="/pushList">
                  PUSH 리스트
                </LinkStyle>
              </SubLI>
            </SubNav>
          )}
          <LI style={{marginLeft: '24px'}}>
            <img src={settingIcon} alt="" />
            <LinkStyle to="/insertPush">스크립트 설치</LinkStyle>
          </LI>
          <LI onClick={openSubNav} style={{marginLeft: '24px'}}>
            <img src={boardIcon} alt="" />
            <LinkStyle href="#none" name="boardNav">게시판</LinkStyle>
          </LI>
          {boardNav && (
            <SubNav>
              <SubLI firstSub='true'>
                <LinkStyle sub='true' to="/faq">
                  FAQ
                </LinkStyle>
              </SubLI>
            </SubNav>
          )}
        </NavLi>
      </Nav>

      {/* 오른쪽 */}
      <WrapRight>
        <TopHeader>          
          <ProLi>
            {myProject.map(
              ({ categoryCode, pid, name, projectUrl, expiryDate }) => {
                if (name.length > 15) {
                  name = name.substring(0, 15) + "...";
                }
                if (expiryDate) {
                  if (pid !== myPushProject.pid) {
                    return (
                      <li
                        key={pid}
                        onClick={() =>
                          handlePushProject(
                            categoryCode,
                            pid,
                            name,
                            projectUrl,
                            expiryDate
                          )
                        }
                      > 
                        {params > 0 && <ProjectEXpiredOptions disabled>{name}</ProjectEXpiredOptions>}
                        {params <= 0 && <ProjectEXpiredOptions>{name}</ProjectEXpiredOptions>}
                      </li>
                    );
                  } else {
                    return (
                      <li key={pid}>
                        <ProjectExpiredSelectOptions>
                          {name}
                        </ProjectExpiredSelectOptions>
                      </li>
                    );
                  }
                } else {
                  if (pid !== myPushProject.pid) {
                    return (
                      <li
                        key={pid}
                        onClick={() =>
                          handlePushProject(
                            categoryCode,
                            pid,
                            name,
                            projectUrl,
                            expiryDate
                          )
                        }
                      >
                        {params > 0 && <ProjectOptions disabled>{name}</ProjectOptions>}
                        {params <= 0 && <ProjectOptions>{name}</ProjectOptions>}
                      </li>
                    );
                  } else {
                    return (
                      <li key={pid}>
                        <ProjectSelectOptions>{name}</ProjectSelectOptions>
                      </li>
                    );
                  }
                }
              }
            )}
            {/* 홈페이지 추가 */}
            {/* <li>
              <Icon src={plus} alt="plus" onClick={handleAddProject} />
            </li> */}
            <li>
              <Icon
                src={settingHomepage}
                alt="홈페이지 관리하기"
                onClick={() => {
                  navigate("/homepage");
                }}
              />
            </li>
          </ProLi>
          <MyButton onClick={handleOpenMyMenu}>
            <ProfileImg src={member} alt="프로필 버튼 사진" />            
            {myProfile.name}
            {isOpenMyMenu && (
              <MyMenu>
                <MyMenuLi first>{myProfile.email}</MyMenuLi>
                <MyMenuLi>
                  <LinkStyle to="/myPage">
                    <MyMenuIcon
                      profileIcon={true}
                      src={profile}
                      alt="내 정보 아이콘"
                    />
                    내 정보
                  </LinkStyle>
                </MyMenuLi>
                <MyMenuLi>
                  <LinkStyle to="/myPage/newPassword">
                    <MyMenuIcon src={password} alt="비밀번호 변경 아이콘" />
                    비밀번호 변경
                  </LinkStyle>
                </MyMenuLi>
                <MyMenuLi logout onClick={handlelogout}> 
                  <LinkStyle>
                    <MyMenuIcon src={logoutIcon} alt="로그아웃 아이콘" />
                    로그아웃
                  </LinkStyle>
                </MyMenuLi>
              </MyMenu>
            )}
          </MyButton>
        </TopHeader>
        <main>{children}</main>
      </WrapRight>
      {/* alert */}
      {isAlertOpen && <AlertModal isErrorType={isErrorType}></AlertModal>}
      {/* {isLogoutOpen && <LogoutMadal></LogoutMadal>} */}
      {/* alert */}
    </WrapDiv>
    </>
  );
}


const WrapDiv = styled.div`
  display: flex;
  font-family: "Pretendard";
`;

const Nav = styled.nav`
  position: fixed;
  background: ${grey1};
  padding: 30px 25px 0px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  z-index: 5;
  height: 100vh;
`;

const MainLogo = styled.img`
  width: 180px;
`;

const NavLi = styled.ul`
  margin-top: 25px;
`;
const ProLi = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WrapRight = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 232px;
`;

const TopHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${grey1};
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.15);
  padding: 21px;
  z-index: 4;
`;

const LI = styled.li`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 24px;
  font-weight: 600;
  position: relative;
  z-index: 1;
  width: fit-content;
`;

const SubNav = styled.ul`
  border-radius: 6px;
  margin: -16px 0 20px 14px;
  `;

const SubLI = styled.li`
  padding:  ${(props) => (props.firstSub ? `14px 0 7px 36px` :  `7px 0 12px 36px` )};
  font-size: 14px;
  font-weight: 600;
  `;

const LinkStyle = styled(Link)`
  /* sub: 왼쪽 메인 메뉴 */
  display: ${(props) => (props.sub ? null : `flex`)};
  z-index: ${(props) => (props.sub ? null : `1`)};
  align-items: ${(props) => (props.sub ? null : `center`)};
  gap: ${(props) => (props.sub ? null : `12px`)};
  color: ${(props) => (props.sub ? `${grey10}` : `${grey9}`)};
  font-weight: ${(props) => (props.sub ? 600 : 700)};
  width: 100%;

  :hover {
    color: ${(props) => (props.sub ? `${primary4}` : null)};
    font-weight: ${(props) => (props.sub ? 700 : null)};
  }
`;

const MyButton = styled.button`
  position: relative;
  display: block;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  float: right;
  padding: 6px 8px;
  cursor: pointer;
  font-weight: 700;
  color: ${grey10};
  margin-right: 20px;
  &:hover {
    /* background: ${grey3}; */
    border-radius: 8px;
  }
`;

const MyMenu = styled.ul`
  min-width: 140px;
  position: absolute;
  right: 20px;
  top: 58px;
  border-radius: 8px;
  box-shadow: 3px -3px 50px rgba(0, 0, 0, 0.13);
  background-color: ${grey1};
  text-align: center;
  padding: 16px;

  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 80%;
    height: 1px;
    background-color: ${grey5};
    left: 15px;
    top: 55px;
  }

  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border-bottom: 28px solid ${grey1};
    border-left: 0px solid transparent;
    border-right: 28px solid transparent;
    right: 0;
    top: -14px;
    transform: rotate(270deg);
  }
`;

const FlexTimer = styled.p`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
  margin-left: -2px;
`

const WrapBell = styled.div`
  cursor: pointer;
  margin-bottom: 45px;
  font-size: 14px;
  color: ${grey7};
`;

const Bell = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  padding: 0 8px;
  cursor: pointer;
`;

const MyMenuLi = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: ${(props) => (props.first ? `${grey10}` : ` ${grey7}`)};
  margin: ${(props) => (props.first ? "12px 0 26px" : "16px 0")};
  justify-content: ${(props) => (props.first ? "center" : "")};
  white-space: pre;
`;

const MyMenuIcon = styled.img`
  width: ${(props) => (props.profileIcon ? "18px" : "15px")};
`;

const ProjectOptions = styled.button`
  padding: 6px 0;
  font-size: 16px;
  font-weight: 500;
  color: ${primary4};
  border-bottom: 3px solid ${grey1};
  width: fit-content;
  margin-right: 12px;
  cursor: pointer;
  `;

const ProjectSelectOptions = styled.button`
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  color: ${grey1};
  background: ${primary4};
  width: fit-content;
  margin-right: 12px;
  cursor: pointer;
`;

const ProjectEXpiredOptions = styled.button`
  padding: 6px 0;
  font-size: 16px;
  color: ${grey6};
  font-weight: 500;
  border-bottom: 3px solid ${grey1};
  width: fit-content;
  margin-right: 12px;
  cursor: pointer;
`;

const ProjectExpiredSelectOptions = styled.button`
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  color: ${grey1};
  background: ${grey6};
  width: fit-content;
  margin-right: 12px;
  cursor: pointer;
`;

const ProfileImg = styled.img`
  width: 30px;
`;

