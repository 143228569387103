import React, { useState, useEffect, useRef, forwardRef } from "react";
import styled from "styled-components";
import { PushBox } from "../../components/containers/push/PushBox";
import Layout from "../../templates/Layout";
import {
  grey5,
  grey10,
  grey2,
  grey4,
  grey7,
  primary6,
  grey8,
  grey3,
  primary3,
  grey6,
  primary4,
  error3,
  error2,
  primary2,
  MAIN_BACKGROUND_COLOR,
  primary1,
  primary5,
  error1,
  subC,
} from "../../constants/color";
import wifi from "../../assets/images/ico-wifi.svg";
import speaker from "../../assets/images/ico-speaker.svg";
import bluetooth from "../../assets/images/ico-bluetooth.svg";
import lock from "../../assets/images/ico-lock.svg";
import flash from "../../assets/images/ico-flash.svg";
import flight from "../../assets/images/ico-flight.svg";
import downCloud from "../../assets/images/img-down.svg";
import arrow from "../../assets/images/ico-arrow-small.png";
import chrome from "../../assets/images/chrome_logo.png";
import activeCheck from "../../assets/images/active-check.png";
import Rectangle from "../../assets/images/demoBox.png";
import settingIcon from "../../assets/images/homepageSetting.png";import inActiveCheck from "../../assets/images/inactive-check.png";
import layoutPsd from "../../assets/image-layout.psd";
import { DemoBoxApp, DemoBoxWeb, DemoWrapBox } from "../../components/containers/push/DemoBox";
import {
  ActivePushButton,
  InactivePushButton,
  RegisterImageButton,
  RegisterIconButton,
  DeleteIconButton,
  DeleteImageButton,
} from "../../components/buttons/PushButtons";
import {
  AlertMessage,
  IsAlertOpen,
  MyProject,
  MyPushProject,
} from "../../atom/Atom";
import { useRecoilState } from "recoil";
import Loading from "../../components/loading/Loading";
import { NavLink, useNavigate } from "react-router-dom";
import { submitMakePush } from "../../api/message";
import {requestAddIcons, deleteIcon, requestIconAll} from '../../api/image'
import ErrorAlert from "../../error/ErrorAlert";
import { isCompositeComponent } from "react-dom/test-utils";

// datepicker library
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "../../styles/datepicker.css";
import { ko } from "date-fns/esm/locale";
import { set, sub } from "date-fns";

export default function MakePush() {
  const navigate = useNavigate();
  const [isCompletePush, setIsCompletePush] = useState(true);
  const [thisClock, setThisClock] = useState("");
  const [thisDate, setThisDate] = useState("");
  const [thisMonth, setThisMonth] = useState("");
  const [thisYear, setThisYear] = useState("");
  const [reserveDate, setReserveDate] = useState("");
  const [finalReserveDate, setFinalReserveDate] = useState("");
  const [reserveTime, setReserveTime] = useState("");
  const [finalReserveTime, setFinalReserveTime] = useState("");
  //예약 시작 시간을 선택했는지
  const [isSelected, setIsSelected] = useState(false);
  const [directDate, setDirectDate] = useState("");
  const [pushType, setPushType] = useState("");
  const [pushTypeDemo, setPushTypeDemo] = useState("advertising");
  const [pid, setPid] = useState("");
  const [myProject, setMyProject] = useRecoilState(MyProject);
  const [myPushProject, setMyPushProject] = useRecoilState(MyPushProject);
  const [iconUrl, setIconUrl] = useState("");
  const [iid, setIid] = useState("");
  // error Type
  const [isErrorType, setIsErrorType] = useState(null);
  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);

  const [resultError, setResultError] = useState([]);

  const [isWebCheck, setisWebCheck] = useState(true);
  const [isMobileCheck, setisMobileCheck] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isAdsCheck, setIsAdsCheck] = useState(false);
  const [isInfoCheck, setisInfoCheck] = useState(false);
  const [isEtcCheck, setisEtcCheck] = useState(false);
  const [isDirectCheck, setIsDirectCheck] = useState(false);
  const [isReserveCheck, setIsReserveCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    web: false,
    mobile: false,
    title: "",
    content: "",
    link: myPushProject.projectUrl.includes('https') ? myPushProject.projectUrl : "https://" + myPushProject.projectUrl ,
    image: "",
    date: "",
    pid: myPushProject.pid,
  });
  useEffect(() => {
    if (isWebCheck && isMobileCheck) {
      setPushType("web_push");
      setPushTypeDemo("other");
    } else if (isMobileCheck) {
      setPushType("mobile_app_push");
    } else if (isWebCheck) {
      setPushType("web_push");
    }
  }, [isWebCheck, isMobileCheck]);
  // 라디오 체크
  const handleWebCheckRadio = () => {
    isWebCheck ? setisWebCheck(false) : setisWebCheck(true);
  };
  const handleMobileCheckRadio = () => {
    isMobileCheck ? setisMobileCheck(false) : setisMobileCheck(true);
  };
  const handleAdsCheckRadio = () => {
    isAdsCheck ? setIsAdsCheck(false) : setIsAdsCheck(true);
  };
  const handleInfoCheckRadio = () => {
    isInfoCheck ? setisInfoCheck(false) : setisInfoCheck(true);
  };
  const handleEtcCheckRadio = () => {
    isEtcCheck ? setisEtcCheck(false) : setisEtcCheck(true);
  };

  const handleDirectCheckRadio = () => {
    isDirectCheck ? setIsDirectCheck(false) : setIsDirectCheck(true);
    setIsReserveCheck(false);
    getDirectDate();
  };

  const handleReserveCheckRadio = () => {
    isReserveCheck ? setIsReserveCheck(false) : setIsReserveCheck(true);
    setIsDirectCheck(false);
    getDirectDate();
  };

  // 메세지 입력
  const { web, mobile, ads, info, etc, title, content, link, image, date } =
    inputs;

  const handleInputValues = (e) => {
    if (isMobileCheck || isWebCheck) {
      e.preventDefault();
      const { name, value } = e.target;
      setInputs({
        ...inputs,
        [name]: value,
        web: isWebCheck,
        mobile: isMobileCheck,
        ads: isAdsCheck,
        info: isInfoCheck,
        etc: isEtcCheck,
      });
    } else {
      alert("Please select Push Type");
    }
  };

  const handleLink = (e) => {
    const regex = /^(https:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/gm;

    const isRightUrl = regex.test(link);
    if(!isRightUrl) setInputs({...inputs, link : myPushProject.projectUrl.includes('https') ? myPushProject.projectUrl : "https://" + myPushProject.projectUrl})
    if(!link.includes( myPushProject.projectUrl)) {
      setInputs({...inputs, link :  myPushProject.projectUrl.includes('https') ? myPushProject.projectUrl : "https://" + myPushProject.projectUrl});
    }
  };

  useEffect(() => {
    if(title.length > 20) {
      setInputs({...inputs, title: title.substring(0,20)})
    };
    if(content.length > 225) {
      setInputs({...inputs, content: content.substring(0,225)})
    };
  }, [title, content])



  // 이미지 파일 업로드
  const [iconArr, setIconArr] = useState([]);
  const imageInputRef = useRef(null);
  const iconInputRef = useRef(null);
  const [demoImg, setDemoImg] = useState("");
  const [iconImg, setIconImg] = useState("");
  const [clickAddIconBtn, setClickAddIconBtn] = useState(false);
  // const [selected, setSelectedIcon] = useState("");
  const formData = new FormData();
  const [previewImg, setPreviewImg] = useState("");
  const onImgInputBtnClick = (e) => {
    e.preventDefault();
    imageInputRef.current.click();
  };

  const deleteUploadImage = (e) => {
    setPreviewImg("");
    setDemoImg("");
  };

  const handleUploadImage = (e) => {
    e.preventDefault();
    let fileList = e.target.files;
    const isSizeOk = limitImgSize(fileList[0], 'image');
    if(!isSizeOk) return;

    const imageUrl = URL.createObjectURL(fileList[0]);
    setPreviewImg(fileList[0]);
    setDemoImg(imageUrl);   

    e.target.value = ''; 
  };

  // 1. 아이콘 클릭
  const onIconInputBtnClick = (e) => {
    e.preventDefault();
    setClickAddIconBtn(true);
    if (iconArr.length > 2) {
      setIsAlertOpen(true);
      setAlertMessage("아이콘은 3개까지 등록이 가능합니다.");
    } else {
      iconInputRef.current.click();
    }
  };

  // 2. 아이콘 파일 선택
  const handleUploadIcon = (e) => {
    const fileList = e.target.files;
    setIconImg(fileList[0]);
    e.target.value = ''; 
  };
  // 3. 아이콘 추가 api 호출 
  useEffect(() => {
    const isIconSizeOk = limitImgSize(iconImg, 'icon');
    if(!isIconSizeOk) return ;
    setIsLoading(true);
    if(iconImg) {
      requestAddIcons(formData, setIconImg,iconImg, requestIconAll,myPushProject,setIconArr,commonErrorPopup, setResultError, iconUrl, setIsLoading);
    }
  }, [iconImg]);

  useEffect(() => {
    if(iconArr.length > 0) {
    // 시작하면 아이콘
    setIconUrl(iconArr[0].url);
    setIid(iconArr[0].url.split("/").at(-1))
  }
  }, [iconArr])


  // 아이콘 최대 용량 설정
  function limitImgSize (iconImg, type) {
    if (iconImg) {
      let maxSize = 10 * 1024 * 1024;
      let fileSize = iconImg.size;
  
      if(fileSize > maxSize){
        if(type === "icon") setIconImg("");
        if(type === "image") {
          setPreviewImg("");
          setDemoImg("");
        } 

        setIsAlertOpen(true);
        setAlertMessage("파일 용량이 초과되었습니다.");

        return false;
      } else {
        return true;
      }
    }
  }

  useEffect(() => {
    if(myPushProject) {
      requestIconAll(myPushProject, setIconArr);
      if (myPushProject.expiryDate) {
        setIsAlertOpen(true);
        setAlertMessage("삭제예정 홈페이지입니다");
        navigate("/dashboard");
      } 
    }
     
    // 홈페이지 바뀔 때, 데모이미지의 사진들도 초기화
    setDemoImg("");
    setIconUrl("");
    setIid("");
  }, [myPushProject]);

  const handleIconSelect = (e) => {
    const selectedSrc = e.target.src;
    if (selectedSrc === iconUrl) {
      setIconUrl("");
      setIid("");
    } else {
      if(selectedSrc !== undefined) {
        setIconUrl(selectedSrc);
        setIid(selectedSrc.split("/").at(-1));
      }
    }
  };


  const commonErrorPopup = (msg, type) => {
    setIsAlertOpen(true);
    setAlertMessage(msg);
    // 함수는 () => 함수이름
    // 바로 작동하면 안됨
    setIsErrorType(type);
  };

  const getDirectDate = () => {
    const offset = 1000 * 60 * 60 * 9;
    // const koreaNow = new Date(new Date().getTime() + offset);
    const koreaNow = new Date();
    const getYear = koreaNow.getFullYear().toString();
    const getMonth = (koreaNow.getMonth() + 1).toString();
    const getDate = koreaNow.getDate().toString();
    // 현재 년
    setThisYear(getYear);    
    // 현재 월
    setThisMonth(getMonth.length > 1 ? getMonth :  "0" + getMonth);    
    // 현재 일
    setThisDate(getDate.length > 1 ? getDate  :  "0" + getDate);    
    // 예약 시간
    // setReserveMin(koreaNow.toISOString().slice(0, 16).replace("T", " "));
    // 지금 시간
    setThisClock(koreaNow.toString().split(" ")[4].slice(0,5));
    setDirectDate(thisYear + "-" + thisMonth + "-" + thisDate + " " + thisClock)
  };

  useEffect(() => {
    getDirectDate();
  }, [thisYear, thisMonth, thisDate, thisClock]);

  const onSelectDate = (date) => {
    setReserveDate(date);
    setReserveTime("");
    setFormatReserveDate(date)
  };

  const setFormatReserveDate = (date) => {
    const formattedYear = date.getFullYear().toString();
    const formattedMonth = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString() ;
    const formattedDay = date.getDate().toString().length === 1 ?  "0" + (date.getDate()).toString() : (date.getDate()).toString();
    setFinalReserveDate(formattedYear + "-"+ formattedMonth+ "-"+formattedDay);
  } 
  

  const onSelectTime = (time) => {
    setReserveTime(time);
    setIsSelected(true);
    handleReserveTime(time)
  };

  const handleReserveTime = function (time) {
    const selectHour = time.getHours().toString().length === 1 ? "0" + time.getHours() : time.getHours();
    const selectMinute = time.getMinutes().toString().length === 1 ? "0" + time.getMinutes() : time.getMinutes();
    const thisHour = thisClock.split(":")[0];
    const thisMinute = thisClock.split(":")[1];
    setFinalReserveTime(selectHour + ":"+selectMinute);
   
    if (isDirectCheck) return;
    if(!reserveDate) {
      setIsCompletePush(false);
      setReserveTime("");
      commonErrorPopup("날짜를 선택해주세요.", null);
      return;
    } else if(directDate.split(' ')[0] === finalReserveDate) {
      if(selectHour < thisHour || (selectHour === thisHour && selectMinute < thisMinute)) {
        setIsCompletePush(false);
        setReserveTime("")
        commonErrorPopup("현재보다 이른 시간은 선택할 수 없습니다.", null);
        return;
      } 
    } else {
      setIsCompletePush(true); 
    }
  } 
  
  const onClickSubmit = (e) => {
    e.preventDefault();
    checkConditons();    
  }

  const checkConditons = () => {
    const regex = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/gm;
    if (!regex.test(link)) {
        setIsCompletePush(false);
        setIsAlertOpen(true);
        setAlertMessage('링크 형식이 올바르지 않습니다.');
        return;
      } else if (!isMobileCheck && !isWebCheck) {
        setIsCompletePush(false);
        return alert("PUSH 유형을 선택해주세요.");
      } else if (!title || !content || !link) {
        setIsCompletePush(false);
        return alert("메세지 제목, 내용, 링크는 필수 사항입니다.");
      } else  if (!isDirectCheck && !isReserveCheck) {
        setIsCompletePush(false);
        return alert("발송 유형을 선택해주세요.");
      } else {
        submitMsg();
      }
  };
  
  const submitMsg = () => {
    if (isReserveCheck) {
      inputs.date = finalReserveDate + " " + finalReserveTime;
    } else if(isDirectCheck) {
      inputs.date = directDate;
    }
    inputs.image = previewImg;
    
    let data = {
      pushType: pushType,
      messageType: pushTypeDemo,
      title: inputs.title,
      content: inputs.content,
      sendType: "advertising",
      link: inputs.link,
      sendTime: inputs.date,
      iid: iid,
    };


    formData.append('file', previewImg);
    
    formData.append(
      "request",
      new Blob([JSON.stringify(data)], { type: "application/json" })
      );
      
    setIsLoading(true);
    submitMakePush(myPushProject, formData, setIsAlertOpen, setAlertMessage,setIsLoading, isLoading, navigate);
     
  }


  return (
    <Layout>
      {/* 로딩창 */}
      {isLoading && <Loading></Loading>}
      <div style={{padding: "40px"}}>
        <TitleWrapper>
          {/* <WrapHomepages>
            {myPushProject.name ? myPushProject.name : "프로젝트를 선택해주세요"}
          </WrapHomepages> */}
          <PageTitle>PUSH 작성 </PageTitle>
          <Message>
            고객들에게 날릴 웹푸시를 작성 및 등록할 수 있는 페이지입니다.
          </Message>
        </TitleWrapper>

        <div>
          
        </div>
        <SectionWrapper >
          <RightPushBoxDiv>
            <PushBox>
              <Title>01.메시지 내용</Title>
              <WrapMessage>
                <SubTitle><EssentialMark>*</EssentialMark>타이틀</SubTitle>
                <Input
                  type="text"
                  placeholder="제목을 입력해주세요. (최대 20글자)"
                  value={title}
                  name="title"
                  onChange={handleInputValues}
                ></Input>
              </WrapMessage>
              <WrapAreaMessage>
                <SubTitle><EssentialMark>*</EssentialMark>내용</SubTitle>
                <ContentArea
                  type="text"
                  placeholder="웹푸시에 넣을 내용을 입력해주세요. (최대 225글자)"
                  value={content}
                  name="content"
                  onChange={handleInputValues}
                ></ContentArea>
              </WrapAreaMessage>
              <WrapMessage>
                <SubTitle><EssentialMark>*</EssentialMark>클릭 이동 링크</SubTitle>
                <Input
                  type="text"
                  placeholder="연결할 주소를 입력해주세요 ex.(https://www.example.com)"
                  value={myPushProject.projectUrl ? link : ""}
                  name="link"
                  onKeyUp={handleLink}
                  onChange={handleInputValues}
                ></Input>
              </WrapMessage>
              <WrapMessage>
                <SubTitle>이미지 (선택)</SubTitle>
                <ImageInput
                  placeholder="이미지를 등록하세요"
                  value={previewImg ? previewImg.name : ""}
                  name="image"
                  readOnly={true}
                ></ImageInput>
                <ImageInput
                  placeholder="이미지를 등록하세요"
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  ref={imageInputRef}
                  onChange={handleUploadImage}
                ></ImageInput>
                {(previewImg && demoImg) && 
                  <DeleteImageButton handleUploadImage={deleteUploadImage}>
                    이미지 삭제
                  </DeleteImageButton>
                }
                {(!previewImg || !previewImg) && 
                  <RegisterImageButton handleUploadImage={onImgInputBtnClick}>
                    이미지 등록
                  </RegisterImageButton>
                }         
              </WrapMessage>
              <WrapMessage>
                <SubTitle></SubTitle>
                <StandardBox>
                  <PsdDiv>
                    <PsdLink href={layoutPsd} download={"아이프로모션_이미지_레이아웃_가이드"} name="아이프로모션_이미지_레이아웃_가이드">
                      <span>레이아웃 다운로드</span>
                    <PsdImg src={downCloud} alt="레이아웃 다운로드 아이콘" />
                    </PsdLink>
                  </PsdDiv>
                  <StandardP style={{color:`${error1}`}}>* 이미지는 레이아웃 파일을 다운받아 제작하는 것을 권장(수정 사용이 가능합니다.)</StandardP>
                  <StandardP>* 권장 크기 1038 x 519 px (2:1)/ png, jpg, jpeg만 가능/ 최대 1MB</StandardP>
                  <StandardP>* png의 경우 뒷배경이 없으면 검정색으로 표현됨</StandardP>
                </StandardBox>
              </WrapMessage>

              {/* 아이콘!!!! 🐰 */}
              <WrapMessage icon>
                <SubTitle><EssentialMark>*</EssentialMark>로고</SubTitle>
                <AlignIcon>
                  {/* map 돌릴 예정 */}
                  {iconArr.map(({ url }, index) => {
                    if (url === iconUrl) {
                      return (
                        <SelectIconDiv key={index}>
                          <IconBox onClick={handleIconSelect}>
                            <Icon src={url} alt={url} />
                          </IconBox>
                        </SelectIconDiv>
                      );
                    } else {
                      return (
                        <IconBox onClick={handleIconSelect} key={index}>
                          <Icon src={url} alt={url} />
                        </IconBox>
                      );
                    }
                  })}
                </AlignIcon>
                <ImageInput
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  ref={iconInputRef}
                  onChange={handleUploadIcon}
                />
                <IconButnsAlign>
                {resultError.length > 0 && <ErrorAlert data={resultError} open={true}/>}
                  {myPushProject.expiryDate ? null : (
                    <RegisterIconButton handleUploadIcon={onIconInputBtnClick}>
                      로고 등록
                    </RegisterIconButton>
                  )}
                  {iconUrl.length > 0 ? (
                    <DeleteIconButton deleteIcon={() => deleteIcon(iid,myPushProject,setIsAlertOpen,setAlertMessage, setIconUrl,setIconArr, setIid)}>
                      로고 삭제
                    </DeleteIconButton>
                  ) : null}
                </IconButnsAlign>
              </WrapMessage>
              <WrapMessage>
                <SubTitle></SubTitle>
                <StandardBox>
                  <StandardP>* 권장 크기 256 x 256 px (1:1)/ png, jpg, jpeg만 가능/ 최대 512kb</StandardP>
                  <StandardP>* png의 경우 뒷배경이 없으면 검정색으로 표현됨</StandardP>
                </StandardBox>
              </WrapMessage>
            </PushBox>
            <PushBox>
              <Title>02.발송 유형</Title>
              <RadioList>
                <RadioLi onClick={handleDirectCheckRadio}>
                  {!isDirectCheck && (
                    <img src={inActiveCheck} alt="즉시발송 체크 아이콘" />
                  )}
                  {isDirectCheck && (
                    <img src={activeCheck} alt="즉시발송 체크 아이콘" />
                    )}
                  즉시발송
                </RadioLi>
                <ReserveWrapper>
                  <RadioLi onClick={handleReserveCheckRadio}>
                    {!isReserveCheck && (
                      <img src={inActiveCheck} alt="예약발송 체크 아이콘" />
                    )}
                    {isReserveCheck && (
                      <img src={activeCheck} alt="예약발송 체크 아이콘" />
                    )}
                    예약발송
                  </RadioLi>
                  {isReserveCheck && (
                    <DatePicker selected={reserveDate} onChange={ onSelectDate } locale={ko} dateFormat="yyyy-MM-dd" minDate={new Date()}  placeholderText="예약 날짜"/>
                  )}
                  {isReserveCheck && (
                  <DatePicker 
                  selected={reserveTime}
                  onChange={onSelectTime}
                  locale={ko}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="예약 시간"
                  minDate={new Date()}
                  dateFormat="p"
                  placeholderText="예약 시간"/>
                  )}
                </ReserveWrapper>
              </RadioList>
            </PushBox>
            <ButtonWrapper>
              {content &&
                title &&
                link &&
                iid &&
                myPushProject.pid &&
                !myPushProject.expiryDate &&
                (isMobileCheck || isWebCheck) &&
                (isDirectCheck || isReserveCheck) && (
                  <ActivePushButton handleSubmit={onClickSubmit}>
                    발송하기
                  </ActivePushButton>
                )}
              {(!content ||
                !title ||
                !link ||
                !iid ||
                !myPushProject.pid ||
                myPushProject.expiryDate ||
                (!isMobileCheck && !isWebCheck) ||
                (!isDirectCheck && !isReserveCheck)) && (
                <InactivePushButton>발송하기</InactivePushButton>
              )}
            </ButtonWrapper>
          </RightPushBoxDiv>
          <DemoSection>
            <DemoWrapBox>
              <Title>
                PC 미리보기
              </Title>
              <DemoWrapperBox>
                <DemoBoxWeb>
                  <WrapDemoContent>
                    <WrapBrowserImg>
                      <ChromeImg src={chrome} alt="크롬 로고" />
                      <LinkMessage>i-promotion.co.kr</LinkMessage>
                      <DemoTime>• 지금<span><DemoArrow up src={arrow} alt="미리보기 펼쳐짐" /></span></DemoTime>
                    </WrapBrowserImg>
                    <div
                      style={{
                        display: "flex",
                        gap: "18px",
                        alignItems: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <SubDemoTitle>{inputs.title}</SubDemoTitle>
                        <SubMessage>{inputs.content}</SubMessage>
                      </div>
                    <WrapIconDiv>
                        <img style={{ width: "60px" }} src={iconUrl ? iconUrl : Rectangle} alt="아이콘 이미지" />
                    </WrapIconDiv>
                  </div>
                    {previewImg && 
                      <WrapDemoImg>
                      <DemoImg
                        src={demoImg ? demoImg : Rectangle}
                        alt="데모이미지"
                      />
                    </WrapDemoImg>
                    }
                  </WrapDemoContent>
                </DemoBoxWeb>
              </DemoWrapperBox>
            </DemoWrapBox>
            <DemoWrapBox>
              <Title>
                모바일 미리보기
              </Title>
              <DemoWrapperBox>
                <AppDesignBox>
                  <AppTopFlexBox>
                    <li>12:00 1월 1일 토요일</li>
                    <li><img src={settingIcon} alt="" style={{width: '18px'}}/></li>
                  </AppTopFlexBox>
                  <AppTopFlexBox>
                    <AppTopIcons wifi></AppTopIcons>
                    <AppTopIcons speaker></AppTopIcons>
                    <AppTopIcons bluetooth></AppTopIcons>
                    <AppTopIcons lock></AppTopIcons>
                    <AppTopIcons flash></AppTopIcons>
                    <AppTopIcons flight></AppTopIcons>
                  </AppTopFlexBox>
                  <AppUnderLine></AppUnderLine>
                  <DemoBoxApp>
                    <WrapDemoContent>
                      <div style={{display:"flex", justifyContent:'space-between',alignItems: 'flex-start', width: '100%'}}>
                        <WrapBrowserImg app>
                          <ChromeImg app src={chrome} alt="크롬 로고" />
                          <LinkMessage>Chrome</LinkMessage>
                          <LinkMessageSamll>i-promotion.co.kr</LinkMessageSamll>
                          <LinkMessageSamll>지금</LinkMessageSamll>
                        </WrapBrowserImg>
                        <WrapIconDiv app>
                          <img style={{ width: "45px", borderRadius:"4px" }} src={iconUrl ? iconUrl : Rectangle} alt="아이콘 이미지" />
                        </WrapIconDiv>
                        <DemoArrow up src={arrow} alt="미리보기 펼쳐짐" />
                      </div>
                      <div
                        style={{
                          marginTop: "-20px",
                          marginLeft: "54px",
                          width: '245px',
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <SubDemoTitle app>{inputs.title}</SubDemoTitle>
                          <SubMessage app>{inputs.content}</SubMessage>
                        </div>
                      </div>
                      {previewImg && 
                        <WrapDemoImg app>
                        <DemoImg
                          app
                          src={demoImg ? demoImg : Rectangle}
                          alt="데모이미지"
                        />
                      </WrapDemoImg>
                      }
                    </WrapDemoContent>
                  </DemoBoxApp>
                </AppDesignBox>
              </DemoWrapperBox>
            </DemoWrapBox>
          </DemoSection>
        </SectionWrapper>
      </div>
    </Layout>
  );
}

const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;
const SectionWrapper = styled.section`
  margin-bottom: 40px;
  position: relative;
  display: flex;
  gap: 20px;
`;

const RightPushBoxDiv = styled.div`
  flex: 1;
`

const DemoSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 480px;
`;
const DemoFoldpBtnList = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`
const FoldBtn = styled.button`
  font-size: 14px;
  font-weight: 700;
  color: ${grey6};
`
const PageTitle = styled.h2`
  color: ${primary5};
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 12px;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 12px;
  color: ${grey10};

  display: flex;
  align-items: center;
  gap: 7px;
`;

const SubTitle = styled.h4`
  width: 150px;
  font-size: 16px;
  font-weight: 500;
  padding: 6px;
  color: ${grey7};
`;
const SubDemoTitle = styled.h4`
  display: block;
  width: ${(props) => (props.app ? "100%" : "300px")};;
  padding-bottom: ${(props) => (props.app ? "6px" : "12px")};
  font-size: 16px;
  font-weight: 400;
  color: ${grey10};
  white-space: pre-wrap;
  word-break:keep-all; 
  word-wrap:break-word
`;

const EssentialMark = styled.span`
  color: ${error3};
  font-size: 12px;
`

const Message = styled.p`
  color: ${grey7};
  font-size: 14px;
`;

const WrapMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const WrapAreaMessage = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const DemoWrapperBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  background: ${grey2};
  border-radius: 8px;
  margin-top: 16px;
`;

const Input = styled.input`
  width: 100%;
  padding: 16px;
  margin-top: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${grey5};
  color: ${grey10};
`;
const InputDate = styled.input`
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${grey5};
  color: ${grey10};
`;
const ImageInput = styled.input`
  width: calc(100% - 170px);
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${grey5};
  color: ${grey10};
`;
const ContentArea = styled.textarea`
  width: 100%;
  line-height: 1.5;
  height: 200px;
  padding: 16px;
  margin-top: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  border: 1px solid ${grey5};
  color: ${grey10};
  resize: none;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${primary3};
    border-radius: 8px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: none;
    border-radius: 4px;
  }
`;

const RadioList = styled.ul`
  display: flex;
  margin: 14px 0;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
`;

const RadioLi = styled.li`
  display: flex;
  margin-right: 20px;
  align-items: center;
  gap: 4px;
`;
const SubMessage = styled.p`
  color: ${grey8};
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -0.5px;
  padding-top:  ${(props) => (props.app ? "0" : "6px")};
  white-space:pre-wrap;
  word-break:break-word; 
  word-wrap:break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp : ${(props) => (props.small ? 1 : 4)};
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
const LinkMessage = styled.p`
color: ${grey8};
font-size: 14px;
`;
const LinkMessageSamll = styled.p`
color: ${grey8};
font-size: 12px;
`;

const DemoTime = styled.p`
  color: ${grey8};
  font-size: 14px;
  cursor: pointer;
`
const DemoArrow = styled.img`
  width : 10px;
  height: 10px;
  margin-left: 5px;
  rotate: ${(props) => (props.up ? '180deg' : 0)};
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 320px;
  margin: 20px auto;
`;
const ReserveWrapper = styled.div`
  display: flex;
  width: 450px;
  height: 36px;
  gap: 8px;
  justify-content: flex-start;
`;
const WrapDemoImg = styled.div`
  width: ${(props) => (props.app ? `calc(100% - 54px)` : "100%")};
  height: 188px;
  /* background: ${grey3}; */
  padding: 0 0px;
  margin-top: 20px;
  margin-left:  ${(props) => (props.app ? `54px` : "0")};
  box-sizing: border-box;
  border-radius:0 0 2px 2px;
  border-radius: ${(props) => (props.app ? `32px` : "0")};
`
const DemoImg = styled.img`
  width: 100%;
  height: 188px;
  object-fit: fill;
  border-radius: ${(props) => (props.app ? `32px` : "0")};
`;

const SelectIconDiv = styled.div`
  background: ${primary1};
`;

const IconBox = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  padding: 5px;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AlignIcon = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  margin: 20px 0 0 29px;
`;

const WrapDemoContent = styled.div`
  padding: 20px;
  width: 100%;
`;

const WrapBrowserImg = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: ${(props) => (props.app ? `flex-start` : "center")};
  gap: 8px;
`;

const ChromeImg = styled.img`
  width: 18px;
  height: 18px;
  background: ${(props) => (props.app ? `${grey4}` : "")};
  border-radius: ${(props) => (props.app ? `50%` : "")};
  padding: ${(props) => (props.app ? `10px` : "")};
  margin-right: ${(props) => (props.app ? `8px` : "")};
`;
const WrapIconDiv = styled.div`
  width: ${(props) => (props.app ? `45px` : "60px")};
  height: ${(props) => (props.app ? `45px` : "60px")};
  border-radius: ${(props) => (props.app ? `4px` : "0")};
  margin-right: ${(props) => (props.app ? `4px` : "0")};
  background: ${grey4};
`;

const IconButnsAlign = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
`;


const CustomDatePicker = styled.button`
  width: 120px;
  padding: 10px;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  color: #262626;
  font-weight: 500;
  box-sizing: border-box;
  text-align: center;
  `

  /* 규격 텍스트 추가 */
  const StandardBox = styled.div`
    width: 100%;
    
  `
  const StandardP = styled.p`
    // margin-left: 130px;
    color: ${primary2};
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
  `

  const PsdDiv = styled.div`
    width: 215px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid ${primary2};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 16px 0;
    box-sizing: border-box;
    padding: 12px;
  `
  const PsdLink = styled.a`
    width: 100%;
    color: ${primary3};
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;  
    display: flex;
    align-items: center;
    justify-content: space-between;
  `
  const PsdImg = styled.img`
    width: 26px;
  `

  const AppDesignBox = styled.div`
    border-radius: 16px;
    background: ${grey3};
    padding: 0 0;
    border: 1px solid ${grey5};
    position: relative;
  `

  const AppTopFlexBox = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-size: 14px;
  `
  const AppTopIcons = styled.li`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${(props) => (props.wifi ? `url('${wifi}') ${subC}` : null)};
    background: ${(props) => (props.speaker ? `url('${speaker}') ${subC}` : null)};
    background: ${(props) => (props.bluetooth ? `url('${bluetooth}') ${grey4}` : null)};
    background: ${(props) => (props.lock ? `url('${lock}') ${grey4}` : null)};
    background: ${(props) => (props.flash ? `url('${flash}') ${grey4}` : null)};
    background: ${(props) => (props.flight ? `url('${flight}') ${grey4}` : null)};
    background-repeat: no-repeat;
    background-position: center;
    ;
  `

  const AppUnderLine = styled.div`
    width: 25px;
    height: 3px;
    background: ${grey5};
    border-radius: 32px;
    margin: 0 auto 16px;
  `