import styled from "styled-components";
import { grey1 } from "../../../constants/color";

const Box = styled.div`
  background: ${grey1};
  padding: 60px;
  border-radius: 16px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
`;

function SignupBox({ children }) {
  return <Box style={{position: "fix"}}>{children}</Box>;
}

function LoginBox({ children }) {
  return <Box>{children}</Box>;
}

export {SignupBox, LoginBox}