import styled from "styled-components";
import { grey3, grey4, grey6, grey7, primary1, primary5 } from "../constants/color";
import Layout from "../templates/Layout";
import arrowIcon from "../assets/images/ico-faq-arrow.svg"
import searchIcon from "../assets/images/ico-search-blue.svg"
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

function Faq() {
  const [faqs, setFaqs] = useState( [
    {title: "설치 조건을 알려주세요.", msg: `1. 설치하려는 사이트에 SSL 인증서를 설치하여 HTTPS:// 로 시작하는 URL을 사용하여야 합니다.\n2. 방문자에게 웹푸시 허용요청 팝업을 띄우려는 페이지에 스크립트를 삽입할 수 있어야 합니다.\n3. Service Worker 파일을 사이트의 루트 경로에 저장할 수 있어야합니다.`,open: true},
    {title: "https 사이트 보안이 아닌데, 설치가 되나요?", msg: "웹푸시는 제3자가 악의적으로 사용자에게 푸시메세지를 보내는 것을 방지하기 위해 전송되는 데이터를 HTTPS Layer로 암호화 하여 전달하도록 되어있습니다. 따라서 HTTPS SSL인증서가 설치되지 않은 사이트에서는 사용할 수 없습니다.",open: false},
    {title: "스크립트 파일을 삽입해도 알림이 안떠요.", msg: "개발자 콘솔에서 에러가 발생되고 있는지 확인 후 문의주세요.",open: false},
    {title: "지원하는 브라우저가 무엇인가요?", msg: "현재는 PC의 Chrome, Edge, Firefox와 Android 기기의 Chrome 및 삼성브라우저에서 푸시수신이 가능합니다.",open: false},
    {title: "설치 완료했는데, PC에서만 메시지가 갑니다.", msg: "웹사이트에 따라 모바일기기에서 접속하는 경우 자동으로 URL이 변경되는 경우(Ex. www.naver.com -> m.naver.com)가 있습니다. 이경우 모바일기기에서 접속되는 URL을 별도 사이트로 추가하여 개별 설치하여 사용할 수 있습니다.",open: false},
    {title: "고객의 어떤 정보 기반으로 메시지가 발송 되나요?", msg: "웹사이트 방문자가 웹브라우져에서 사이트에 방문하여 푸시알림수신승인요청 팝업에서 확인을 누르면 개별 해당 브라우져에 대해 고유한 값(토큰)을 생성하게 됩니다. 이 토큰정보를 서버에 저장하게되며, 이를 기반으로 방문자의 푸시알림 수신승인여부, 방문사이트등을 구분하여 푸시메세지를 보내게 됩니다.",open: false},
    {title: "고객의 어떤 정보를 획득하나요?", msg: "개별 방문자별로 부여되는 임의의 고유한 값(토큰) 이외에는 어떠한 정보도 수집하지 않습니다.",open: false},
    {title: "알림 팝업의 내용 수정이 가능한가요", msg: "현재는 수정 불가능 하지만, 추후버전에서는 커스텀마이징이 가능하도록 업데이트 예정입니다.",open: false},
    {title: "정상적으로 설치가 됐는데 메시지 발송이 안돼요.", msg: "서버의 점검 등의 이유로 메시지가 발송되지 않을 수 있습니다. 발송예정시간이 경과한 메시지는 서버점검 등이 마무리되어 발송가능하게 되는 경우 즉시 순서대로 발송이 재개됩니다.",open: false},
    {title: "정상 설치되었는지 확인하는 방법은 무엇인가요?", msg: "사이트를 등록하고 스크립트 삽입 및 Service Worker 업로드를 완료하면 스크립트를 삽입한 후 스크립트를 삽입한 페이지를 방문하면 즉시 푸시알림수신승이요청 팝업이 화면에 표시됩니다.",open: false},
    {title: "푸시 알림 뜨게 하고 싶지 않아요.", msg: "edge, 크롬 : 우측의 그림처럼 주소창 왼쪽에 있는 자물쇠 아이콘을 클릭하면 나타나는 사이트 정보창에서 알림 선택상자의 '허용' 을 클릭하여 '차단' 으로 변경하면 푸시알림이 더 이상 수신되지 않습니다.\n다시 푸시를 수신하려면 마찬가지로 '차단'을 '허용'으로 변경하면 푸시알림이 다시 수신됩니다.",open: false},
    {title: "메시지 즉시 발송 했는데 메시지가 도착하지 않아요.", msg: "웹푸시 발송서버는 모든 사용자의 푸시요청을 순차적으로 발송합니다. 따라서 다른 사용자가 많은 양의 메세지를 먼저 발송한 경우 해당 사용자의 메세지가 모두 발송될때까지 발송이 지연될 수 있습니다.발송 대기건이 모두 해소되면 발송서버는 즉시 다음 메세지를 전송하므로 발송 신청한 메세지는 누락없이 발송됩니다."},
    {title: "메시지가 한 밤중에 온다고 항의 옵니다.", msg: "메세지를 특정한 시간에 발송할 수 있도록 발송메세지 작성시 발송예정일시를 지정할 수 있습니다. 발송예정일시를 지정한 경우, 해당 일시에 웹푸시 메세지 발송이 시작됩니다.\n부득이 야간에 작성하여야 하는 경우 발송예정일시를 등록하여 예약발송기능을 활용하세요.",open: false},
  ]);

  // open, close
  const handleDrop= (idx, arr) => {
    if(arr === faqs) {
      setFaqs(
        faqs.map((faq, i) => {
          if (i === idx) {
            faq.open = !faq.open;
          } else {
            faq.open = false;
          }
          return faq;
        })
      );
    } else {
      setFilteredFaqs(
        filteredFaqs.map((faq, i) => {
          if (i === idx) {
            faq.open = !faq.open;
          } else {
            faq.open = false;
          }
          return faq;
        })
      );
    }
  };
  

  // 검색
  const searchRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [isResult, setIsResult] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();    
    const searchValue = searchRef.current.value.replaceAll(' ','').toLowerCase();
    setIsResult(false);
    setSearchInput(searchValue);
    if(searchValue !== '') {
      const filteredData = faqs.filter(ele => {
        return ele.title.replaceAll(' ','').toLowerCase().includes(searchValue) || ele.msg.replaceAll(' ','').toLowerCase().includes(searchValue)
      });
      setFilteredFaqs(filteredData);
      if(filteredData.length <= 0) setIsResult(true);
    } else {
      setFilteredFaqs(faqs);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };
 
  return (
    <Layout>
      <PageWrapper>
      <FaqH2>FAQ 자주 묻는 질문</FaqH2>  
      <Message>
        아이프로모션 궁금증을 해결해보세요! 
      </Message>
      <FaqArticle>
        <WrapSearch>
          <SearchInput type="text" placeholder="검색어를 입력해주세요." ref={searchRef} onKeyDown={(e) => handleKeyDown(e)}/>
          <SearchBtn type="button" onClick={handleSearch}>
            <img src={searchIcon} alt="검색하기"/>
          </SearchBtn>
        </WrapSearch>
        <ul>
          {isResult && <NoResultP>🥲검색결과가 없습니다.</NoResultP>}
          {
            (filteredFaqs.length > 0 || searchInput != '') ?
            filteredFaqs.map(((ele, idx) => {
              return (
                <List key={idx}>
                <QuestionBox onClick={() => handleDrop(idx, filteredFaqs)}>
                  <p>{ele.title}</p>
                  {!ele.open && <img src={arrowIcon} alt=""/>}
                  {ele.open && <img src={arrowIcon} alt="" style={{rotate: '180deg'}}/>}
                </QuestionBox>
                {ele.open && <AnswerBox readOnly value={ele.msg} className=""></AnswerBox>}
              </List>
              )
            }))
           : 
            faqs.map(((ele, idx) => {
              return (
              <List key={idx}>
                <QuestionBox onClick={() => handleDrop(idx, faqs)}>
                  <p>{ele.title}</p>
                  {!ele.open && <img src={arrowIcon} alt=""/>}
                  {ele.open && <img src={arrowIcon} alt="" style={{rotate: '180deg'}}/>}
                </QuestionBox>
                {ele.open && <AnswerBox readOnly value={ele.msg} className=""></AnswerBox>}
              </List>
              )
            }))
          }
        </ul>
      </FaqArticle>
      </PageWrapper>

    </Layout>
  )
}
export default Faq;

const PageWrapper = styled.section`
  padding: 40px 40px 60px;
  gap: 40px;
`;
const WrapSearch = styled.div`
  position: relative;
  width: fit-content;
`
const SearchBtn = styled.button`
  display: block;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 14px;
  cursor: pointer;
`
const NoResultP = styled.p`
  text-align: center;
  margin-top: 80px;
`

const SearchInput = styled.input`
  width: 400px;
  padding: 16px;
  border: none;
  border-radius: 8px;
  outline: 1px solid #B8C6CC;
  margin-bottom: 24px;
`
const FaqH2 = styled.h2`
  color: ${primary5};
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 16px;
`

const Message = styled.p`
  color: ${grey7};
  font-size: 14px;
`;

const FaqArticle = styled.article`
  margin-top: 60px;
`
const List = styled.li`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
`
const QuestionBox = styled.div`
  width: 100%;
  font-size: 16px;
  color: ${grey7};
  font-weight: 600;
  line-height: 1;
  padding: 20px 32px;
  background: ${grey3};
  border-radius: 16px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

`
const AnswerBox = styled.textarea`
  font-size: 16px;
  color: ${grey6};
  font-weight: 400;
  line-height: 1.3;

  width: 100%;
  margin: 20px 0 20px;
  padding: 32px;
  border: 1px solid ${grey4};
  border-radius: 16px;
  box-sizing: border-box;
  resize: none;
  outline: none;
  white-space: pre-wrap;
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    height: 10%;
    background-color: ${primary1};
    border-radius: 32px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: none;
    border-radius: 32px;
  }
`