import styled from "styled-components";
import { PasswordBox } from "../../components/containers/profile/ProfileBox";
import { error3 } from "../../constants/color";
import Layout from "../../templates/Layout";
import {
  InputGroup,
  InputValidateGroup,
} from "../../components/inputs/InputGroups";
import { UpdatePasswordBtn } from "../../components/buttons/ProfileButtons";
import { instanceAxios } from "../../api/axios";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { AlertCode, AlertMessage, IsAlertOpen, IsLogoutOpen, MyProfile } from "../../atom/Atom";
import Cookies from "universal-cookie";
import { logout } from "../../api/member/logout";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import AlertModal from "../../components/modals/AlertModal";
import ErrorAlert from "../../error/ErrorAlert";
import ErrorLabel from "../../error/ErrorLabel";
const WrapInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* gap: 180px; */
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
`;

const LabelStyle = styled.label`
  display: flex;
  width: 180px;
`;
const WrapButton = styled.div`
  width: 180px;
  margin: 80px auto 20px;
`;
const LabelWarning = styled.span`
  display: block;
  color: ${error3};
  font-size: 14px;
  margin: 8px 0 0;
`;

export default function NewPassword() {
  const navigate = useNavigate();
  const [myProfile, setMyProfile] = useRecoilState(MyProfile);
  const [email, setEmail] = useState(myProfile.email);
  const [confimPassword, setConfimPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);
  const [alertCode, setAlertCode] = useRecoilState(AlertCode);
  // error Type
  // error Type
  const [isErrorType, setIsErrorType] = useState(null);
  const commonErrorPopup = (msg, type) => {
    setIsAlertOpen(true);
    setAlertMessage(msg);
    // 함수는 () => 함수이름
    // 바로 작동하면 안됨
    setIsErrorType(type);
  };
  const handleValidPassword = (e) => {
    console.log(e.target.value)
    if (e.target.name === "newPassword") {
      const regex = /(?=.*[a-zA-Z])(?=.*[~!@#$%^&*()+|=-])(?=.*[0-9]).{8,25}/;
      if (regex.test(e.target.value)) {
        setNewPassword(e.target.value);
        setIsValidPassword(true);
      } else {
        setNewPassword(e.target.value);
        setIsValidPassword(false);
      }
    }
  };

  const updateData = {
    currentPassword: currentPassword,
    newPassword: newPassword,
    confimPassword: confimPassword,
  };
  
  const logoutToRelogin = () => {
    setAlertCode(-1);
    commonErrorPopup("비밀번호가 변경되었습니다. 다시 로그인해주세요.",  '/');
    window.localStorage.removeItem("recoil-persist");
    logout(); 
  }

  const [pwdErr, setPwdErr] = useState([]);
  const [newPwdErr, setNewPwdErr] = useState([]);
  const [checkPwdErr, setCheckPwdErr] = useState([]);
  const [resultErr, setResultErr] = useState([]);

  const resetErr = () => {
    setPwdErr([]);
    setNewPwdErr([]);
    setCheckPwdErr([]);
    setResultErr([]);
  }

  const updatePassword = async (e) => {
    e.preventDefault();
    resetErr();
    if(currentPassword.length <= 0) {
      commonErrorPopup("기존 비밀번호를 입력하세요.", null);
      return;
    } else if(newPassword.length <= 0) {
      commonErrorPopup("새 비밀번호를 입력하세요.", null);
      return;
    } else if(confimPassword.length <= 0) {
      commonErrorPopup("새 비밀번호 확인을 입력하세요.", null);
      return;
    } else if(confimPassword !== newPassword) {
      commonErrorPopup("새 비밀번호 확인이 필요합니다. ", null);
      return;
    } else {
      if (window.confirm("비밀번호를 수정하시겠습니까?")) {
        try {
          const response = await instanceAxios.put(
            "/member/password/update",
            updateData
          );
          if (response.status === 200) {
            logoutToRelogin();
          } 
        } catch (err) {
          const errData = err.response.data.data;
          if(errData.result) setResultErr(errData.result);
          if(errData.currentPassword) setPwdErr(errData.currentPassword);
          if(errData.newPassword) setNewPwdErr(errData.newPassword);
          if(errData.confimPassword) setCheckPwdErr(errData.confimPassword);
          if(errData.item) setCheckPwdErr(errData.item);
        }
      }
    }

  };
  return (
    <Layout>
      <PasswordBox>
        {resultErr.length > 0 && <ErrorAlert data={resultErr} open={true}/>}
        <form action="post">
          <WrapInputs>
            <LabelStyle htmlFor="currentPassword">기존 비밀번호</LabelStyle>
            <div>
              <InputGroup
                type="password"
                id="currentPassword"
                value={currentPassword === undefined ? "" : currentPassword}
                setValue={setCurrentPassword}
                autoComplete={"off"}
              />
              {pwdErr.length > 0 && <ErrorLabel data={pwdErr}/>}
            </div>
          </WrapInputs>
          <WrapInputs>
            <LabelStyle htmlFor="newPassword">새 비밀번호</LabelStyle>
            <div>
              <InputValidateGroup
                type="password"
                id="newPassword"
                name="newPassword"
                value={newPassword === undefined ? "" : newPassword}
                placeholder="한글, 영문, 특수문자 포함 8자 이상"
                setValue={handleValidPassword}
                autoComplete={"off"}
              />
              {newPwdErr.length > 0 && <ErrorLabel data={newPwdErr}/>}
              {!isValidPassword && newPassword && newPwdErr.length === 0 && (
                <LabelWarning>
                  한글, 영문, 특수문자 포함한 8자~25자입니다.
                </LabelWarning>
              )}
            </div>
          </WrapInputs>
          <WrapInputs>
            <LabelStyle htmlFor="confimPassword">새 비밀번호 확인</LabelStyle>
            <div>
              <InputGroup
                type="password"
                id="confimPassword"
                value={confimPassword === undefined ? "" : confimPassword}
                setValue={setConfimPassword}
                autoComplete={"off"}
              />
              {checkPwdErr.length > 0 && <ErrorLabel data={checkPwdErr}/>}
              {confimPassword !== newPassword && confimPassword && checkPwdErr.length === 0 && (
                <LabelWarning>비밀번호가 일치하지 않습니다.</LabelWarning>
              )}
            </div>
          </WrapInputs>
          <WrapButton>
            <UpdatePasswordBtn updatePassword={updatePassword}>
              수정
            </UpdatePasswordBtn>
          </WrapButton>
        </form>
      </PasswordBox>
      {isAlertOpen && <AlertModal isErrorType={isErrorType}></AlertModal>}
    </Layout>
  );
}
