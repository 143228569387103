 import { instanceAxios } from "./axios";

// 1. 로고 등록하기
export const requestAddIcons = async (formData,setIconImg, iconImg, requestIconAll, myPushProject,setIconArr, commonErrorPopup, setResultError,iconUrl, setIsLoading) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  }
  try {
    formData.append("icon", iconImg);
    const response = await instanceAxios.post(
      `/${myPushProject.pid}/icon/upload`,
      formData, {headers}
      );
      if (response.status === 200) {
      setIsLoading(false);
      setIconImg(null);
      requestIconAll(myPushProject,setIconArr);
    }
  } catch (err) {
    setIsLoading(false);
    console.error(err);
  }
};


// 2. 로고 삭제하기
export const deleteIcon = async (iid, myPushProject, setIsAlertOpen, setAlertMessage, setIconUrl,setIconArr,setIid) => {
  if (iid === null) {
    alert("삭제할 로고를 선택해주세요.");
  } else {
    if (window.confirm("로고를 삭제하시겠습니까?")) {
      try {
        const response = await instanceAxios.delete(
          `${myPushProject.pid}/icon/${iid}`
        );
        if (response.status === 200) {
          setIconUrl("");
          setIid("");
          setIsAlertOpen(true);
          setAlertMessage("로고를 삭제되었습니다.");
          if(iid.length > 0) {
            requestIconAll(myPushProject,setIconArr);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
};


// 3. 홈페이지 로고 리스트 호출 
export const requestIconAll = async (myPushProject,setIconArr) => {
  if(!myPushProject.pid) return;
  try {
    const response = await instanceAxios.get(
      `/${myPushProject.pid}/icon/all`
    );
    if (response.status === 200) {
      setIconArr(response.data);
    }
  } catch (err) {
    console.log(err)
  }
};