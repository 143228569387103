// 새로운 팔레트

import { createTheme } from "@mui/material";

// Primary
export const primary1 = "#c4dcfa"; 
export const primary2 = "#9bbff0"; 
export const primary3 = "rgb(76, 125, 194)"; 
export const primary4 = "#134a97"; 
export const primary5 = "#033781"; 
export const primary6 = "#002355"; 
// sub 
export const subC = "#40bdae"; 

// Grey
export const grey1 = "#FFFFFF";
export const grey2 = "#FAFAFA";
export const grey3 = "#F0F0F0";
export const grey4 = "#D9D9D9";
export const grey5 = "#BFBFBF";
export const grey6 = "#8C8C8C";
export const grey7 = "#595959";
export const grey8 = "#434343";
export const grey9 = "#262626";
export const grey10 ="#141414";
export const grey11= "#000000";

// Error 
export const error1 = "#F99191";
export const error2 = "#f78787";
export const error3 = "#E82A2A";
export const error4 = "#C81717";
export const error5 = "#9B0808";

// background
// export const MAIN_BACKGROUND_COLOR = "linear-gradient(133.78deg, #88EAFF 8.16%, #98A2FF 74.57%)";
export const MAIN_BACKGROUND_COLOR = "rgb(243, 247, 255)";


// mui 라이브러리 컬러 세팅
export const muiColorTheme = createTheme({
  palette: {
    primary: {
      main: primary2,
    },
  },
});