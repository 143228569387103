import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components"
import { MAIN_BACKGROUND_COLOR, error3, grey1, grey3} from "../../../constants/color";
import { useEffect } from "react";

const Section = styled.section`
  background: ${MAIN_BACKGROUND_COLOR};
  display: flex;
  justify-content: center;
  padding: 80px 0;
  font-family: "Pretendard";
`;

const WrapBox = styled.div`
  width: 90%;
`

const Box = styled.div`
  padding: 32px;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.06);
  background-color: ${grey1};
  `;

const AlignTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 60px;
`

const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
`

const Withdrawal = styled.button`
  display: block;
  font-weight: 600;
  color : ${error3}
`

export function ProfileBox({children, name}) {
  const navigate = useNavigate();
  const goSuccession = () => {
    navigate("/secession");
  }
  return (
    <Section>
        <h1 className='ir'> 정보 페이지</h1>
        <WrapBox>
          <Box>
            <AlignTop style={{display:"flex"}}>
              <Title>{name}님의 정보</Title>
              {/* <Withdrawal onClick={goSuccession}>탈퇴하기</Withdrawal> */} 
            </AlignTop>
              {children}
          </Box>
        </WrapBox>
    </Section>
  )
}

export function PasswordBox({children}) {
  return (
    <Section style={{height: '100vh'}}>
        <h1 className='ir'>비밀번호 변경 페이지</h1>
        <WrapBox>
          <Box>
          <Title>새 비밀번호 설정</Title>
          <AlignTop></AlignTop>
            {children}
          </Box>
        </WrapBox>
    </Section>
  )
}