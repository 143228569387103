import styled from "styled-components";
import { MAIN_BACKGROUND_COLOR, grey1,grey6, grey7, grey9, primary2, primary3, primary4} from "../../../constants/color";
import iconBell from "../../../assets/images/ico-bell.svg"
import iconYellowRound from "../../../assets/images/ico-round-check.svg";
import iconFlying from "../../../assets/images/ico-flying.svg";
import exclamation from "../../../assets/images/ico-exclamation.svg"
import clicker from "../../../assets/images/ico-clicker.svg"
import { useEffect, useState } from "react";
import { MyPushProject } from "../../../atom/Atom";
import { instanceAxios } from "../../../api/axios";
import { useRecoilState } from "recoil";

export default function SummaryBox({firstStart}) {
  // 설명 박스 보이기
  const [isHover1, setIsHover1] = useState(false);
  const [isHover2, setIsHover2] = useState(false);
  const [isHover3, setIsHover3] = useState(false);
  const [isHover4, setIsHover4] = useState(false);

  const [myPushProject, setMyPushProject] = useRecoilState(MyPushProject);
  const [startErr, setStartErr] = useState("");
  const [endErr, setEndErr] = useState("");

  // 구독
  const [todaySubscribe, setTodaySubscribe] = useState(0);
  const [totalSubscribe, setTotalSubscribe] = useState(0);
  const [weekSubscribe, setWeekSubscribe] = useState(0);

  // 발송
  const [totalSend, setTotalSend] = useState(0);
  const [weekSend, setWeekSend] = useState(0);
  const [todaySend, setTodaySend] = useState(0);

  // 성공 수
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [weekSuccess, setWeekSuccess] = useState(0);
  const [todaySuccess, setTodaySuccess] = useState(0);
  const requestTotalCount = async () => {
    try {
      const response = await instanceAxios.get(`/report/${myPushProject.pid}/total`);
      if (response.status === 200) {
        const data = response.data.data;
        // 구독자
        setTotalSubscribe(data.totalSubscribe.toLocaleString());
        setWeekSubscribe(data.weekSubscribe.toLocaleString());
        setTodaySubscribe(data.todaySubscribe.toLocaleString());
        // 발송량
        setTotalSend(data.totalSend.toLocaleString());
        setWeekSend(data.weekSend.toLocaleString());
        setTodaySend(data.todaySend.toLocaleString());

        // 성공수
        setTotalSuccess((data.totalSend - data.totalFailed).toLocaleString());
        setWeekSuccess((data.weekSend - data.weekFailed).toLocaleString());
        setTodaySuccess((data.todaySend - data.todayFailed).toLocaleString());
      }
    } catch (err) {
      const errResponse = err.response.data.data;
      
      console.error(errResponse);
     
    }
  }

  useEffect(() => {
    if(!firstStart) return;
    if(myPushProject.categoryCode) {
    requestTotalCount();
  }
  }, [myPushProject])

  return (
    <WrapSummaryBox>
      <Box>
          <Icon src={iconBell} alt="" />
          <div>
            <SummaryTitle>
              <IconExclamation src={exclamation} alt=""  onMouseEnter={()=>{setIsHover1(true)}} onMouseOut={()=>setIsHover1(false)}/>
              <span>PUSH 구독자 수</span>
              {
                isHover1 &&
                <HoverBox>
                  홈페이지 푸시 알림을 구독한 회원 수
                </HoverBox>
              }
            </SummaryTitle>
            <SummarySpan>총 <SummaryCount>{totalSubscribe}</SummaryCount> 건</SummarySpan>
            <SummaryTodaySpan>주간 <SummaryTodayCount>{weekSubscribe}</SummaryTodayCount> 건</SummaryTodaySpan>
            <SummaryTodaySpan>오늘 <SummaryTodayCount>{todaySubscribe}</SummaryTodayCount> 건</SummaryTodaySpan>
          </div>
      </Box>
      <Box>
          <Icon src={iconFlying} alt="" />
          <div>
            <SummaryTitle >
              <IconExclamation src={exclamation} alt="" onMouseEnter={()=>{setIsHover2(true)}} onMouseOut={()=>setIsHover2(false)}/>
              <span>PUSH 발송량</span>
              {
                isHover2 &&
                <HoverBox>
                  발송 중인 전체 메시지 건수
                </HoverBox>
              }
            </SummaryTitle>
            <SummarySpan>총 <SummaryCount>{totalSend}</SummaryCount> 건</SummarySpan>
            <SummaryTodaySpan>주간 <SummaryTodayCount>{weekSend}</SummaryTodayCount> 건</SummaryTodaySpan>
            <SummaryTodaySpan>오늘 <SummaryTodayCount>{todaySend}</SummaryTodayCount> 건</SummaryTodaySpan>
          </div>
      </Box>
      <Box>
          <Icon src={iconYellowRound} alt="" />
          <div>
            <SummaryTitle>
              <IconExclamation src={exclamation} alt=""  onMouseEnter={()=>{setIsHover3(true)}} onMouseOut={()=>setIsHover3(false)}/>
              <span>PUSH 성공 수</span>
              {
                isHover3 &&
                <HoverBox>
                  전송이 100% 완료된 푸시의 건수
                </HoverBox>
              }
            </SummaryTitle>
            <SummarySpan>총 <SummaryCount>{totalSuccess}</SummaryCount> 건</SummarySpan>
            <SummaryTodaySpan>주간 <SummaryTodayCount>{weekSuccess}</SummaryTodayCount> 건</SummaryTodaySpan>
            <SummaryTodaySpan>오늘 <SummaryTodayCount>{todaySuccess}</SummaryTodayCount> 건</SummaryTodaySpan>
          </div>
      </Box>
      {/* <Box>
          <Icon src={clicker} alt="" />
          <div>
            <SummaryTitle>
              <IconExclamation src={exclamation} alt=""  onMouseEnter={()=>{setIsHover4(true)}} onMouseOut={()=>setIsHover4(false)}/>
              <span>PUSH 클릭 수</span>
              {
                isHover4 &&
                <HoverBox>
                  푸시를 클릭한 건수
                </HoverBox>
              }
            </SummaryTitle>
            <SummarySpan>총 <SummaryCount>50,000</SummaryCount> 건</SummarySpan>
            <SummaryTodaySpan>주간 <SummaryTodayCount>50,000</SummaryTodayCount> 건</SummaryTodaySpan>
            <SummaryTodaySpan>오늘 <SummaryTodayCount>50,000</SummaryTodayCount> 건</SummaryTodaySpan>
          </div>
      </Box> */}
    </WrapSummaryBox>
  )
}

const WrapSummaryBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  `
  const Box = styled.div`
    padding: 32px;
    width: calc(100% / 3);
    /* height: 110px; */
    border-radius: 16px;
    background-color: ${grey1};
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    position: relative;
  `;
  const Icon = styled.img`
    width: 42px;
    height: 42px;
  `
const SummaryTitle = styled.div`
  font-size: 14px;
  color: ${grey6};
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: flex-end;
`
const IconExclamation = styled.img`
  width: 15px;
  height: 15px;
`
const SummarySpan = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${grey9};
  padding-top: 8px;
`
const SummaryTodaySpan = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${primary2};
  padding-top: 12px;
`
const SummaryCount = styled.strong`
  font-size: 22px;
  font-weight: 700;
  color: ${grey9};
`
const SummaryTodayCount = styled.strong`
  font-size: 16px;
  font-weight: 600;
  color: ${primary3};
`

const HoverBox = styled.span`
  /* max-width: 180px; */
  background: ${MAIN_BACKGROUND_COLOR};
  color: ${primary4};
  border-radius: 4px;
  box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  padding: 10px;
  text-align: left;
  font-size: 12px;
  /* 위치 */
  position: absolute;
  right: 120px;
  top : -10px;
  line-height: 1.3;
`
