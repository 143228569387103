import { useState } from "react";
import errCode from "./errorCode";
import styled from "styled-components";
import { error3, grey11 } from "../constants/color";

const ErrorLabelStyle = styled.label`
  display: block;
  color: ${error3};
  font-size: 14px;
  padding-top: 6px;
`;

function ErrorLabel({data}) {
    let ErrMsgsArr = [];
    data.forEach((ele) => {
      if(ele.param) {
        let errmsg = errCode[ele.msg];
        let param = {};
        let paramKey = [];
        let paramValue = ele.param;
        
        function replaceKeywords () {
        return errmsg.replaceAll(/[${][^}]*}/gm, match => {
          console.log(match)
          paramKey.push(match.slice(2,-1));
          paramKey.forEach((item, idx) => {
            param[item] = paramValue[idx];
          });
          return param[match.slice(2,-1)]
          })
        }
                
        ErrMsgsArr.push(replaceKeywords());
      } else if(errCode[ele.msg] === undefined) {
        ErrMsgsArr.push(ele.msg)
      } else {
        ErrMsgsArr.push(errCode[ele.msg])
      }
    })

    return (
      <>
        {ErrMsgsArr.length > 0 && ErrMsgsArr.map((ele,idx) => (
          <ErrorLabelStyle key={idx}>* {ele}<br/></ErrorLabelStyle>
        ))}
      </>
    )
  }

  
  
  export default ErrorLabel;
  
   