import React, { useEffect, useState } from "react";
import iconSearch from "../assets/images/ico-search.svg"
import Layout from "../templates/Layout";
import styled from "styled-components";
import {  grey1, grey5, grey9, primary3, primary5 } from "../constants/color";

import SummaryBox from "../components/containers/dashboard/SummaryBox";
import SubscribersBox from "../components/containers/dashboard/SubscribersBox";
import PushGraphBox from "../components/containers/dashboard/PushGraphBox";

// datepicker library
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "../styles/datepicker.css";
import { ko } from "date-fns/esm/locale";
import { instanceAxios } from "../api/axios";
import { MyPushProject } from "../atom/Atom";
import { useRecoilState } from "recoil";



const DashBoard = () => {
  const today = new Date();
  const [firstStart,  setFirstStart] = useState(false);
  // 시작 시간
  const [startDate, setstartDate] = useState(addDays(today, -7));
  const [fianlstartDate, setFinalstartDate] = useState(startDate);
  // 종료 시간
  const [endDate, setEndDate] = useState(today);
  const [fianlendDate, setFinalEndDate] = useState(endDate);

  // 일 빼기
  function addDays(date, days) {
    const clone = new Date(date);
    clone.setDate(date.getDate() + days)
    return clone;
  }
  // 월 빼기
  function setMonth(date, month) {
    const clone = new Date(date);
    clone.setMonth((date.getMonth()) + month)
    return clone;
  }
  // 날짜 차이
  const getDateDiff = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const diffDate  = date1.getTime() - date2.getTime(); // 172800000
    const elapsedDay = diffDate  /  (1000 * 60 * 60 * 24)// 2
    return  Math.ceil(elapsedDay)
  }

  const [isClickUnit, setIsClickUnit] = useState(false);
  const clickDateUnitBtns = (e) => {
    setEndDate(today);
    setFormatEndDate(today);
    setIsClickUnit(true);
    if(e.target.id === "1") {
      setstartDate(today);
      setFormatStartDate(today);
    } else if(e.target.id === "2") {
      setstartDate(addDays(today, -7));
      setFormatStartDate(addDays(today, -7));
    } else if(e.target.id === "3") {
      setstartDate(setMonth(today, -3));
      setFormatStartDate(setMonth(today, -3));
    } else if(e.target.id === "4") {
      setstartDate(setMonth(today, -6));
      setFormatStartDate(setMonth(today, -6));
    }
  }

  const onSelectStartDate = (date) => {
    setstartDate(date);
    setFormatStartDate(date);
  };

  const onSelectEndDate = (date) => {
    setEndDate(date);
    setFormatEndDate(date)
  };

  const setFormatStartDate = (date) => {
    // setStartFormatted(true);
    const formattedYear = date.getFullYear().toString();
    const formattedMonth = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString() ;
    const formattedDay = date.getDate().toString().length === 1 ?  "0" + (date.getDate()).toString() : (date.getDate()).toString();
    setFinalstartDate(formattedYear + formattedMonth + formattedDay);
  } 
  const setFormatEndDate = (date) => {
    // setendFormatted(true);
    const formattedYear = date.getFullYear().toString();
    const formattedMonth = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString() ;
    const formattedDay = date.getDate().toString().length === 1 ?  "0" + (date.getDate()).toString() : (date.getDate()).toString();
    return setFinalEndDate(formattedYear + formattedMonth + formattedDay);
  } 

  const [myPushProject, setMyPushProject] = useRecoilState(MyPushProject);
  
  // 1. 구독자수 그래프
  const [registerCount, setRegisterCount] = useState([]);
  const [startErr1, setStartErr1] = useState("");
  const [endErr1, setEndErr1] = useState("");
  const requestRegister = async () => {
    setStartErr1("");
    setEndErr1("");
    try {
      const response = await instanceAxios.get(`/report/${myPushProject.pid}/subscribe?start=${fianlstartDate}&end=${fianlendDate}`);
      if (response.status === 200) {
        setRegisterCount(response.data.data);
      }
    } catch (err) {
      console.error(err);
      const errResponse = err.response.data.data;
      if(errResponse.start) setStartErr1(errResponse.start);
      if(errResponse.end) setEndErr1(errResponse.end);
    }
  };

  // 2. 푸시 그래프
  const [pushCount, setPushCount] = useState([]);
  const [startErr2, setStartErr2] = useState("");
  const [endErr2, setEndErr2] = useState("");
  const requestPush = async () => {
    setStartErr2("");
    setEndErr2("");
    try {
      const response = await instanceAxios.get(`/report/${myPushProject.pid}/push?start=${fianlstartDate}&end=${fianlendDate}`);
      if (response.status === 200) {
        setPushCount(response.data.data);
      }
    } catch (err) {
      console.error(err);
      const errResponse = err.response.data.data;
      if(errResponse.start) setStartErr2(errResponse.start);
      if(errResponse.end) setEndErr2(errResponse.end);
    }
  };

  // ---- 첫 로딩 api 실행 ----
  useEffect(() => {
    if(!firstStart) return;
    if(myPushProject.categoryCode) {
      requestRegister();
      requestPush();
    }
  }, [myPushProject]);

  // --- 첫 로딩 날짜 설정 (default: 일주일) ----
  useEffect(() => {
    if(myPushProject) {
      setFirstStart(true);
      setFormatStartDate(startDate);
      setFormatEndDate(endDate);
    }
  }, [myPushProject]);
  
  const clickDateSearch = (e) => {
    // 구독자 수 api
    requestRegister();
    // 발송량 api
    requestPush()
    // 클릭수 api
  }
  useEffect(() => {
    if(!isClickUnit) return;
    if(fianlstartDate && isClickUnit) {
      clickDateSearch();
      setIsClickUnit(false);
    }
  },[fianlstartDate, isClickUnit])

  return (
    <Layout>
      <PageWrapper>
        <DashbaordH2>DASHBOARD</DashbaordH2>
        {/* ----- TOP SUMMARY ----- */}
        <SummaryBox firstStart={firstStart}/>
        {/* ----- 날짜 필터링 (PROP으로 내려줄 것) ----- */}
        <FilterSection>
          <DateList>
            <li>
              <FilterBtn id="1" onClick={clickDateUnitBtns}>오늘</FilterBtn>
            </li>
            <li>
              <FilterBtn id="2" onClick={clickDateUnitBtns}>최근 7일</FilterBtn>
            </li>
            <li>
              <FilterBtn id="3"  onClick={clickDateUnitBtns}>최근 3개월</FilterBtn>
            </li>
            <li>
              <FilterBtn id="4"  onClick={clickDateUnitBtns}>최근 6개월</FilterBtn>
            </li>
          </DateList>
          <DateInputsList>
            <li>
              {/* <FilterDateInput type="text" id="startDate"  onClick={clickDateInput}/> */}
              <DatePicker selected={startDate} onChange={onSelectStartDate} locale={ko}  dateFormat="yyyy-MM-dd" maxDate={new Date()}/>
            </li>
            <li>~</li>
            <li>
              {/* <FilterDateInput type="text"  ref={$endDate} /> */}
              <DatePicker value={endDate} selected={endDate} onChange={onSelectEndDate}  locale={ko}  dateFormat="yyyy-MM-dd" maxDate={new Date()}/>
            </li>
            <li>
              <DateFilterBtn onClick={clickDateSearch}>
                <img src={iconSearch} alt="검색"/>
                검색하기
              </DateFilterBtn>
            </li>
          </DateInputsList>
        </FilterSection>

        {/* ----- 그래프 ----- */}
        {/* 1. 구독자수 */}
        <BoxFlex>
          <SubscribersBox response={registerCount} startErr={startErr1} endErr={endErr1}/>
        </BoxFlex>
        {/* 2. PUSH 발송량 */}
        <BoxFlex>
          <PushGraphBox response={pushCount} startErr={startErr2} endErr={endErr2}/>
        </BoxFlex>
      </PageWrapper>
    </Layout>
  );
};

const PageWrapper = styled.section`
  padding: 40px 40px 60px;
  gap: 40px;
`;
const DashbaordH2 = styled.h2`
  color: ${primary5};
  font-size: 32px;
  font-weight: 700;
`
const FilterSection = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 40px 0 20px;
`
// 기간 선택 버튼
const DateList = styled.ul`
  display: flex;
  gap: 8px;
  align-items: center;
`
const FilterBtn = styled.button`
  width: 90px;
  padding: 10px;
  border-radius: 4px;
  background: ${grey1};
  border: 1px solid ${grey5};
  color: ${grey9};
  font-weight: 500;
  box-sizing: border-box;
`

// calendar inputs
const DateInputsList = styled.ul`
  display: flex;
  gap: 8px;
  align-items: center;
  `
const FilterDateInput = styled.input`
  width: 120px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  background: ${grey1};
  border: 1px solid ${grey5};
  color: ${grey9};
  font-weight: 500;
`
const DateFilterBtn = styled.button`
  font-weight: 500;
  color: ${grey1};
  font-size: 14px;
  background: ${primary3};
  padding: 10px;
  width: 96px;
  box-sizing: border-box;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`

const BoxFlex = styled.div`
  display: flex;
  gap: 32px;
  align-items: flex-start;
  justify-content: center;
  margin: 40px 0;
  border-radius: 8px;
`

export default DashBoard;
 