import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { PushBox } from "../../components/containers/push/PushBox";
import Layout from "../../templates/Layout";
import {
  grey5,
  grey10,
  grey2,
  grey4,
  grey7,
  primary5,
  primary6,
  grey8,
  primary3,
  grey3,
  grey6,
  primary2,
  error3,
  MAIN_BACKGROUND_COLOR,
  subC,
} from "../../constants/color";
import settingIcon from "../../assets/images/homepageSetting.png";
import wifi from "../../assets/images/ico-wifi.svg";
import speaker from "../../assets/images/ico-speaker.svg";
import bluetooth from "../../assets/images/ico-bluetooth.svg";
import lock from "../../assets/images/ico-lock.svg";
import flash from "../../assets/images/ico-flash.svg";
import flight from "../../assets/images/ico-flight.svg";
import arrow from "../../assets/images/ico-arrow-small.png";
import iconRocket from "../../assets/images/ico-rocket.svg";
import chrome from "../../assets/images/chrome_logo.png";
import activeCheck from "../../assets/images/active-check.png";
import Rectangle from "../../assets/images/demoBox.png";
import inActiveCheck from "../../assets/images/inactive-check.png";
import {  DemoBoxApp, DemoBoxWeb, DemoWrapBox } from "../../components/containers/push/DemoBox";
import {
  ActivePushButton,
  InactivePushButton,
  RegisterImageButton,
  RegisterIconButton,
  DeleteIconButton,
  GoPushListButton,
} from "../../components/buttons/PushButtons";
import {
  AlertMessage,
  IsAlertOpen,
  MyProject,
  MyPushProject,
} from "../../atom/Atom";
import { useRecoilState } from "recoil";
import Loading from "../../components/loading/Loading";
import { useNavigate, useParams} from "react-router-dom";
import { submitMakePush } from "../../api/message";
import {requestAddIcons, deleteIcon, requestIconAll} from '../../api/image';
import {getDetailMsg, editPush} from '../../api/message';
import ErrorAlert from "../../error/ErrorAlert";

// datepicker library
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "../../styles/datepicker.css";
import { ko } from "date-fns/esm/locale";
import { set } from "date-fns";


export default function MakePush() {
  const [isCompletePush, setIsCompletePush] = useState(false);
  const [thisClock, setThisClock] = useState("");
  const [thisDate, setThisDate] = useState("");
  const [thisMonth, setThisMonth] = useState("");
  const [thisYear, setThisYear] = useState("");
  const [reserveDate, setReserveDate] = useState("");
  const [finalReserveDate, setFinalReserveDate] = useState("");
  const [reserveTime, setReserveTime] = useState("");
  const [finalReserveTime, setFinalReserveTime] = useState("");
  //예약 시작 시간을 선택했는지
  const [isSelected, setIsSelected] = useState(false);
  const [directDate, setDirectDate] = useState("");
  const [pushType, setPushType] = useState("");
  const [pushTypeDemo, setPushTypeDemo] = useState("advertising");
  const [myPushProject, setMyPushProject] = useRecoilState(MyPushProject);
  const [iconUrl, setIconUrl] = useState("");
  const [iid, setIid] = useState("");
  // error Type
  const [isErrorType, setIsErrorType] = useState(null);
  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);

  const commonErrorPopup = (msg, type) => {
    setIsAlertOpen(true);
    setAlertMessage(msg);
    // 함수는 () => 함수이름
    // 바로 작동하면 안됨
    setIsErrorType(type);
  };

  const [resultError, setResultError] = useState([]);


  const [isWebCheck, setisWebCheck] = useState(true);
  const [isMobileCheck, setisMobileCheck] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isAdsCheck, setIsAdsCheck] = useState(false);
  const [isInfoCheck, setisInfoCheck] = useState(false);
  const [isEtcCheck, setisEtcCheck] = useState(false);
  const [isDirectCheck, setIsDirectCheck] = useState(false);
  const [isReserveCheck, setIsReserveCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isWebCheck && isMobileCheck) {
      setPushType("web_push");
      setPushTypeDemo("other");
    } else if (isMobileCheck) {
      setPushType("mobile_app_push");
    } else if (isWebCheck) {
      setPushType("web_push");
    }
  }, [isWebCheck, isMobileCheck]);
  // 라디오 체크
  const handleWebCheckRadio = () => {
    isWebCheck ? setisWebCheck(false) : setisWebCheck(true);
  };
  const handleMobileCheckRadio = () => {
    isMobileCheck ? setisMobileCheck(false) : setisMobileCheck(true);
  };
  const handleAdsCheckRadio = () => {
    isAdsCheck ? setIsAdsCheck(false) : setIsAdsCheck(true);
  };
  const handleInfoCheckRadio = () => {
    isInfoCheck ? setisInfoCheck(false) : setisInfoCheck(true);
  };
  const handleEtcCheckRadio = () => {
    isEtcCheck ? setisEtcCheck(false) : setisEtcCheck(true);
  };
  const handleDirectCheckRadio = () => {
    isDirectCheck ? setIsDirectCheck(false) : setIsDirectCheck(true);
    setIsReserveCheck(false);

    getDirectDate();
    // console.log(thisClock.slice(0, 2) - 9 + thisClock.slice(2), "시간");
  };
  const handleReserveCheckRadio = () => {
    isReserveCheck ? setIsReserveCheck(false) : setIsReserveCheck(true);
    setIsDirectCheck(false);
    getDirectDate();
  };

  const navigate = useNavigate();
  const { mid } = useParams(); 
  const [msgDetail, setMsgDetail] = useState([]);
  // 1. 정보 불러오기
  useEffect(()=> {
    if(mid === undefined) return;
    getDetailMsg(myPushProject, mid, setIsLoading, setMsgDetail,setInputs);
  },[myPushProject])
  
  // inputs 초기값
  const [inputs, setInputs] = useState({
    title: msgDetail.title,
    content: msgDetail.content,
    link: msgDetail.link,
    date: msgDetail.date,
    image: msgDetail.image,
    icon: msgDetail.icon,
    pid: myPushProject.pid,
  },[]);

  // 2. input 값 업데이트
  useEffect(() => {
    setInputs({
      title: msgDetail.title,
      content: msgDetail.content,
      link: msgDetail.link,
      image: msgDetail.image,
      icon: msgDetail.icon,
      date: msgDetail.date,
      pid: msgDetail.pid
    });
  },[msgDetail])
  
  // 메세지 입력
  const { web, mobile, ads, info, etc, title, content, link, icon, image, date } =
    inputs;
   
  const handleInputValues = (e) => {
    if (isMobileCheck || isWebCheck) {
      e.preventDefault();
      const { name, value } = e.target;
      setInputs({
        ...inputs,
        [name]: value,
        web: isWebCheck,
        mobile: isMobileCheck,
        ads: isAdsCheck,
        info: isInfoCheck,
        etc: isEtcCheck,
      });
    } else {
      alert("Please select Push Type");
    }
    limitTitle();

  };
  const limitTitle = () => {
    if(title.length > 21) {
      setInputs({...inputs, title: title.substring(0,21)})
    }
    if(content.length > 225) {
      setInputs({...inputs, content: content.substring(0,225)})
    }
  }


  // 이미지 파일 업로드
  const [iconArr, setIconArr] = useState([]);
  const imageInputRef = useRef(null);
  const iconInputRef = useRef(null);
  const [demoImg, setDomoImg] = useState("");
  const [iconImg, setIconImg] = useState(null);
  // const [selected, setSelectedIcon] = useState("");
  const formData = new FormData();

  const [previewImg, setPreviewImg] = useState(null);
  const handleUploadImage = (e) => {
    const fileList = e.target.files;
    setPreviewImg(fileList[0]);
    // formData.append('file', previewImg);
    // for(const keyValues of formData) console.log("for 문: ", keyValues);
    const imageUrl = URL.createObjectURL(fileList[0]);
    setDomoImg(imageUrl);
   
  };


  // 데모 이미지 클릭
  const onImgInputBtnClick = (e) => {
    e.preventDefault();
    imageInputRef.current.click();
  };

  // 1. 아이콘 클릭
  const onIconInputBtnClick = (e) => {
    e.preventDefault();
    if (iconArr.length > 2) {
      setIsAlertOpen(true);
      setAlertMessage("아이콘은 3개까지 등록이 가능합니다.");
    } else {
      iconInputRef.current.click();
    }
  };
  // 2. 아이콘 파일 선택
  const handleUploadIcon = (e) => {
    const fileList = e.target.files;
    setIconImg(fileList[0]);
  };

  // 3. 아이콘 추가 api 호출
  useEffect(() => {
    const isIconSizeOk = limitImgSize(iconImg);
    if(!isIconSizeOk) return;
    if(iconImg) {
      requestAddIcons(formData, setIconImg,iconImg, requestIconAll,myPushProject,setIconArr,commonErrorPopup, setResultError, iconUrl);
    }
  }, [iconImg]);

  // 아이콘 최대 용량 설정
  function limitImgSize (iconImg) {
    if (iconImg) {
      let maxSize = 10 * 1024 * 1024;
      let fileSize = iconImg.size;
  
      if(fileSize > maxSize){
        setIsAlertOpen(true);
        setAlertMessage("파일 용량은 10MB 이하입니다.");
        setIconImg("");
        return false;
      } else {
        return true;
      }
    }
  }

  const handleIconSelect = (e) => {
    console.log(e.target.src);
    console.log(inputs.icon, "🥕")
    const imageSrc = e.target.src;
    if (imageSrc === iconUrl) {
      setIconUrl("");
      setIid("");
    } else {
      if(e.target.src !== undefined) {
        setIconUrl(e.target.src);
        setIid(imageSrc.split("/").at(-1));
        // inputs.icon = iconUrl;
      }
    }
  };

  // 메세제 이미지 수정
  useEffect(() => {
    setInputs({...inputs,icon: iconUrl})
  }, [iconUrl])
  useEffect(() => {
    setInputs({...inputs, image: demoImg})
  }, [demoImg]);

  useEffect(() => {
    if(myPushProject) {
      requestIconAll(myPushProject, setIconArr);
      if (myPushProject.expiryDate) {
        setIsAlertOpen(true);
        setAlertMessage("삭제예정 홈페이지입니다");
        navigate("/dashboard");
      }
    }
  }, [myPushProject]);

  // 발송 시간 설정 처리
  const getDirectDate = () => {
    const offset = 1000 * 60 * 60 * 9;
    // const koreaNow = new Date(new Date().getTime() + offset);
    const koreaNow = new Date();
    const getYear = koreaNow.getFullYear().toString();
    const getMonth = (koreaNow.getMonth() + 1).toString();
    const getDate = koreaNow.getDate().toString();
    // 현재 년
    setThisYear(getYear);    
    // 현재 월
    setThisMonth(getMonth.length > 1 ? getMonth :  "0" + getMonth);    
    // 현재 일
    setThisDate(getDate.length > 1 ? getDate  :  "0" + getDate);    
    // 예약 시간
    // setReserveMin(koreaNow.toISOString().slice(0, 16).replace("T", " "));
    // 지금 시간
    setThisClock(koreaNow.toString().split(" ")[4].slice(0,5));
    setDirectDate(thisYear + "-" + thisMonth + "-" + thisDate + " " + thisClock)
  };

  useEffect(() => {
    getDirectDate();
  }, [thisYear, thisMonth, thisDate, thisClock]);


  const onSelectDate = (date) => {
    setReserveDate(date);
    setReserveTime("");
    setFormatReserveDate(date)
  };

  const setFormatReserveDate = (date) => {
    const formattedYear = date.getFullYear().toString();
    const formattedMonth = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString() ;
    const formattedDay = date.getDate().toString().length === 1 ?  "0" + (date.getDate()).toString() : (date.getDate()).toString();
    setFinalReserveDate(formattedYear + "-"+ formattedMonth+ "-"+formattedDay);
  } 
  

  const onSelectTime = (time) => {
    if(!time) return;
    setReserveTime(time);
    setIsSelected(true);
    handleReserveTime(time)
  };

  const handleReserveTime = function (time) {
    const selectHour = time.getHours().toString().length === 1 ? "0" + time.getHours() : time.getHours();
    const selectMinute = time.getMinutes().toString().length === 1 ? "0" + time.getMinutes() : time.getMinutes();
    const thisHour = thisClock.split(":")[0];
    const thisMinute = thisClock.split(":")[1];
    setFinalReserveTime(selectHour + ":"+selectMinute);
   
    if (isDirectCheck) return;
    if(!reserveDate) {
      setIsCompletePush(false);
      setReserveTime("");
      commonErrorPopup("날짜를 선택해주세요.", null);
      return;
    } else if(directDate.split(' ')[0] === finalReserveDate) {
      if(selectHour < thisHour || (selectHour === thisHour && selectMinute < thisMinute)) {
        setIsCompletePush(false);
        setReserveTime("")
        commonErrorPopup("현재보다 이른 시간은 선택할 수 없습니다.", null);
        return;
      } 
    } else {
      setIsCompletePush(true); 
    }
  } 

  // 예외 처리 
  const checkConditons = async () => {
    if (!link.includes("https://")) {
        setIsCompletePush(false);
        setIsAlertOpen(true);
        setAlertMessage('링크는 "https://"가 필요합니다.');
      } else if (!isMobileCheck && !isWebCheck) {
        setIsCompletePush(false);
        return alert("PUSH 유형을 선택해주세요.");
      } else if (!title || !content || !link) {
        setIsCompletePush(false);
        return alert("메세지 제목, 내용, 링크는 필수 사항입니다.");
      } else  if (!isDirectCheck && !isReserveCheck) {
        setIsCompletePush(false);
        return alert("발송 유형을 선택해주세요.");
        
      } else {
        setIsCompletePush(true);
      }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      if (link.includes("https://")) {
        return;
      } else {
        setIsAlertOpen(true);
        setAlertMessage('링크는 "https://"가 필요합니다.');
      }
    }
  };

  const onClickSubmit =  async (e) => {
    e.preventDefault();
    checkConditons();
    handleReserveTime();
    
    if (isReserveCheck) {
      inputs.date = reserveDate + " " + reserveTime;
    } else if(isDirectCheck) {
      inputs.date = directDate;
    }
    inputs.image = previewImg;

    let data = {
      pushType: pushType,
      messageType: pushTypeDemo,
      title: inputs.title,
      content: inputs.content,
      sendType: "advertising",
      link: inputs.link,
      sendTime: inputs.date,
      iid: iid,
    };
    formData.append(
      "request",
      new Blob([JSON.stringify(data)], { type: "application/json" })
      );
      formData.append("file", previewImg);
      if(isCompletePush) {
        setIsLoading(true);
        console.log(data)
        // editPush(myPushProject, mid, setIsLoading, formData);
    }
    console.log(data)
  }

  return (
    <Layout>
      {/* 로딩창 */}
      {isLoading && <Loading></Loading>}
      <div style={{padding: "40px"}}>
        <TitleWrapper>
          {/* <WrapHomepages>
            {myPushProject.name ? myPushProject.name : "프로젝트를 선택해주세요"}
          </WrapHomepages> */}
          <div>
            <PageTitle>PUSH 상세 보기</PageTitle>
            <Message>
              고객들에게 날린 웹푸시 상세보기입니다.
            </Message>
          </div>
          <div>
            <GoPushListButton handleSubmit={() => {navigate('/pushList')}}>리스트 보기</GoPushListButton> 
          </div>
        </TitleWrapper>
        <SectionWrapper >
          <RightPushBoxDiv>
            {/* <PushBox>
              <Title>01.PUSH 유형</Title>
              <RadioList>
                <RadioLi onClick={handleWebCheckRadio}>
                  {!isWebCheck && (
                    <img src={inActiveCheck} alt="웹푸시 체크 아이콘" />
                  )}
                  {isWebCheck && (
                    <img src={activeCheck} alt="웹푸시 체크 아이콘" />
                  )}
                  웹 푸시
                </RadioLi>
                <RadioLi>
                  {!isMobileCheck && (
                    <img src={inActiveCheck} alt="모바일푸시 체크 아이콘" />
                  )}
                  {isMobileCheck && (
                    <img src={activeCheck} alt="모바일푸시 체크 아이콘" />
                  )}
                  모바일 앱 푸시
                </RadioLi>
              </RadioList>
            </PushBox> */}
            {/**
            {isMobileCheck || isWebCheck ? (
              <PushBox>
                <Title>02.메시지 유형</Title>
                <RadioList>
                  <RadioLi onClick={handleAdsCheckRadio}>
                    {!isAdsCheck && (
                      <img src={inActiveCheck} alt="광고성 체크 아이콘" />
                    )}
                    {isAdsCheck && (
                      <img src={activeCheck} alt="웹푸시 체크 아이콘" />
                    )}
                    광고성
                  </RadioLi>
                  <RadioLi onClick={handleInfoCheckRadio}>
                    {!isInfoCheck && (
                      <img src={inActiveCheck} alt="정보성 체크 아이콘" />
                    )}
                    {isInfoCheck && (
                      <img src={activeCheck} alt="기타 체크 아이콘" />
                    )}
                    정보성
                  </RadioLi>
                  <RadioLi onClick={handleEtcCheckRadio}>
                    {!isEtcCheck && (
                      <img src={inActiveCheck} alt="모바일푸시 체크 아이콘" />
                    )}
                    {isEtcCheck && (
                      <img src={activeCheck} alt="모바일푸시 체크 아이콘" />
                    )}
                    기타
                  </RadioLi>
                </RadioList>
              </PushBox>
            ) : null}
            */}
            <PushBox>
              <Title>메시지 내용</Title>
              <WrapMessage>
                <SubTitle><EssentialMark>* </EssentialMark>타이틀</SubTitle>
                <Input
                  type="text"
                  placeholder="제목을 입력해주세요."
                  value={title ? title : ""}
                  name="title"
                  readOnly
                  onChange={handleInputValues}
                ></Input>
              </WrapMessage>
              <WrapAreaMessage>
                <SubTitle><EssentialMark>* </EssentialMark>내용</SubTitle>
                <ContentArea
                  type="text"
                  placeholder="웹푸시에 넣을 내용을 입력해주세요."
                  value={content ? content : ""}
                  name="content"
                  readOnly
                  onChange={handleInputValues}
                ></ContentArea>
              </WrapAreaMessage>
              <WrapMessage>
                <SubTitle>클릭 이동 링크</SubTitle>
                <Input
                  type="text"
                  placeholder="연결할 주소를 입력해주세요 ex.(https://www.example.com)"
                  value={link ? `${link}`  : ""}
                  name="link"
                  onKeyDown={onKeyDown}
                  readOnly
                  onChange={handleInputValues}
                ></Input>
              </WrapMessage>
              {/* <WrapMessage>
                <SubTitle>이미지</SubTitle>
                <ImageInput
                  placeholder="이미지를 등록하세요"
                  value={previewImg ? previewImg.name : ""}
                  name="image"
                  readOnly={true}
                ></ImageInput>
                <ImageInput
                  placeholder="이미지를 등록하세요"
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  ref={imageInputRef}
                  onChange={handleUploadImage}
                ></ImageInput>
                <RegisterImageButton handleUploadImage={onImgInputBtnClick}>
                  이미지 등록
                </RegisterImageButton>
              </WrapMessage>
              <StandardBox>
                <StandardP>* 최대 크기 1038 x 519 px (2:1)/ png, jpg, jpeg만 가능</StandardP>
              </StandardBox> */}
              {/* 아이콘!!!! 🐰 */}
              {/* <WrapMessage icon>
                <SubTitle><EssentialMark>* </EssentialMark>아이콘</SubTitle>
                <AlignIcon>
                  {iconArr.map(({ url }, index) => {
                    if (url === iconUrl) {
                      return (
                        <SelectIconDiv key={index}>
                          <IconBox onClick={handleIconSelect}>
                            <Icon src={url} alt={url} />
                          </IconBox>
                        </SelectIconDiv>
                      );
                    } else {
                      return (
                        <IconBox onClick={handleIconSelect} key={index}>
                          <Icon src={url} alt={url} />
                        </IconBox>
                      );
                    }
                  })}
                </AlignIcon>
                <ImageInput
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  ref={iconInputRef}
                  onChange={handleUploadIcon}
                />
                <IconButnsAlign>
                {resultError.length > 0 && <ErrorAlert data={resultError} open={true}/>}
                  {myPushProject.expiryDate ? null : (
                    <RegisterIconButton handleUploadIcon={onIconInputBtnClick}>
                      아이콘 등록
                    </RegisterIconButton>
                  )}
                  {iconUrl.length > 0 ? (
                    <DeleteIconButton deleteIcon={() => deleteIcon(iid,myPushProject,setIsAlertOpen,setAlertMessage, setIconUrl,setIconArr, setIid)}>
                      아이콘 삭제
                    </DeleteIconButton>
                  ) : null}
                </IconButnsAlign>
              </WrapMessage> */}
              {/* <StandardBox>
                <StandardP>* 최대 크기 256 x 256 px (2:1)/ png, jpg, jpeg만 가능</StandardP>
                <StandardP>* png의 경우 뒷배경이 없으면 검정색으로 표현됨</StandardP>
              </StandardBox> */}
            </PushBox>
            {/* <PushBox>
              <Title>02. 발송 유형</Title>
              <RadioList>
                <RadioLi onClick={handleDirectCheckRadio}>
                  {!isDirectCheck && (
                    <img src={inActiveCheck} alt="즉시발송 체크 아이콘" />
                  )}
                  {isDirectCheck && (
                    <img src={activeCheck} alt="즉시발송 체크 아이콘" />
                    )}
                  즉시발송
                </RadioLi>
                <ReserveWrapper>
                  <RadioLi onClick={handleReserveCheckRadio}>
                    {!isReserveCheck && (
                      <img src={inActiveCheck} alt="예약발송 체크 아이콘" />
                    )}
                    {isReserveCheck && (
                      <img src={activeCheck} alt="예약발송 체크 아이콘" />
                    )}
                    예약발송
                  </RadioLi>
                  {isReserveCheck && (
                    <DatePicker selected={reserveDate} onChange={ onSelectDate } locale={ko} dateFormat="yyyy-MM-dd" minDate={new Date()}  placeholderText="예약 날짜"/>
                  )}
                  {isReserveCheck && (
                  <DatePicker 
                  selected={reserveTime}
                  onChange={onSelectTime}
                  locale={ko}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="예약 시간"
                  minDate={new Date()}
                  dateFormat="p"
                  placeholderText="예약 시간"/>
                  )}
                </ReserveWrapper>
              </RadioList>
            </PushBox> */}
            {/* <ButtonWrapper>
              {content &&
                title &&
                link &&
                myPushProject.pid &&
                !myPushProject.expiryDate &&
                (isMobileCheck || isWebCheck) &&
                (isDirectCheck || isReserveCheck) && (
                  <ActivePushButton handleSubmit={onClickSubmit}>
                    수정하기
                  </ActivePushButton>
                )}
              {(!content ||
                !title ||
                !link ||
                !myPushProject.pid ||
                myPushProject.expiryDate ||
                (!isMobileCheck && !isWebCheck) ||
                (!isDirectCheck && !isReserveCheck)) && (
                <InactivePushButton>수정하기</InactivePushButton>
              )}
            </ButtonWrapper> */}
          </RightPushBoxDiv>
          <DemoSection>
              <DemoWrapBox>
                <Title>
                  PC 미리보기
                </Title>
                <DemoWrapperBox>
                  <DemoBoxWeb>
                    <WrapDemoContent>
                    <WrapBrowserImg>
                      <ChromeImg src={chrome} alt="크롬 로고" />
                      <LinkMessage>i-promotion.co.kr</LinkMessage>
                      <DemoTime>• 지금<span><DemoArrow up src={arrow} alt="미리보기 펼쳐짐" /></span></DemoTime>
                    </WrapBrowserImg>
                      <div
                        style={{
                          display: "flex",
                          gap: "18px",
                          alignItems: "flex-start",
                          marginTop: "20px",
                        }}
                      >
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <SubDemoTitle>{title ? title : ""}</SubDemoTitle>
                        <SubMessage>{content ? content : ""}</SubMessage>
                      </div>
                      {msgDetail.icon &&
                        <WrapIconDiv>
                          <img style={{ width: "60px" }} src={msgDetail.icon ? msgDetail.icon : Rectangle} alt="아이콘 이미지" />
                        </WrapIconDiv>  
                      }
                    </div> 
                    {msgDetail.image && 
                      <WrapDemoImg>
                        <DemoImg
                          src={msgDetail.image ? msgDetail.image : Rectangle}
                          alt="데모이미지"
                        />
                      </WrapDemoImg>
                    }
                    </WrapDemoContent>
                  </DemoBoxWeb>
                </DemoWrapperBox>
              </DemoWrapBox>
              <DemoWrapBox>
              <Title>
                모바일 미리보기
              </Title>
              <DemoWrapperBox>
                <AppDesignBox>
                <AppTopFlexBox>
                    <li>12:00 1월 1일 토요일</li>
                    <li><img src={settingIcon} alt="" style={{width: '18px'}}/></li>
                  </AppTopFlexBox>
                  <AppTopFlexBox>
                    <AppTopIcons wifi></AppTopIcons>
                    <AppTopIcons speaker></AppTopIcons>
                    <AppTopIcons bluetooth></AppTopIcons>
                    <AppTopIcons lock></AppTopIcons>
                    <AppTopIcons flash></AppTopIcons>
                    <AppTopIcons flight></AppTopIcons>
                  </AppTopFlexBox>
                  <AppUnderLine></AppUnderLine>
                  <DemoBoxApp>
                    <WrapDemoContent>
                      <div style={{display:"flex", justifyContent:'space-between',alignItems: 'flex-start', width: '100%'}}>
                        <WrapBrowserImg app>
                          <ChromeImg app src={chrome} alt="크롬 로고" />
                          <LinkMessage>Chrome</LinkMessage>
                          <LinkMessageSamll>i-promotion.co.kr</LinkMessageSamll>
                          <LinkMessageSamll>지금</LinkMessageSamll>
                        </WrapBrowserImg>
                        {msgDetail.icon &&
                          <WrapIconDiv app>
                            <img style={{ width: "45px", borderRadius:"8px" }} src={msgDetail.icon ? msgDetail.icon : Rectangle} alt="아이콘 이미지" />
                          </WrapIconDiv>
                        }
                        <DemoArrow up src={arrow} alt="미리보기 펼쳐짐" />
                      </div>
                      <div
                        style={{
                          marginTop: "-20px",
                          marginLeft: "54px",
                          width: '245px',
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <SubDemoTitle app>{inputs.title}</SubDemoTitle>
                          <SubMessage app>{inputs.content}</SubMessage>
                        </div>
                      </div>
                      {msgDetail.image && 
                        <WrapDemoImg app>
                        <DemoImg
                            app
                            src={msgDetail.image ? msgDetail.image : Rectangle}
                            alt="데모이미지"
                          />
                      </WrapDemoImg>
                      }
                    </WrapDemoContent>
                  </DemoBoxApp>

                </AppDesignBox>
              </DemoWrapperBox>
            </DemoWrapBox>
            </DemoSection>
        </SectionWrapper>
      </div>
    </Layout>
  );
}


const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const SectionWrapper = styled.section`
  margin-bottom: 40px;
  position: relative;
  display: flex;
  gap: 20px;
`;

const RightPushBoxDiv = styled.div`
  flex: 1;
`

const DemoSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 480px;
`;
const DemoFoldpBtnList = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`
const FoldBtn = styled.button`
  font-size: 14px;
  font-weight: 700;
  color: ${grey6};
`
const PageTitle = styled.h2`
  color: ${grey10};
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 12px;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 12px;
  color: ${grey10};

  display: flex;
  align-items: center;
  gap: 7px;
`;

const SubTitle = styled.h4`
  width: 150px;
  font-size: 16px;
  font-weight: 500;
  padding: 6px;
  color: ${grey7};
`;

const EssentialMark = styled.span`
  color: ${error3};
`

const SubDemoTitle = styled.h4`
  display: block;
  width: ${(props) => (props.app ? "100%" : "300px")};;
  padding-bottom: ${(props) => (props.app ? "6px" : "12px")};
  font-size: 16px;
  font-weight: 400;
  color: ${grey10};
  white-space: pre-wrap;
  word-break:keep-all; 
  word-wrap:break-word
`;

const Message = styled.p`
  color: ${grey7};
  font-size: 14px;
`;

const WrapMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const WrapAreaMessage = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const DemoWrapperBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  background: ${grey2};
  border-radius: 8px;
  margin-top: 16px;
`;

const Input = styled.input`
  width: 100%;
  padding: 16px;
  margin-top: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${grey5};
  color: ${grey10};

  :read-only {
    background: ${MAIN_BACKGROUND_COLOR};
  }
`;
const InputDate = styled.input`
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${grey5};
  color: ${grey10};
`;
const ImageInput = styled.input`
  width: calc(100% - 170px);
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${grey5};
  color: ${grey10};
`;
const ContentArea = styled.textarea`
  width: 100%;
  line-height: 1.5;
  height: 200px;
  padding: 16px;
  margin-top: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  border: 1px solid ${grey5};
  color: ${grey10};
  resize: none;

  :read-only {
    background: ${MAIN_BACKGROUND_COLOR};
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${primary3};
    border-radius: 8px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: none;
    border-radius: 4px;
  }
`;

const RadioList = styled.ul`
  display: flex;
  margin: 14px 0;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
`;

const RadioLi = styled.li`
  display: flex;
  margin-right: 20px;
  align-items: center;
  gap: 4px;
`;
const SubMessage = styled.p`
  color: ${grey8};
  line-height: 1.2;
  padding-top:  ${(props) => (props.app ? "0" : "6px")};
  letter-spacing:  -0.5px;
  white-space:pre-wrap;
  word-break:break-word; 
  word-wrap:break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp : ${(props) => (props.small ? 1 : 4)};
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
const LinkMessage = styled.p`
color: ${grey8};
font-size: 14px;
`;
const LinkMessageSamll = styled.p`
color: ${grey8};
font-size: 12px;
`;

const DemoTime = styled.p`
  color: ${grey8};
  font-size: 14px;
  cursor: pointer;
`
const DemoArrow = styled.img`
  width : 10px;
  height: 10px;
  margin-left: 5px;
  rotate: ${(props) => (props.up ? '180deg' : 0)};
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 320px;
  margin: 20px auto;
`;
const ReserveWrapper = styled.div`
  display: flex;
  width: 450px;
  height: 36px;
  gap: 8px;
  justify-content: flex-start;
`;
const WrapDemoImg = styled.div`
  width: ${(props) => (props.app ? `calc(100% - 54px)` : "100%")};
  height: 188px;
  /* background: ${grey3}; */
  padding: 0 0px;
  margin-top: 20px;
  margin-left:  ${(props) => (props.app ? `54px` : "0")};
  box-sizing: border-box;
  border-radius:0 0 2px 2px;
  border-radius: ${(props) => (props.app ? `32px` : "0")};
`
const DemoImg = styled.img`
  width: 100%;
  height: 188px;
  object-fit: fill;
  border-radius: ${(props) => (props.app ? `32px` : "0")};
`;
const SelectIconDiv = styled.div`
  background: ${grey5};
`;

const IconBox = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  padding: 5px;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AlignIcon = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  margin: 20px 0 0 29px;
`;

const WrapDemoContent = styled.div`
  padding: 20px;
  width: 100%;
`;

const WrapBrowserImg = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: ${(props) => (props.app ? `flex-start` : "center")};
  gap: 8px;
`;

const ChromeImg = styled.img`
  width: 18px;
  height: 18px;
  background: ${(props) => (props.app ? `${grey4}` : "")};
  border-radius: ${(props) => (props.app ? `50%` : "")};
  padding: ${(props) => (props.app ? `10px` : "")};
  margin-right: ${(props) => (props.app ? `8px` : "")};
`;

const WrapIconDiv = styled.div`
  width: ${(props) => (props.app ? `45px` : "60px")};
  height: ${(props) => (props.app ? `45px` : "60px")};
  border-radius: ${(props) => (props.app ? `8px` : "0")};
  margin-right: ${(props) => (props.app ? `4px` : "0")};
  background: ${grey4};
`;

const IconButnsAlign = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
`;


const CustomDatePicker = styled.button`
  width: 120px;
  padding: 10px;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  color: #262626;
  font-weight: 500;
  box-sizing: border-box;
  text-align: center;
  `

  /* 규격 텍스트 추가 */
  const StandardBox = styled.div`
    width: 100%;
    
  `
  const StandardP = styled.p`
    margin-left: 130px;
    color: ${primary2};
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
  `

  const AppDesignBox = styled.div`
    border-radius: 16px;
    background: ${grey3};
    padding: 0 0;
    border: 1px solid ${grey5};
    position: relative;
  `

  const AppTopFlexBox = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-size: 14px;
  `
  const AppTopIcons = styled.li`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${(props) => (props.wifi ? `url('${wifi}') ${subC}` : null)};
    background: ${(props) => (props.speaker ? `url('${speaker}') ${subC}` : null)};
    background: ${(props) => (props.bluetooth ? `url('${bluetooth}') ${grey4}` : null)};
    background: ${(props) => (props.lock ? `url('${lock}') ${grey4}` : null)};
    background: ${(props) => (props.flash ? `url('${flash}') ${grey4}` : null)};
    background: ${(props) => (props.flight ? `url('${flight}') ${grey4}` : null)};
    background-repeat: no-repeat;
    background-position: center;
    ;
  `

  const AppUnderLine = styled.div`
    width: 25px;
    height: 3px;
    background: ${grey5};
    border-radius: 32px;
    margin: 0 auto 16px;
  `