import styled from "styled-components";
import { ProfileBox } from "../../components/containers/profile/ProfileBox";
import { MAIN_BACKGROUND_COLOR, error3, grey4, grey5, grey7, grey9, primary1 } from "../../constants/color";
import Layout from "../../templates/Layout";
import {
  InputGroup,
  InputValidateGroup,
} from "../../components/inputs/InputGroups";
import {
  UpdateInactiveProfileBtn,
  UpdateProfileBtn,
} from "../../components/buttons/ProfileButtons";
import { instanceAxios } from "../../api/axios";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  AlertMessage,
  IsAlertOpen,
  MyCategory,
  MyProfile,
} from "../../atom/Atom";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import ErrorAlert from "../../error/ErrorAlert";
import ErrorLabel from "../../error/ErrorLabel";

export default function MyPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [myProfile, setMyProfile] = useRecoilState(MyProfile);
  const [myCategory, setMyCategory] = useRecoilState(MyCategory);
  const [company, setCompany] = useState(myProfile.company);
  const [email, setEmail] = useState(myProfile.email);
  const [name, setName] = useState(myProfile.name);
  const [phone, setPhone] = useState(myProfile.phone);
  const [position, setPosition] = useState(myProfile.position);
  const [task, setTask] = useState(myProfile.business_task);
  const [department, setDepartment] = useState(myProfile.department);
  const [isValidEmail, setIsValidEmail] = useState(true);

  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);
  // error Type
  const [isErrorType, setIsErrorType] = useState(null);
  // error func
  const commonErrorPopup = (msg, type) => {
    setIsAlertOpen(true);
    setAlertMessage(msg);
    // 함수는 () => 함수이름
    // 바로 작동하면 안됨
    setIsErrorType(type);
  };

  useEffect(() => {
    if (phone) {
      if (phone.length === 10) {
        setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
      }
      if (phone.length === 13) {
        setPhone(
          phone.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
        );
      }
    }
  }, [phone]);

  const handlePhone = (e) => {
    if (e.target.name === "phone") {
      const regex = /^[0-9\b -]{0,13}$/;
      if (regex.test(e.target.value)) {
        setPhone(e.target.value);
      }
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.name === "email") {
      const regex = /^[a-zA-Z0-9]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/gm;
      if (regex.test(e.target.value)) {
        setEmail(e.target.value);
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    }
  };


  const [resultErr, setResultErr] = useState([]);
  const [phoneErr, setPhoneErr] = useState([]);
  const [nameErr, setNameErr] = useState([]);
  const [departmentErr, setDepartmentErr] = useState([]);
  const [positionErr, setPositionErr] = useState([]);
  const [taskErr, setTaskErr] = useState([]);

  const resetErr = () => {
    setResultErr([]);
    setPhoneErr([]);
    setNameErr([]);
    setDepartmentErr([]);
    setPositionErr([]);
    setTaskErr([]);
  }
  
  const updateData = {
    // readonly
    company: myProfile.company,
    business_number: myProfile.business_number,
    email: myProfile.email,
    roles: myProfile.roles,

    // 수정 가능 정보
    name: name,
    phone: phone,
    department: department,
    position: position,
    business_task: task, 
  };

  const updateMyInfo = async (e) => {
    e.preventDefault();
    resetErr();
    const regex = /^01[0|1|6|7|8|9]-[0-9]{4}-[0-9]{4}$/gm
    const isphoneRight  = regex.test(phone);
    if(name.length <= 0) {
      commonErrorPopup("이름을 입력해주세요.", null);
      return;
    } else if(phone.length <= 0) {
      commonErrorPopup("휴대폰번호를 입력해주세요.", null);
      return;
    } else if(0 < phone.length && !isphoneRight) {
      commonErrorPopup("휴대폰번호 형식을 확인해주세요.", null);
      return;
    } else if(company.length <= 0) {
      commonErrorPopup("회사명을 입력해주세요.", null);
      return;
    } else if(department.length <= 0) {
      commonErrorPopup("부서를 입력해주세요.", null);
      return;
    } else if(position.length <= 0) {
      commonErrorPopup("직급를 입력해주세요.", null);
      return;
    } else if(task.length <= 0) {
      commonErrorPopup("담당업무를 입력해주세요.", null);
      return;
    } 

    // confirm
    if (window.confirm("개인정보를 수정하시겠습니까?")) {
      try {
        const response = await instanceAxios.put("/member/update", updateData);
        setIsLoading(true);
        if (response.status === 200) {
          setIsLoading(false);
          if(!isLoading) {
            setIsAlertOpen(true);
            setAlertMessage(response.data.data.msg);
            setMyProfile(updateData); 
          }
        }
      } catch (err) {
        setIsLoading(false);
        const errData = err.response.data.data;
        if(errData.result) setResultErr(errData.result);
        if(errData.name) setNameErr(errData.name);
        if(errData.phone) setPhoneErr(errData.phone);
        if(errData.department) setDepartmentErr(errData.department);
        if(errData.position) setPositionErr(errData.position);
        if(errData.business_task) setTaskErr(errData.business_task);
        if(err.response.status >= 500) {
          commonErrorPopup("잘못된 접근입니다. 다시 시도해주세요.", null)
        }
      }
    }
  };


  return (
    <Layout>
      <ProfileBox name={myProfile.name} secessionMember>
      {isLoading && <Loading></Loading>}
      {resultErr.length > 0 && <ErrorAlert data={resultErr} open={true}/>}
        <form action="post">
          <SubTitle>회사 정보</SubTitle>
          <WrapInputs>
            <LabelStyle htmlFor="company">회사명</LabelStyle>
            <div>
              <InputGroup
                type="text"
                id="company"
                value={!company ? "" : company}
                readonly={true}
              />
            </div>
          </WrapInputs>
          <WrapInputs>
            <LabelStyle htmlFor="business_number">사업자번호</LabelStyle>
            <div>
              <InputGroup
                type="text"
                id="business_number"
                value={!myProfile.business_number ? "" : myProfile.business_number }
                readonly={true}
              />
            </div>
          </WrapInputs>

          <div style={{marginTop: '60px'}}></div>
          {/* ------------- 개인 정보------------- */}
          <SubTitle>담당자 정보</SubTitle>
          <WrapInputs>
            <LabelStyle htmlFor="email">이메일</LabelStyle>
            <div>
              <InputValidateGroup
                type="text"
                id="email"
                name="email"
                readonly={true}
                value={!email ? "" : email}
              />
            </div>
          </WrapInputs>
          <WrapInputs>
            <LabelStyle htmlFor="name"><EssetialLabel>* </EssetialLabel>이름</LabelStyle>
            <div>
              <InputGroup
                type="text"
                id="name"
                name="name"
                value={!name ? "" : name}
                setValue={setName}
              />
              {nameErr.length > 0 && <ErrorLabel data={nameErr}/>}
            </div>
          </WrapInputs>
          <WrapInputs>
            <LabelStyle htmlFor="phone"><EssetialLabel>* </EssetialLabel>휴대폰 번호</LabelStyle>
            <div>
              <InputValidateGroup
                type="text"
                id="phone"
                name="phone"
                value={!phone ? "" : phone}
                setValue={handlePhone}
              />
              {phoneErr.length > 0 && <ErrorLabel data={phoneErr}/>}
            </div>
          </WrapInputs>
          <WrapInputs>
            <LabelStyle htmlFor="department"><EssetialLabel>* </EssetialLabel>부서</LabelStyle>
            <div>
              <InputGroup
                type="text"
                id="department"
                value={!department ? "" : department}
                setValue={setDepartment}
              />
              {departmentErr.length > 0 && <ErrorLabel data={departmentErr}/>}
            </div>
          </WrapInputs>
          <WrapInputs>
            <LabelStyle htmlFor="position"><EssetialLabel>* </EssetialLabel>직급</LabelStyle>
            <div>
              <InputGroup
                type="text"
                id="position"
                value={!position ? "" : position}
                setValue={setPosition}
              />
              {positionErr.length > 0 && <ErrorLabel data={positionErr}/>}
            </div>
          </WrapInputs>
          <WrapInputs>
            <LabelStyle htmlFor="task">담당업무</LabelStyle>
            <div>
              <InputGroup
                type="text"
                id="task"
                value={!task ? "" : task}
                setValue={setTask}
              />
              {taskErr.length > 0 && <ErrorLabel data={taskErr}/>}
            </div>
          </WrapInputs>
          
          <WrapButton>
            {Object.values(myProfile).toString() != Object.values(updateData).toString() && (
              <UpdateProfileBtn updateMyInfo={updateMyInfo}>
                수정
              </UpdateProfileBtn>
            )}
            {Object.values(myProfile).toString() ===
              Object.values(updateData).toString() && (
              <UpdateInactiveProfileBtn>
                수정
              </UpdateInactiveProfileBtn>
            )}
          </WrapButton>
        </form>
      </ProfileBox>
    </Layout>
  );
}

const WrapInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 380px;
  /* gap: 180px; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;
const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  width: fit-content;
  color: ${grey9};
  z-index: 1;
  position: relative;
  margin-bottom: 20px;

  ::after {
    display: block;
    position: absolute;
    content: '';
    background: ${primary1};
    z-index: -1;
    opacity: 0.5;
    width: 100%;
    height: 8px;
    padding: 0 5px;
    left: -5px;
    bottom: -1px;
    border-radius: 16px;
  }
`
const EssetialLabel = styled.span`
color: ${error3};
`

const LabelStyle = styled.label`
  display: flex;
`;
const WrapButton = styled.div`
  width: 180px;
  margin: 80px auto 20px;
`;
const LabelWarning = styled.span`
  display: block;
  color: ${error3};
  font-size: 12px;
  margin: 8px 0 0;
`;
const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${grey5};

  :read-only {
    background-color: ${MAIN_BACKGROUND_COLOR};
  }
`;