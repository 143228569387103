import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();


// ---------- templete 중앙 통제 ------------
// ------- ✨ 레이아웃 --------
// 레이아웃 위치
const Temp_position = atom({
  key: "TempPosition", // unique ID (with respect to other atoms/selectors)
  default: {top:'unset', bottom: '0', left: 'unset', right: '0', transform: 'translate(0,0)'}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
// 레이아웃 크기
const Temp_size = atom({
  key: "TempSize", // unique ID (with respect to other atoms/selectors)
  default: {width: '250', height: '260'}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
})
// 레이아웃 모양
const Temp_shape = atom({
  key: "TempShape", // unique ID (with respect to other atoms/selectors)
  default: '0', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

//레이아웃 투명도
const Temp_opacity = atom({
  key: "TempOpacity", // unique ID (with respect to other atoms/selectors)
  default: '1', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const Temp_bg = atom({
  key: "TempBg", // unique ID (with respect to other atoms/selectors)
  default: '#fff', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

// ------- ✨ 이미지 --------
// 사진
const Temp_img_url = atom({
  key: "TempImgUrl", // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const Temp_img_file = atom({
  key: "TempImgFile", // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const Temp_img_round = atom({
  key: "TempImgRound", // unique ID (with respect to other atoms/selectors)
  default: '0', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

// ------- ✨ 버튼 --------
const Temp_btn_shape = atom({
  key: "TempBtnShape", // unique ID (with respect to other atoms/selectors)
  default: '0', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const Temp_btn_color = atom({
  key: "TempBtnColor", // unique ID (with respect to other atoms/selectors)
  default: '#fff', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const Temp_btn_txt_color = atom({
  key: "TempBtnTxtColor", // unique ID (with respect to other atoms/selectors)
  default: '#333', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const Temp_btn_txt_weight = atom({
  key: "TempBtnTxtWeight", // unique ID (with respect to other atoms/selectors)
  default: 'normal', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const Temp_btn_txt = atom({
  key: "TempBtnTxt", // unique ID (with respect to other atoms/selectors)
  default: '더 알아보기', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});


// ---- ✨ 텍스트 ----
const Temp_cont_txt = atom({
  key: "TempContTxt", // unique ID (with respect to other atoms/selectors)
  default: {title: '제목을 입력하세요.', content : '내용을 입력하세요.'}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const Temp_cont_size = atom({
  key: "TempContSize", // unique ID (with respect to other atoms/selectors)
  default: {title: '18px', content : '16px'}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const Temp_cont_weight = atom({
  key: "TempContWeight", // unique ID (with respect to other atoms/selectors)
  default: {title: 'normal', content : 'normal'}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const Temp_cont_color = atom({
  key: "TempContColor", // unique ID (with respect to other atoms/selectors)
  default: {title: '#333', content : '#333'}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
const Temp_cont_align = atom({
  key: "TempContAlign", // unique ID (with respect to other atoms/selectors)
  default: {title: '', content : ''}, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});
export {
  Temp_position,
  Temp_shape,
  Temp_opacity,
  Temp_bg,
  Temp_size,
  Temp_img_url,
  Temp_img_file,
  Temp_img_round,
  Temp_btn_shape,
  Temp_btn_color,
  Temp_btn_txt_color,
  Temp_btn_txt_weight,
  Temp_btn_txt,
  Temp_cont_txt,
  Temp_cont_size,
  Temp_cont_weight,
  Temp_cont_color,
  Temp_cont_align
};
