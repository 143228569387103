import styled from "styled-components";
import { SignupBox } from "../../components/containers/auth/AuthBox";
import {
  grey11,
  grey9,
  grey1,
  MAIN_BACKGROUND_COLOR,
  grey5,
  grey6,
  primary4,
  error3,
  primary1,
  error2,
} from "../../constants/color";
import logo from "../../assets/images/logo.png";
import {
  CertificationButton,
  UnCertificationButton,
  SignupButton,
  BeforeSignupButton,
  ActiveTokenButton,
  InactiveTokenButton,
} from "../../components/buttons/SignupButtons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import SignupAgreement from "../../components/agreement/SignupAgreement";
import { instanceAxios } from "../../api/axios";
import warning from "../../assets/images/warning.png";
import {
  DropboxInput,
  InputValidateGroup,
} from "../../components/inputs/InputGroups";
import {EmailDropbox} from '../../components/dropbox/dropbox'
import { useRecoilState } from "recoil";
import { AlertMessage, IsAlertOpen } from "../../atom/Atom";
import AlertModal from "../../components/modals/AlertModal";
import { NextActiveBtn, NextInactiveBtn, PrevBtn } from "../../components/buttons/AuthButtons";
import { RegisterImageButton } from "../../components/buttons/PushButtons";
import ErrorAlert from "../../error/ErrorAlert";
import ErrorLabel from "../../error/ErrorLabel";
import Loading from "../../components/loading/Loading";



//--------------회원가입 페이지--------------------------
export default function Signup() {
  const [isLoading, setIsLoading] = useState(false);

  // 아이디 저장 기능
  const [iscapslock, setIsCapsLock] = useState(false);
  const navigate = useNavigate();
  
  // 토큰 인증 박스 열기
  const [isOpenTokenBox, setIsOpenTokenBox] = useState(false);
  // 토큰 재인증 요청
  const [isTokenVerification, setIsTokenVerification] = useState(false);
  // 이메일
  const [email, setEmail] = useState("");
  // 이메일 직접 입력
  const [isWriteEmail, setIsWriteEmail] = useState(false);
  // email validation
  const [emailVaildation, setEmailVaildation] = useState(true);
  // 비밀번호
  const [passwordVaildation, setPasswordVaildation] = useState(true);
  // 비밀번호 확인
  const [conPasswdVaildation, setConPasswdVaildation] = useState(true);
  // 핸드폰
  const [phoneWrite, setPhoneWrite] = useState("");
  const [phoneVaildation, setPhoneVaildation] = useState(true);
  // 홈페이지 url 유효성
  const [isUrl, setIsUrl] = useState(false);
  // 동의란
  const [essentialCheck, setEssentialCheck] = useState(false);
  // 이메일 인증 타이머
  const [minutes, setMinutes] = useState(parseInt(3));
  const [seconds, setSeconds] = useState(parseInt(0));

  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);
  // Error Type
  const [isErrorType, setIsErrorType] = useState(null);

  //에러 팝업 공통 함수
  const commonErrorPopup = (msg, type) => {
    setIsAlertOpen(true);
    setAlertMessage(msg);
    // 함수는 () => 함수이름
    // 바로 작동하면 안됨
    setIsErrorType(type);
  };


  //capsLock 여부
  const handleCheckCapsLock = (e) => {
    if (e.getModifierState("CapsLock")) {
      setIsCapsLock(true);
    } else {
      setIsCapsLock(false);
    }
  };

  useEffect(() => {
    if (isOpenTokenBox) {
      const countdown = setInterval(() => {
        if (parseInt(seconds) > 0) {
          setSeconds(parseInt(seconds) - 1);
        }
        if (parseInt(seconds) === 0) {
          if (parseInt(minutes) === 0) {
            clearInterval(countdown);
          } else {
            setMinutes(parseInt(minutes) - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [minutes, seconds, isOpenTokenBox]);

  const [inputs, setInputs] = useState({
    id: "",
    // email: "",
    password: "",
    confirmPassword: "",
    name: "",
    phone: "",
    company: "",
    token: "",
    department: "",
    position:"",
    task: "",
    businessNum:"",
    homepageName: "",
    homepageLink: "",
    companyKey: "",
  });

  const { token, password, confirmPassword, name, phone, company,department, position, task, businessNum, homepageName, homepageLink, companyKey} = inputs;

  useEffect(() => {
    if (phoneWrite.length === 10) {
      setPhoneWrite(phoneWrite.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (phoneWrite.length === 13) {
      setPhoneWrite(
        phoneWrite
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [phoneWrite]);

  useEffect(() => {
    const regex = /[^0-9]/g;
    if(regex.test(businessNum)) {
      const onlyNum = businessNum.replace(regex, "");
      setInputs({...inputs, businessNum: onlyNum})
    } else {
      return;
    }
  },[businessNum])

  useEffect(() => {
    const regex = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/gm;
    if(regex.test(homepageLink)) {
      setIsUrl(true);
    } else {
      setIsUrl(false);
    }
  },[homepageLink])

  const handleInputValues = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (e.target.name === "password") {
      // 영문 숫자 특수문자 1개씩 +  8-25글자 정규식
      let re = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
      // setPassword(e.target.value)
      setPasswordVaildation(re.test(e.target.value));
      if (conPasswdVaildation) {
        setConPasswdVaildation(false);
      }
    } else if (e.target.name === "confirmPassword") {
      if (e.target.value === password) {
        setConPasswdVaildation(true);
      } else {
        setConPasswdVaildation(false);
      }
    } else if (e.target.name === "phone") {
      const regex = /^[0-9\b -]{0,13}$/;
      if (regex.test(e.target.value)) {
        setPhoneWrite(e.target.value);
        setPhoneVaildation(true);
      } else {
        return setPhoneVaildation(false);
      }
    } 
    setInputs({
      ...inputs,
      [name]: value,
    });

  };

  const handleEmail = (e) => {
    e.preventDefault();
    const re =/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;
    let result = re.test(e.target.value);
    setEmail(e.target.value);
    result ? setEmailVaildation(true) : setEmailVaildation(false);
  };

  // 이메일 입력 > 토큰 발급 요청
  // 이메일 : id +@+ email
  const [showTokenBtn, setShowTokenBtn] = useState(true);
  const [emailTokenErr, setEmailTokenErr] =useState([]);
  const [emailTokenResultErr, setEmailTokenResultErr] =useState([]);
  const requestToken = async (e) => {
    e.preventDefault();
    setEmailTokenErr([]);
    setEmailTokenResultErr([]);
    setTokenCompleteErr([]);
    // 재인증 클릭 (시간 다시 시작)
    setMinutes(parseInt(3));
    setSeconds(parseInt(0));
    try {
      const response = await instanceAxios.post("/auth/emailToken", {
        email: email,
      });
      if (response.status === 200) {
        setShowTokenBtn(false);
        setIsOpenTokenBox(true);
        setIsTokenVerification(false);
        setIsAlertOpen(true);
        setAlertMessage(response.data.msg);
      }
    } catch (err) {
      const errData = err.response.data.data;
      console.log(err.response)
      if(errData.result) setEmailTokenResultErr(errData.result);
      if(errData.email) setEmailTokenErr(errData.email);
      setEmail("");
      setInputs({...inputs, token : ""});
    }
  };

  // 토큰 인증 완료 요청
  const [tokenCompleteErr, setTokenCompleteErr] =useState([]);
  const requestCompleteToken = async (e) => {
    e.preventDefault();
    setTokenCompleteErr([]);
    try {
      const response = await instanceAxios.post("/auth/emailTokenComplete", {
        email: email,
        token: token,
      });
      if (response.status === 200) {
        setIsTokenVerification(true);
        setIsAlertOpen(true);
        setAlertMessage(response.data.msg);
        setIsOpenTokenBox(false);
      }
      console.log(response);
    } catch (err) {
      console.error(err.response.data.data);
      const errData = err.response.data.data;
      if(err.response.status === 406) {
        setIsAlertOpen(true);
        setAlertMessage("잘못된 접근입니다. 새로고침 후 다시 시도해주세요.");
      }
      if(errData.result) setTokenCompleteErr(errData.result);
      if(errData.token) setTokenCompleteErr(errData.token);
      if(errData.email) setTokenCompleteErr(errData.email);
      if(err.response.status === 404) {
        commonErrorPopup("유효하지 않은 인증번호입니다. 인증번호를 재발급해주세요.", null);
      } 
      setInputs({...inputs, token : ""});
    }
  };

  // 로그인 data
  const formData = new FormData();
  const registerData = {
    email: email,
    password: password,
    confirm_password: confirmPassword,
    name: name,
    phone: phoneWrite,
    department: department,
    position: position,
    business_task: task,
    company: company,
    business_number:businessNum,
    project_name: homepageName, 
    project_url: homepageLink,
    companyKey: companyKey,
    token: token,
  };
  const [emailErr, setEmailErr] = useState([]);
  const [passwordErr, setPasswordErr] = useState([]);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState([]);
  const [nameErr, setNameErr] = useState([]);
  const [phoneErr, setPhoneErr] = useState([]);
  const [companyErr, setCompanyErr] = useState([]);
  const [tokenErr, setTokenErr] = useState([]);
  const [departmentErr, setDepartmentErr] = useState([]);
  const [positionErr, setPositionErr] = useState([]);
  const [businessTaskErr, setBusinessTaskErr] = useState([]);
  const [businessNumErr, setBusinessNumErr] = useState([]);
  const [homepageNameErr, setHomepageNameErr] = useState([]);
  const [homepageLinkErr, setHomepageLinkErr] = useState([]);
  const [companyKeyErr, setCompanyKeyErr] = useState([]);
  const [itemErr, setItemErr] = useState([]);
  const [resultErr, setResultErr] = useState([]);
  const handleResetErrors = () => {
    setEmailErr([]);
    setPasswordErr([]);
    setConfirmPasswordErr([]);
    setNameErr([]);
    setPhoneErr([]);
    setCompanyErr([]);
    setTokenErr([]);
    setDepartmentErr([]);
    setPositionErr([]);
    setBusinessTaskErr([]);
    setBusinessNumErr([]);
    setHomepageNameErr([]);
    setHomepageLinkErr([]);
    setCompanyKeyErr([]);
    setItemErr([]);
    setResultErr([]);
  }
  // 로그인 요청
  const requestRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleResetErrors();
    try {
      formData.append("file", businessFile);
      formData.append(
        "data",
        new Blob([JSON.stringify(registerData)], { type: "application/json" })
        );
        const response = await instanceAxios.post("/auth/register", formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        
        if (response.status === 200) {
          setIsLoading(false);
          if(!isLoading) commonErrorPopup("성공적으로 회원가입을 완료했습니다.", '/')
        }
    } catch (err) {
      const errData = err.response.data.data;
      setIsLoading(false);
      console.log(emailErr)
      if(errData.email) setEmailErr(errData.email);
      if(errData.password) setPasswordErr(errData.password);
      if(errData.confirm_password) setConfirmPasswordErr(errData.confirm_password);
      if(errData.name) setNameErr(errData.name);
      if(errData.phone) setPhoneErr(errData.phone);
      if(errData.company) setCompanyErr(errData.company);
      if(errData.token) setTokenErr(errData.token);
      if(errData.department) setDepartmentErr(errData.department);
      if(errData.position) setPositionErr(errData.position);
      if(errData.business_task) setBusinessTaskErr(errData.business_task);
      if(errData.business_number) setBusinessNumErr(errData.business_number);
      if(errData.project_name) setHomepageNameErr(errData.project_name);
      if(errData.project_url) setHomepageLinkErr(errData.project_url);
      if(errData.companyKey) setCompanyKeyErr(errData.companyKey);
      if(errData.item) setItemErr(errData.item);
      if(errData.result) setResultErr(errData.result)
     
      // if(err.response.status === 409) {
      //   commonErrorPopup("이미 존재하는 계정입니다.", null);
      // } 
    }
  };


  // 사업자등록증 업로드
  const imageInputRef = useRef();
  const clickFileInput = (e) => {
    e.preventDefault();
    imageInputRef.current.click();
  };
  const [businessFile, setBusinessFile] = useState(null);
  const [filePreview, setFilePreview] = useState("");

  const handleUploadImage = (e) => {
    const fileList = e.target.files;
    const fileType = fileList[0].type.toLowerCase();
    if(fileType.includes("jpg") || fileType.includes("jpeg") || fileType.includes("png") || fileType.includes("pdf") ||  fileType.includes("gif") ||  fileType.includes("bmp")) {
      setBusinessFile(fileList[0]);
      setFilePreview(URL.createObjectURL(fileList[0]));
    } else {
      setIsAlertOpen(true);
      setAlertMessage("jpg, png, pdf만 가능합니다.");
      setBusinessFile("");
      setFilePreview("");
    }
    
    e.target.value = "";
  };
  const [isBsNumTrue, setIsBsNumTrue] = useState(false);
  async function postBusinessNum() {
    if (businessNum.length <= 0) {
      setIsAlertOpen(true);
      setAlertMessage('사업자등록번호를 입력해주세요.');
      return;
    } else if(businessNum.length < 10) {
      setIsAlertOpen(true);
      setAlertMessage('사업자등록번호는 숫자 10자리입니다.');
      return;
    }
    let data = {
      "b_no": [
        businessNum
      ]
    }

    try {
      const res = await fetch(
        "https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=sCAzxPmsfTOoa%2FOd9eIINWpgFiSlXDNKnUslAdyTreze%2BLVM8oGbmr0%2FTMLoStOYD1GdxHYyuEZOatPs%2FO%2BjtA%3D%3D&returnType=JSON",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      
      const resJson = await res.json();
      if(resJson.status_code === "OK") {
        if(resJson.data[0].b_stt_cd === '01') {
          setIsBsNumTrue(true);
          setIsAlertOpen(true);
          setAlertMessage('사업자등록번호가 인증되었습니다.');
        } else if(resJson.data[0].b_stt_cd === '02'|| resJson.data[0].b_stt_cd === '03') {
          setIsBsNumTrue(false);
          setIsAlertOpen(true);
          setAlertMessage('계속사업자가 아닙니다. 다시 인증해주세요.');
        } else  if(resJson.data[0].b_stt_cd === '') {
          setIsBsNumTrue(false);
          setIsAlertOpen(true);
          setAlertMessage(resJson.data[0].tax_type);
        };
      }
    } catch (err) {
      console.error(err);
    }
  }


    // 이메일 셀렉트
    const renderSelectEmail = () => {
      return (
        <>
          <InputAlign>
            <Label htmlFor="email" first>
              <EssetialLabel>*</EssetialLabel>이메일
            </Label>
            <SubInputAlign>
              <InputValidateGroup
                first
                type="text"
                placeholder="이메일을 입력해주세요."
                id="email"
                value={email}
                name="email"
                maxLength={100}
                setValue={handleEmail}
              />          
            </SubInputAlign>
          </InputAlign>
          <WrapRightItems first>
            {(email && !emailVaildation && emailErr.length <= 0)  && (
              <LabelWarning email htmlFor="email" >
                이메일 형식을 맞춰주세요.
              </LabelWarning>
            )}
             {email && emailVaildation && !isTokenVerification && showTokenBtn && (
               <CertificationButton requestToken={requestToken}>
                이메일 인증하기
              </CertificationButton>
            )} 
            {emailErr.length > 0 && <ErrorLabel data={emailErr}/>}
            {isOpenTokenBox && (
              <WrapWriteToken>
                <TokenMsg>이메일로 전송된 인증번호를 입력해주세요.</TokenMsg>
                <InputAlign style={{ gap: "8px" }}>
                  <InputValidateGroup
                    type="text"
                    placeholder="인증번호를 적어주세요."
                    name="token"
                    setValue={handleInputValues}
                    value={token}
                    readonly={isTokenVerification ? true : false}
                  />
                  <TimeSpan>
                    {minutes} : {seconds < 10 ? "0" + seconds : seconds}
                  </TimeSpan>
                  {token ? (
                    <ActiveTokenButton
                      requestCompleteToken={requestCompleteToken}
                    >
                      인증하기
                    </ActiveTokenButton>
                  ) : (
                    //차후 변경 인증하기 버튼 비활성화 <InactiveTokenButton>인증하기</InactiveTokenButton>
                    <InactiveTokenButton>
                      인증하기
                    </InactiveTokenButton>
                  )}
                </InputAlign>
                  {tokenCompleteErr.length > 0 && <ErrorAlert data={tokenCompleteErr} open={true}/>}
                <WrapReSendLink>
                  <img src={warning} alt="" />
                  <ResendBtn onClick={requestToken}>
                    인증번호 재발송하기
                  </ResendBtn>
                </WrapReSendLink>
              </WrapWriteToken>
            )}
          </WrapRightItems>
        </>
      );
    };

  //------------ 컴포넌트 시작--------------
  return (
    <Section>
      <h1 className="ir">회원가입</h1>
      <SignupBox>
          <Title>회원 가입</Title>   
        <WrapContents> 
          <form action="post">
            {resultErr.length > 0 && <ErrorAlert data={resultErr} open={true}/>}
            {emailTokenResultErr.length > 0 && <ErrorAlert data={emailTokenResultErr} open={true}/>}
            <WrapTitle>
              <Message>Step01 담당자 정보</Message>
            </WrapTitle>
            {/* 이메일 종류 선택하기 */}
            {renderSelectEmail()}
            <InputAlign>
              <Label htmlFor="password"><EssetialLabel>*</EssetialLabel>비밀번호 </Label>
              <WrapRightItems>
                <InputValidateGroup
                  type="password"
                  id="password"
                  maxLength={25}
                  placeholder="한글, 영문, 특수문자를 포함한 8자~25자입니다."
                  value={password}
                  name="password"
                  autoComplete={"off"}
                  setValue={handleInputValues}
                  isKeyDown={handleCheckCapsLock}
                  style={{
                    border: !passwordVaildation ? `1px solid ${error3}` : null,
                  }}
                />
                {passwordErr.length > 0 && <ErrorLabel data={passwordErr}/>}
                {!passwordVaildation && password && (
                  <LabelWarning htmlFor="email">
                    영문/숫자/특문을 포함한 8자~25자를 입력해주세요.
                  </LabelWarning>
                )}
                {iscapslock && (
                  <LabelWarning>Caps Lock이 켜져있습니다!</LabelWarning>
                )}
              </WrapRightItems>
            </InputAlign>

            <InputAlign>
              <Label htmlFor="confirmPassword"><EssetialLabel>*</EssetialLabel>비밀번호 확인</Label>
              <WrapRightItems>
                <InputValidateGroup
                  type="password"
                  id="confirmPassword"
                  placeholder="비밀번호를 확인해주세요."
                  maxLength={25}
                  autoComplete={"off"}
                  value={confirmPassword}
                  name="confirmPassword"
                  setValue={handleInputValues}
                  isKeyDown={handleCheckCapsLock}
                  style={{
                    border: !conPasswdVaildation ? `1px solid ${error3}` : null,
                  }}
                />
                {itemErr.length > 0 && <ErrorLabel data={itemErr}/>}
                {confirmPasswordErr.length > 0 && <ErrorLabel data={confirmPasswordErr}/>}
                {!conPasswdVaildation && confirmPassword && (
                  <LabelWarning htmlFor="email">
                    비밀번호가 일치하지 않습니다.
                  </LabelWarning>
                )}
                {iscapslock && (
                  <LabelWarning>Caps Lock이 켜져있습니다!</LabelWarning>
                )}
              </WrapRightItems>
            </InputAlign>

            <InputAlign>
              <Label htmlFor="name"><EssetialLabel>*</EssetialLabel>이름</Label>
              <WrapRightItems>
                <InputValidateGroup
                  type="text"
                  id="name"
                  placeholder="본인 성명을 입력해주세요."
                  value={name}
                  name="name"
                  maxLength={100}
                  setValue={handleInputValues}
                />
                {nameErr.length > 0 && <ErrorLabel data={nameErr}/>}
              </WrapRightItems>
            </InputAlign>
            <InputAlign>
              <Label htmlFor="phone"><EssetialLabel>*</EssetialLabel>휴대폰 번호</Label>
              <WrapRightItems>
                <InputValidateGroup
                  type="text"
                  id="phone"
                  placeholder="휴대폰 번호를 입력하세요."
                  value={phoneWrite}
                  name="phone"
                  setValue={handleInputValues}
                />
                {phoneErr.length > 0 && <ErrorLabel data={phoneErr}/>}
              </WrapRightItems>
            </InputAlign>

            <InputAlign>
              <Label htmlFor="department"><EssetialLabel>*</EssetialLabel>부서</Label>
              <WrapRightItems>
                <InputValidateGroup
                  type="text"
                  id="department"
                  placeholder="소속 부서를 입력해주세요."
                  value={department}
                  name="department"
                  maxLength={100}
                  setValue={handleInputValues}
                />
                {departmentErr.length > 0 && <ErrorLabel data={departmentErr}/>}
              </WrapRightItems>
            </InputAlign>
            <InputAlign>
              <Label htmlFor="position"><EssetialLabel>*</EssetialLabel>직급</Label>
              <WrapRightItems>
                <InputValidateGroup
                  type="text"
                  id="position"
                  placeholder="현재 직급을 적어주세요"
                  value={position}
                  name="position"
                  maxLength={100}
                  setValue={handleInputValues}
                />
                {positionErr.length > 0 && <ErrorLabel data={positionErr}/>}
              </WrapRightItems>
            </InputAlign>
            <InputAlign last>
              <Label htmlFor="task">담당 업무</Label>
              <WrapRightItems>
                <InputValidateGroup
                  type="text"
                  id="task"
                  placeholder="담당업무를 입력해주세요."
                  value={task}
                  name="task"
                  maxLength={100}
                  setValue={handleInputValues}
                />
                {businessTaskErr.length > 0 && <ErrorLabel data={businessTaskErr}/>}
              </WrapRightItems>
            </InputAlign>


            {/* ----------- 2단계 ------------- */}
            <WrapTitle>
              <Message>Step02 회사 정보</Message>
            </WrapTitle>
            <InputAlign>
              <Label htmlFor="company"><EssetialLabel>*</EssetialLabel>회사명</Label>
              <WrapRightItems>
                <InputValidateGroup
                  type="text"
                  id="company"
                  placeholder="회사명을 입력해주세요."
                  value={company}
                  name="company"
                  setValue={handleInputValues}
                />
                {companyErr.length > 0 && <ErrorLabel data={companyErr}/>}
              </WrapRightItems>
            </InputAlign>
              {/* 사업자등록 번호 */}  
            <InputAlign>
              <Label htmlFor="businessNum"><EssetialLabel>*</EssetialLabel>사업자등록번호</Label>
              <WrapRightItems flex='true'>
                  <InputValidateGroup
                    type="text"
                    id="businessNum"
                    placeholder="사업자등록번호를 입력해주세요."
                    value={businessNum}
                    name="businessNum"
                    maxLength={10}
                    setValue={handleInputValues}
                    pattern="[0-9]*"
                    />
                  <ReigistImageBtn type="button" onClick={postBusinessNum}>
                    인증하기
                  </ReigistImageBtn>
              </WrapRightItems>
            </InputAlign>
            <InputAlign>
              <Label></Label>
              <WrapRightItems>
                {businessNumErr.length > 0 && <ErrorLabel data={businessNumErr}/>}
              </WrapRightItems>
            </InputAlign>
            <InputAlign>
              <Label htmlFor="registration"><EssetialLabel>*</EssetialLabel>사업자등록증</Label>
              <WrapRightItems flex='true'>
                <InputValidateGroup
                  type="text"
                  placeholder="사업자등록증을 업로드해주세요."
                  value={businessFile ? businessFile.name : ""}
                  name="registration"
                  readonly
                />
                <div style={{display:"none"}}>
                  <InputValidateGroup
                    type="file"
                    id="registration"
                    placeholder="jpg, png, pdf만 가능합니다."
                    name="registration"
                    accept=".jpg, .png, .pdf, .jpeg"
                    setValue={handleUploadImage}
                    refName={imageInputRef}
                  />
                </div>
                <ReigistImageBtn type="button" onClick={clickFileInput}>
                  등록하기
                </ReigistImageBtn>
                {!filePreview && 
                  <ReigistImageBtnA href="#none">
                    미리보기
                  </ReigistImageBtnA>
                }
                {filePreview && 
                  <ReigistImageBtnA href={filePreview} target="_blank" >
                    미리보기
                  </ReigistImageBtnA>
                }
              </WrapRightItems>
            </InputAlign>
            <InputAlign>
              <Label htmlFor="homepageName"><EssetialLabel>*</EssetialLabel>홈페이지 이름</Label>
              <WrapRightItems>
                <InputValidateGroup
                  type="text"
                  id="homepageName"
                  placeholder="회사 홈페이지 명을 입력해주세요."
                  value={homepageName}
                  name="homepageName"
                  setValue={handleInputValues}
                />
                {homepageNameErr.length > 0 && <ErrorLabel data={homepageNameErr}/>}
              </WrapRightItems>
            </InputAlign>
            <InputAlign last>
              <Label htmlFor="homepageLink"><EssetialLabel>*</EssetialLabel>홈페이지 URL</Label>
              <WrapRightItems>
                <InputValidateGroup
                  type="text"
                  id="homepageLink"
                  placeholder="https://를 포함해주세요."
                  value={homepageLink}
                  name="homepageLink"
                  setValue={handleInputValues}
                />
                {homepageLinkErr.length > 0 && <ErrorLabel data={homepageLinkErr}/>}
              {(!isUrl && homepageLink) && 
                <LabelWarning htmlFor="homepageLink">
                  유효하지 않는 url입니다.
                </LabelWarning>
              }
              </WrapRightItems>
            </InputAlign>
            {/* ----- 3단계 ----------*/}
            <WrapTitle>
              <Message>Step03 </Message>
            </WrapTitle>
            <InputAlign last>
              <Label htmlFor="companyKey"><EssetialLabel>*</EssetialLabel>가입 코드</Label>
              <WrapRightItems>
                <InputValidateGroup
                  type="text"
                  id="companyKey"
                  placeholder="가입 코드를 입력하세요."
                  value={companyKey}
                  name="companyKey"
                  setValue={handleInputValues}
                />
                {companyKeyErr.length > 0 && <ErrorLabel data={companyKeyErr}/>}
              </WrapRightItems>
            </InputAlign>
            {/* -------- 4단계-------- */}
            <WrapTitle>
              <Message>Step04 약관 동의하기</Message>
            </WrapTitle>
            <SignupAgreement setEssentialCheck={setEssentialCheck}  essentialCheck={essentialCheck}/>
            
            {/* 로그인 누르면 일시적 공백 채우는 div */}
            {isLoading && <Loading></Loading>}
            <div style={{marginTop: '40px'}}></div>
            {/* 회원가입 최종 버튼 */}
            {(!email ||
              !password ||
              !confirmPassword ||
              !name ||
              !phone ||
              !department ||
              !position ||
              !company ||
              !businessNum ||
              !isBsNumTrue ||
              !businessFile ||
              !isUrl ||
              !conPasswdVaildation ||
              !passwordVaildation ||
              !isTokenVerification ||
              !token ||
              !essentialCheck) && 
              <BeforeSignupButton type="submit">회원가입</BeforeSignupButton>
            }

            {email &&
              password &&
              confirmPassword &&
              name &&
              phone &&
              department &&
              position &&
              company &&
              businessNum &&
              isBsNumTrue &&
              businessFile &&
              isUrl &&
              conPasswdVaildation &&
              passwordVaildation &&
              isTokenVerification &&
              token &&
              essentialCheck &&
               (
              <SignupButton type="submit" requestRegister={requestRegister}>
                회원가입
              </SignupButton>
            )}
          </form>
        </WrapContents>
      </SignupBox>
      {/* alert */}
      {isAlertOpen && <AlertModal isErrorType={isErrorType}></AlertModal>}
      {/* alert */}
    </Section>
  );
}

const SubmitBlackDiv = styled.div`
  position: fixed;
  background: none;
    /* background: linear-gradient(0deg,rgba(0,0,0,0.5),rgba(0,0,0,0.5)); */
    z-index: 5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "Pretendard";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
`
const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: "Pretendard";
  background: ${MAIN_BACKGROUND_COLOR};
  padding: 60px 0;
`;

const WrapTitle = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 1px solid ${grey6};
`;

const Title = styled.h2`
  color: ${grey11};
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 24px;
  text-align: center;
`;

const Message = styled.p`
  color: ${grey9};
  font-size: 16px;
  font-weight: 600;
  display: block;
  width: fit-content;
  position: relative;
  z-index: 1;
  ::after {
    content: "";
    position: absolute;
    display: block;
    background: ${primary1};
    width: 100%;
    height: 9px;
    padding: 0 5px;
    left: -5px;
    bottom: -2px;
    opacity: 0.35;
    z-index: -1;
  }
`;

const WrapContents = styled.div`
  width: 520px;
  padding-top: 24px;
`;

const InputAlign = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.last ? "38px" : "12px")};
  margin-bottom: ${(props) => (props.agreement ? "24px" : null)};
`;

const SubInputAlign = styled.div`
  display: flex;
  width: 380px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Label = styled.label`
  /* width: 140px; */
  color: ${grey11};
  display: inline-block;
  width: 140px;
  font-size: 16px;
`;

const EssetialLabel = styled.span`
  color: ${error3};
`

const LabelWarning = styled.span`
  display: block;
  color: ${error3};
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: ${(props) => (props.email ? "8px" : "0")};
`;

const WrapRightItems = styled.div`
  width: 380px;
  display: ${(props) => (props.flex ? "flex" : null)};
  align-items: ${(props) => (props.flex ? "center" : null)};
  justify-content: ${(props) => (props.flex ? "center" : null)};
  gap: ${(props) => (props.flex ? "12px" : null)};
  margin: ${(props) => (props.first ? "0 0 12px 140px" : "0")};
`;

const WrapWriteToken = styled.div`
  background: #f0f0f0;
  padding: 16px;
  margin-top: 12px;
`;
const TokenMsg = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;

const WrapReSendLink = styled.div`
  display: flex;
  align-items: center;
`;

const ResendBtn = styled.button`
  font-size: 14px;
  color: #434343;
`;

const TimeSpan = styled.span`
  color: ${error3};
  position: absolute;
  display: block;
  right: 100px;
  top: 12px;
  font-size: 14px;
`;

const ItemBtn = styled.button`
  width: 100%;
  padding: 12px 0;

  &:hover {
    color: ${primary4};
    font-weight : 700;
  }
`

const FlexStepBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: ${(props) => (props.last ? "40px 0 0" : "0")};
`

const ReigistImageBtn = styled.button`
  width: 120px;
  border-radius: 4px;
  background: ${grey1};
  color: ${primary4};
  font-size: 14px;
  border: 1px solid ${primary4};
  padding: 8px;
  box-sizing: border-box;
  line-height: 1;
`
const ReigistImageBtnA = styled.a`
  width: 120px;
  border-radius: 4px;
  background: ${grey1};
  padding: 8px;
  color: ${error2};
  font-size: 14px;
  border: 1px solid ${error2};
  text-align: center;
  box-sizing: border-box;
  line-height: 1;
`