import styled from "styled-components";
import FindMemberBox from "../../components/containers/auth/FindMemberBox";
import {
  grey9,
  grey5,
  primary4,
  error3,
  MAIN_BACKGROUND_COLOR,
} from "../../constants/color";
import { useEffect, useState } from "react";
import {
  ActiveSetNewasswordButton,
  InactiveSetNewPasswordButton,
} from "../../components/buttons/FindMemberButtons";
import { instanceAxios } from "../../api/axios";
import { useLocation, useNavigate } from "react-router-dom";
import SetPasswordBox from "../../components/containers/auth/SetPasswordBox";
import { useRecoilState } from "recoil";
import { AlertMessage, IsAlertOpen } from "../../atom/Atom";
import AlertModal from "../../components/modals/AlertModal";
import ErrorAlert from "../../error/ErrorAlert";
import ErrorLabel from "../../error/ErrorLabel";


export default function SetNewPassword() {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [passwordVaildation, setPasswordVaildation] = useState(true);
  const [conPasswdVaildation, setConPasswdVaildation] = useState(true);
  const [expiredToken, setExpiredToken] = useState(false);
  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);
  // error Type
  const [isErrorType, setIsErrorType] = useState(null);
  const commonErrorPopup = (msg, type) => {
    setIsAlertOpen(true);
    setAlertMessage(msg);
    // 함수는 () => 함수이름
    // 바로 작동하면 안됨
    setIsErrorType(type);
  };


  const [inputs, setInputs] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const location = window.location;
    if (location.search) {
      setToken(location.search.split("=")[1].split("&")[0]);
      setEmail(location.search.split("=")[2]);
    }
  }, []);

  const { newPassword, confirmPassword } = inputs;

  const handleInputValues = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "newPassword") {
      let re = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
      setPasswordVaildation(re.test(e.target.value));
      // if (e.target.value === inputs.confirmPassword) {
      //   setConPasswdVaildation(true);
      // } else {
      //   setConPasswdVaildation(false);
      // }
      // 영문 숫자 특수문자 1개씩 +  8-25글자 정규식
      if (conPasswdVaildation) {
        // setConPasswdVaildation(false);
      }
    } else if (e.target.name === "confirmPassword") {
      if (e.target.value === inputs.newPassword) {
        setConPasswdVaildation(true);
      } else {
        setConPasswdVaildation(false);
      }
    }
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const data = {
    email: email,
    newPassword:newPassword,
    confirmPassword: confirmPassword,
    token: token,
  };

  const [pwdErr, setPwdErr] = useState([]);
  const [checkPwdErr, setCheckPwdErr] = useState([]);
  const [emailErr, setEmailErr] = useState([]);
  const [resultErr, setResultErr] = useState([]);
  const [tokenErr, setTokenErr] = useState([]);

  const requesetNewPassword = async (e) => {
    e.preventDefault();
    setPwdErr([]);
    setCheckPwdErr([]);
    setEmailErr([]);
    setTokenErr([]);
    setResultErr([]);
    try {      
      const response = await instanceAxios.post("/auth/password/reset", data);
      if (response.status === 200) { 
        setIsAlertOpen(true);
        setAlertMessage(response.data.data.msg);
        navigate('/');
      }
    } catch (err) {
      const errData = err.response.data.data;
      if(errData.result) setResultErr(errData.result);
      if(errData.newPassword) setPwdErr(errData.newPassword);
      if(errData.item) setCheckPwdErr(errData.item);
      if(errData.confirmPassword) setCheckPwdErr(errData.confirmPassword);
      if(errData.email) setEmailErr(errData.email);
      if(errData.token) setTokenErr(errData.token);
    }
  };

  return (
    <Section>
      <h1 className="ir">새 비밀번호 설정 페이지</h1>
      {(isAlertOpen) && <AlertModal isErrorType={isErrorType}></AlertModal>}
      {resultErr.length > 0 && <ErrorAlert data={resultErr} open={true}/>}
      {tokenErr.length > 0 && <ErrorAlert data={tokenErr} open={true}/>}
      <SetPasswordBox>
        <Title>새 비밀번호 설정</Title>
        <SubMessage>새로운 비밀번호를 설정해주세요.</SubMessage>
        {emailErr.length > 0 && <ErrorLabel data={emailErr}/>}
        <FormStyle action="post" onSubmit={requesetNewPassword}>
          <div>
            <InputStyle
              onChange={handleInputValues}
              value={newPassword}
              type="password"
              name="newPassword"
              placeholder="새 비밀번호"
              maxLength={25}
            />
            {pwdErr.length > 0 && <ErrorLabel data={pwdErr}/>}
            {(!passwordVaildation && newPassword && pwdErr.length === 0) && (
              <LabelWarning htmlFor="email">
                영문/숫자/특문을 포함한 8자~25자를 입력해주세요.
              </LabelWarning>
            )}
          </div>
          <div>
            <InputStyle
              onChange={handleInputValues}
              value={confirmPassword}
              type="password"
              name="confirmPassword"
              placeholder="새 비밀번호 확인"
              maxLength={25}
            />
            {checkPwdErr.length > 0 && <ErrorLabel data={checkPwdErr}/>}
            {(!conPasswdVaildation && confirmPassword && checkPwdErr.length === 0) && (
              <LabelWarning htmlFor="email">
                비밀번호가 일치하지 않습니다.
              </LabelWarning>
            )}
          </div>
          {(!newPassword ||
            !confirmPassword ||
            !conPasswdVaildation ||
            !passwordVaildation) && (
            <InactiveSetNewPasswordButton>
              비밀번호 변경하기
            </InactiveSetNewPasswordButton>
          )}
          {newPassword &&
            confirmPassword &&
            conPasswdVaildation &&
            passwordVaildation && (
              <ActiveSetNewasswordButton>
                비밀번호 변경하기
              </ActiveSetNewasswordButton>
            )}
        </FormStyle>
      </SetPasswordBox>
      {/* alert */}
    </Section>
  );
}

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: "Pretendard";
  background: ${MAIN_BACKGROUND_COLOR};
`;

const Title = styled.h2`
  font-size: 32px;
  text-align: center;
  margin-bottom: 12px;
`;

const WarningMessage = styled.p`
  color: ${error3};
  padding-top: 15px;
  text-align: start;
`;
const SubMessage = styled.p`
  color: ${grey9};
  text-align: center;
`;

const FormStyle = styled.form`
  margin-top: 43px;
  width: 437px;
`;

const LabelWarning = styled.p`
  color: ${error3};
  margin-top: 5px;
  font-size: 14px;
`;
const InputStyle = styled.input`
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  margin: 16px 0 0;
  box-sizing: border-box;
  border: 1px solid ${grey5};

  &:focus {
    border: 1px solid ${primary4};
  }
`;