import React from 'react'
import styled from "styled-components";
import { grey1, grey10, primary2, primary3 } from '../../../constants/color';
import Graph2 from "../../../assets/images/graph2.png"
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ErrorLabel from '../../../error/ErrorLabel';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);


export default function PushGraphBox({response, startErr, endErr}) {
  const labels = response.map(ele => ele.title);
  const data = {
    labels,
    datasets: [
      {
        type: 'line',
        label: '발송 성공',
        borderColor: 'rgb(255, 99, 99)',
        borderWidth: 2,
        fill: false,
        data: response.map(ele => ele.count),
      },
      {
        type: 'bar',
        label: '총 발송량',
        backgroundColor: 'rgb(148, 228, 211)',
        data: response.map(ele => ele.total_count),
        borderColor: 'white',
        borderWidth: 2,
      }
    ],
  };
  return (
    <ProceedingSection>
    <BottomH3>PUSH 발송량</BottomH3>
    {startErr.length > 0 && <ErrorLabel data={startErr}/>}  
    {endErr.length > 0 && <ErrorLabel data={endErr}/>}  
    <GraphBox>
      <Chart type='bar' data={data} style={{width: "800px", margin:"0 auto"}}/>
    </GraphBox>
  </ProceedingSection>
  )
}

const ProceedingSection = styled.section`
  width: calc(100%);
  box-sizing: border-box;
`

const BottomH3 = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${grey10};
  padding: 0 0 16px;
  position: relative;
  width: fit-content;
  display: block;
  
  ::after {
    display: block;
    position: absolute;
    content: '';
    left: -5px;
    top: 10px;
    background: ${primary2};
    height: 10px;
    width: 100%;
    opacity: 0.4;
    padding: 0 5px;
    z-index: -1;
  }
`

const GraphBox = styled.article`
  background: ${grey1};
  box-shadow: 4px 4px 14px rgba(0,0,0,0.16);
  padding: 10px;
  border-radius: 16px;
`
