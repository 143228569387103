import { instanceAxios } from "./axios";
import { useParams } from "react-router-dom";
// 1. 메세지 리스트 호출 (PushList)
export  const getPushList = async (myPushProject, setPushList) => {
  if (!myPushProject.pid) return;
  try {
    const response = await instanceAxios.get(`/${myPushProject.pid}/all`, {});
    if (response.status === 200) {
      setPushList(response.data);
    }
  } catch (err) {
    console.error(err);
  }
};


// 2. 메세지 삭제 (PushList)
export const handleDelete = async (mid, myPushProject, setIsAlertOpen, setAlertMessage, setPushList) => {
  if (window.confirm("push 메세지를 삭제하시겠습니까?")) {
    try {
      const response = await instanceAxios.delete(
        `/${myPushProject.pid}/${mid}`
      );
      if (response.status === 200) {
        setIsAlertOpen(true);
        setAlertMessage("삭제되었습니다.");
        getPushList(myPushProject, setPushList);
      }
    } catch (err) {
      console.error(err);
    }
  }
};

// 3. 메세지 등록 (MakePush)
export const submitMakePush = async(myPushProject,formData, setIsAlertOpen, setAlertMessage,setIsLoading,isLoading,navigate) => {
  try {
    const response = await instanceAxios.post(
      `/${myPushProject.pid}/add`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      );
      if (response.status === 200) {
        setIsAlertOpen(true);
        setAlertMessage("메세지가 작성되었습니다.");
        setIsLoading(false);
        if(!isLoading) {
      navigate("/pushList");
    }
  }
} catch (err) {
  setIsLoading(false);
  console.error(err);
}
}


// 4. 메세지 상세 정보 호출 (PushDetail)
export const getDetailMsg = async(myPushProject, mid,setIsLoading, setMsgDetail) => {
  if(myPushProject === undefined) return;
  try {
    const response = await instanceAxios.get(
      `/${myPushProject.pid}/${mid}`
      );
      if (response.status === 200) {
        setMsgDetail(response.data);
  }
} catch (err) {
  setIsLoading(false);
  console.error(err);
}
}


// 5. 메세지 수정 PushDetail)
export const editPush = async (myPushProject,mid,setIsLoading,formData) => {
  try {
    const response = await instanceAxios.put(
      `/${myPushProject.pid}/${mid}`, formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      );
      if (response.status === 200) {
  }
  }catch (err) {
    setIsLoading(false);
    console.error(err);
  }
}