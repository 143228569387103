import styled from "styled-components";
import { HomepageBox } from "../../components/containers/homepage/HomepageBox";
import Layout from "../../templates/Layout";
import { DropboxInput, InputGroup } from "../../components/inputs/InputGroups";
import UpdateProfile from "../../components/buttons/ProfileButtons";
import { instanceAxios } from "../../api/axios";
import { useEffect, useState } from "react";
import HompageButton, {
  DeleteHomepage,
  DeleteSelectedHomepage,
  SelectExpiredHomepage,
  SelectedExpiredHomepage,
} from "../../components/buttons/HompageButtons";
import { grey1, grey4, primary4, error3, primary1, primary2 } from "../../constants/color";
import {
  SelectedHomepage,
  SelectHomepage,
  BeforeUpdateHomepage,
  AfterUpdateHomepage,
} from "../../components/buttons/HompageButtons";
import { useRecoilState } from "recoil";
import { CategoryDropbox, CategoryDropbox1, CategoryDropbox2 } from "../../components/dropbox/dropbox";
import {
  AlertMessage,
  IsAlertOpen,
  MyProject,
  MyPushProject,
  MyCategory,
} from "../../atom/Atom";
import arrow from "../../assets/images/ico-arrow-small.png"
import ErrorLabel from "../../error/ErrorLabel";
import ErrorAlert from "../../error/ErrorAlert";

export default function Homepage() {
  const [isOpenDrop1, setIsOpenDrop1] = useState(false);
  const [isOpenDrop2, setIsOpenDrop2] = useState(false);
  const [myProject, setMyProject] = useRecoilState(MyProject);
  const [myCategory, setMyCategory] = useRecoilState(MyCategory);
  const [scdCatList, setScdCatList] = useState([]);
  const [myPushProject, setMyPushProject] = useRecoilState(MyPushProject);
  const [homepage, setHomepage] = useState(myPushProject.name);
  const [pid, setPid] = useState(myPushProject.pid);
  const [link, setLink] = useState(myPushProject.projectUrl);
  const [finalCat, setFianlCat] = useState(myPushProject.categoryCode);
  const [firstCat, setFirstCat] = useState("");
  const [scdCat, setScdCat] = useState("");
  const [firstCatCode, setFirstCatCode] = useState("");

  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);
  // error Type
  const [isErrorType, setIsErrorType] = useState(null);
  // error func
  const commonErrorPopup = (msg, type) => {
    setIsAlertOpen(true);
    setAlertMessage(msg);
    // 함수는 () => 함수이름
    // 바로 작동하면 안됨
    setIsErrorType(type);
  };
  // 처음 카테고리 정보 가져오기
  const getCategoryInfo = async () => {
    try {
      const response = await instanceAxios.get(`/category/${myPushProject.categoryCode}`);
      if(response.status === 200) {
        const responseData = response.data.data;
        if(!responseData.parentCode) {
          setScdCat("");
          setFirstCat(responseData.name);
        } else {
          const parentCatArr = myCategory.filter(ele => ele.code === responseData.parentCode);
          setFirstCatCode(parentCatArr[0].code);
          setFirstCat(parentCatArr[0].name);
          setScdCat(responseData.name);

        }
      } else if(response.status === 204) {
        setScdCat("");
        setFirstCat("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  // 1차 선택 
  const getSecondCategory = async(code) => {
    try {
      const response = await instanceAxios.get(`/category/list/${code}` ,{
        headers: {
          'Cache-Control': 'max-age=1000'
        }
      });
      setScdCatList(response.data.data);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if(!firstCatCode) return;
    if(firstCatCode) getSecondCategory(firstCatCode);
  }, [firstCatCode])
  
  // --- 선택된 프로젝트 정보 ---
  const getOneHomepage = async () => {
    try {
      const response = await instanceAxios.get(`/${pid}`);
      if (response.status === 200) {
        setMyPushProject(response.data);
        setHomepage(response.data.name);
        setLink(response.data.projectUrl.includes("https://") ? response.data.projectUrl : "https://" + response.data.projectUrl);
      }
    } catch (err) {
      console.error(err);
    }
  };
  
  useEffect(() => {
    if (pid) {
      getOneHomepage();
    }
  }, [pid]);

  useEffect(() => {
    if (myPushProject.categoryCode) {
      getCategoryInfo();
    }
  }, [myPushProject.categoryCode]);

  const updateData = {
    code: finalCat,
    name: homepage,
    projectUrl: link,
  };
  
  const [resultErr, setResultErr] = useState([]);
  const [nameErr, setNameErr] = useState([]);
  const [linkErr, setLinkErr] = useState([]);
  const [catErr, setCatErr] = useState([]);
  const errReset = () => {
    setResultErr([]);
    setNameErr([]);
    setLinkErr([]);
    setCatErr([]);
  };

  // --- 홈페이지 정보 수정 ---
  const updateHomePage = async (e) => {
    e.preventDefault();
    errReset();
    if(homepage.length <= 0) {
      commonErrorPopup("홈페이지 이름을 적어주세요.", null)
      return;
    } else if(!link.includes('https://')) {
      commonErrorPopup("홈페이지 주소에는 'https://'가 필요합니다.", null);
      return;
    }  else if(!finalCat) {
      commonErrorPopup("카테고리를 선택해주세요.", null);
      return;
    }

    if (window.confirm("홈페이지 정보를 수정하시겠습니까?")) {
      try {
        const response = await instanceAxios.put(`/${myPushProject.pid}`,
          updateData
        );
        if (response.status === 200) {
          setIsAlertOpen(true);
          setAlertMessage(response.data.data.msg);
        } 
      } catch (err) {
        const ErrData = err.response.data.data;
        if(ErrData.result) setResultErr(ErrData.result);
        if(ErrData.name) setNameErr(ErrData.name);
        if(ErrData.projectUrl) setLinkErr(ErrData.projectUrl);
        if(ErrData.code) setCatErr(ErrData.code);
      }
    }
  };

  // --- 홈페이지 정보 삭제 ---
  const deleteHomePage = async (e) => {
    e.preventDefault();
    if (window.confirm("홈페이지를 삭제하시겠습니까?")) {
      try {
        const response = await instanceAxios.delete(
          `/${myPushProject.pid}`
        );
        if (response.status === 200) {
          setIsAlertOpen(true);
          setAlertMessage("홈페이지가 삭제되었습니다.");
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const renderSubmitButton = () => {
    if (
      myPushProject.projectUrl === link &&
      myPushProject.name === homepage &&
      myPushProject.categoryCode === finalCat
    ) {
      return <BeforeUpdateHomepage>수정</BeforeUpdateHomepage>;
    } else {
      return (
        <AfterUpdateHomepage updateHomePage={updateHomePage}>
          수정
        </AfterUpdateHomepage>
      );
    }
  };

  // --- 만든 홈페이지 리스트 ---
  const renderHomepageList = () => {
    return (
      <>
        {myProject?.map(({ name, pid, expiryDate }) => {
          if (pid === myPushProject.pid) {
            if(expiryDate) {
              return (
                <li key={pid}>
                  <SelectedExpiredHomepage
                    setValue={() => {
                      setPid(pid);
                    }}
                  >
                    {name}
                  </SelectedExpiredHomepage>
                </li>
              );
            } else {
              return (
                <li key={pid}>
                  <SelectedHomepage
                    setValue={() => {
                      setPid(pid);
                    }}
                  >
                    {name}
                  </SelectedHomepage>
                </li>
              );
            }
          } else {
            if(expiryDate) {
              return (
                <li key={pid}>
                  <SelectExpiredHomepage
                    setValue={() => {
                      setPid(pid);
                    }}
                  >
                    {name}
                  </SelectExpiredHomepage>
                </li>
              );
            } else {
              return (
                <li key={pid}>
                  <SelectHomepage
                    setValue={() => {
                      setPid(pid);
                    }}
                  >
                    {name}
                  </SelectHomepage>
                </li>
              );
            }
          }
        })}
      </>
    );
  };

  const handleClickDrop1 = (e) => {
    isOpenDrop1 ? setIsOpenDrop1(false) : setIsOpenDrop1(true);
    setIsOpenDrop2(false);
    setScdCat('');
    setFianlCat('');
  };
  const handleClickDrop2 = (e) => {
    if(scdCatList.length <= 0) return; // 2차가 없을 때
    isOpenDrop2 ? setIsOpenDrop2(false) : setIsOpenDrop2(true);
    setIsOpenDrop1(false);
  };
  
  const clickFirstCatDrop1 = (e) => {
    e.preventDefault();
    setIsOpenDrop1(false);
    // 2차 목록 불러오기
    getSecondCategory(e.target.id);
    setFirstCat(e.target.value);
    setFianlCat(e.target.id);
    
  };
  const clickFirstCatDrop2 = (e) => {
    e.preventDefault();
    setIsOpenDrop2(false);
    setScdCat(e.target.value);
    setFianlCat(e.target.id);
  };

  return (
    <Layout>
      <HomepageBox>
        {resultErr.length > 0 && <ErrorAlert data={resultErr} open={true}/>}
        <TopAlign>
          <WrapHomepages>{renderHomepageList()}</WrapHomepages>
          {/* {myPushProject.expiryDate ? (
            <p>{myPushProject.expiryDate.slice(0, 10)}에 삭제 예정입니다</p>
          ) : (
            <DeleteBtn onClick={deleteHomePage}>삭제하기</DeleteBtn>
          )} */}
        </TopAlign>
        <form action="post">
          <WrapInputs>
            <LabelStyle htmlFor="homepage">홈페이지 이름</LabelStyle>
            <div>
              <InputGroup
                type="text"
                value={homepage}
                id="homepage"
                setValue={setHomepage}
              />
              {nameErr.length > 0 && <ErrorLabel data={nameErr}/>}
            </div>
          </WrapInputs>
          <WrapInputs>
            <LabelStyle htmlFor="link">홈페이지 주소</LabelStyle>
            <div>
              <InputGroup
                type="text"
                value={link}
                id="link"
                readonly
                setValue={setLink}
              />
              {linkErr.length > 0 && <ErrorLabel data={linkErr}/>}
              <ConfirmMsg>수정을 희망할 경우, 담당자에게 연락해주세요.</ConfirmMsg>
            </div>
          </WrapInputs>
          <WrapInputs style={{alignItems: 'center', width: '780px'}}>
            <LabelStyle htmlFor="category">카테고리</LabelStyle>
              <div style={{display: 'flex', gap: '10px'}}>
                <div  style={{position:"relative"}}>
                  <CateogryClickInput>
                    <DropboxInput
                    type="text"
                    value={firstCat}
                    id="category01"
                    readOnly={true}
                    placeholder={'1차 카테고리'}
                    handleClick={handleClickDrop1}
                    />
                    {isOpenDrop1 &&
                    <CategoryInputArrow up src={arrow} alt="" />              
                    }
                    {!isOpenDrop1 &&
                      <CategoryInputArrow src={arrow} alt="" />              
                    }
                  </CateogryClickInput>
                  {/* 카테고리 드롭박스 */}
                  {isOpenDrop1 && (
                    <CategoryDropbox
                      arrList={myCategory}
                      ver="42px"
                      hor="0"
                      width="206px"
                      handleClick={clickFirstCatDrop1}
                    />
                  )}
                </div>
                <div style={{position:"relative"}}>
                  <CateogryClickInput>
                    <DropboxInput
                    type="text"
                    value={scdCat}
                    id="category02"
                    readOnly={true}
                    placeholder={'2차 카테고리'}
                    handleClick={handleClickDrop2}
                    />
                    {isOpenDrop2 &&
                    <CategoryInputArrow up src={arrow} alt="" />              
                    }
                    {!isOpenDrop2 &&
                      <CategoryInputArrow src={arrow} alt="" />              
                    }
                  </CateogryClickInput>
                  {/* 카테고리 드롭박스 */}
                  {isOpenDrop2 && (
                    <CategoryDropbox
                      arrList={scdCatList}
                      ver="42px"
                      hor="0px"
                      width="206px"
                      handleClick={clickFirstCatDrop2}
                    />
                  )}
                </div>
                {catErr.length > 0 && <ErrorLabel data={catErr}/>}
              </div>
          </WrapInputs>
          {myPushProject.expiryDate ? null : (
            <WrapButton>{renderSubmitButton()}</WrapButton>
          )}
        </form>
      </HomepageBox>
    </Layout>
  );
}



const WrapInputs = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 60px;
`;

const LabelStyle = styled.label`
  display: flex;
  width: 180px;
`;
const WrapButton = styled.div`
  width: 180px;
  margin: 80px auto 20px;
`;

const TopAlign = styled.ul`
  display: flex;
  gap: 10px;
  position: relative;
  margin-bottom: 40px;
  align-items: center;
  justify-content: space-between;

  ::after {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 2px;
    background-color: ${grey4};
    bottom: -20px;
    left: 0;
  }
`;

const WrapHomepages = styled.ul`
  display: flex;
  gap: 10px;
  position: relative;
  align-items: center;
`;

const DeleteBtn = styled.button`
  color: ${error3};
  font-weight: 600;
`;

const CateogryClickInput = styled.div`
  display: flex;
  position: relative;
`

const CategoryInputArrow = styled.img`
  width: 10px;
  height: 10px;
  position: absolute;
  right: 13px;
  top: 15px;

  rotate: ${props => props.up ? "180deg" : null};
`

const ConfirmMsg = styled.p`
  font-size: 12px;
  color: ${error3};
  position: absolute;
  left: 180px;
  bottom: -20px;
`
