import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Reset } from 'styled-reset'
import { ThemeProvider } from "@mui/material/styles";
import { muiColorTheme } from './constants/color';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider  theme={muiColorTheme}>
      <Reset />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);


