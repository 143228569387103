import styled from "styled-components";
import { grey1, grey4, grey5 } from "../../../constants/color";

const Box = styled.div`
  padding: 32px;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid ${grey4};
  /* box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1); */
  border-radius: 16px;
  background: ${grey1};
`;
const DemoShowBox = styled.div`
  width: 100%;
  margin-left: 0;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.06);
  /* display: flex; */
  /* gap: 20px; */
  /* padding: 32px; */
  border-radius: ${props => props.pc ? "0" : "16px"};
  border: 1px solid ${grey4};
  background: ${grey1};
`;

export function DemoWrapBox({ children }) {
  return <Box>{children}</Box>;
}
export function DemoBoxWeb({ children }) {
  return <DemoShowBox pc>{children}</DemoShowBox>;
}
export function DemoBoxApp({ children }) {
  return <DemoShowBox>{children}</DemoShowBox>;
}
