import styled from "styled-components";
import "../../styles/rullet.css"
import Layout from "../../templates/Layout";
import { DropboxInput } from "../../components/inputs/InputGroups";
import { instanceAxios } from "../../api/axios";
import { useEffect, useRef, useState } from "react";
import {
  grey1,
  grey4,
  grey3,
  grey8,
  primary3,
  grey7,
  primary2,
  grey6,
  grey9,
  primary5,
  error1,
} from "../../constants/color";
import {
  SelectedHomepage,
  SelectHomepage,
  AfterCopy,
  BeforeCopy,
} from "../../components/buttons/HompageButtons";
import { useRecoilState } from "recoil";
import {
  AlertMessage,
  IsAlertOpen,
  MyProject,
  MyPushProject,
} from "../../atom/Atom";
import eventImg1 from '../../assets/images/img-event01.png';
import eventImg2 from '../../assets/images/img-event02.png';
import eventImg3 from '../../assets/images/img-event03.png';
import eventImg4 from '../../assets/images/img-event04.png';
import eventImg5 from '../../assets/images/img-event05.png';
import dropArrow from "../../assets/images/ico-arrow-small.png"
import reulloetArrow from '../../assets/images/rullet-arrow.png';
import successRullet from '../../assets/images/img-success-rullet.png';
import { TemplateDropbox } from "../../components/dropbox/dropbox";
import downCloud from "../../assets/images/img-down.svg";




export default function InsertPush() {
  const [myProject, setMyProject] = useRecoilState(MyProject);
  const [myPushProject, setMyPushProject] = useRecoilState(MyPushProject);
  const projectUrl = myPushProject.projectUrl;
  const [pid, setPid] = useState(myPushProject.pid);
  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);

  useEffect(() => {
    const getOneHomepage = async () => {
      try {
        const response = await instanceAxios.get(`/${pid}`);
        if (response.status === 200) {
          setMyPushProject(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (pid) {
      getOneHomepage();
    }
  }, [pid]);
  
  // script
  const [copyScript, setCopyScript] = useState("");  
  const getBasicScript = async () => {
    // setCopyScript("베이직 타입")
    try {
      const response = await instanceAxios.get(`/${pid}/resource/basic`);
      if (response.status === 200) {
        setCopyScript(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getModalScript = async () => {
    // setCopyScript("베이직 타입")
    try {
      const response = await instanceAxios.get(`/${pid}/resource/modal`);
      if (response.status === 200) {
        setCopyScript(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getEventScript = async () => {
    // setCopyScript("event 타입")
    try {
      const response = await instanceAxios.get(`/${pid}/resource/event`);
      if (response.status === 200) {

        setCopyScript(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getBannerScript = async () => {
    // setCopyScript("배너 타입")
    try {
      const response = await instanceAxios.get(`/${pid}/resource/banner`);
      if (response.status === 200) {

        setCopyScript(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getShortcutScript = async () => {
    // setCopyScript("배너 타입")
    try {
      const response = await instanceAxios.get(`/${pid}/resource/shortcut`);
      if (response.status === 200) {

        setCopyScript(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {  
    // 처음 로딩되면 basic만 불러오기
    // pid가 바뀌면 basic 다시 부르고 타입은 1로 리셋
    // 다른 타입은 드롭박스 클릭할 때 변경 handleType()
    if (pid) {
      getBannerScript();
      setTypeCode("1");
    }
  }, [pid]);

  const arrList = [
   {name: "기본 타입", code: "1"},
   {name: "이벤트 타입", code: "2"},
   {name: "모달 타입", code: "3"},
  //  {name: "로고 타입", code: "4"},
   {name: "바로가기 타입", code: "5"},
 ];
 const [type, setType] = useState(arrList[0].name);
 const [typeCode, setTypeCode] = useState(arrList[0].code);
 const [openTypeDrop, setOpenTypeDrop] = useState(false);
   // -------type 관리-------
  const handleType = (e) => {
    setType(e.target.value);
    setTypeCode(e.target.id)
    setOpenTypeDrop(false);

    if(e.target.id === "1") getBannerScript();
    if(e.target.id === "2") getEventScript();
    if(e.target.id === "3") getModalScript();
    if(e.target.id === "5") getShortcutScript();
  }

  const handleCopyScript = (text) => {
    try {
      navigator.clipboard.writeText(text);
      setIsAlertOpen(true);
      setAlertMessage("클립보드에 복사되었습니다.");
    } catch (error) {
      setIsAlertOpen(true);
      setAlertMessage("복사에 실패하였습니다. 다시 시도해주세요.");
    }
  };

  const renderProjectBtns = () => {
    return (
      <>
        {myProject?.map(({ name, pid }) => {
          if (pid === myPushProject.pid) {
            return (
              <li key={pid}>
                <SelectedHomepage
                  setValue={() => {
                    setPid(pid);
                  }}
                >
                  {name}
                </SelectedHomepage>
              </li>
            );
          } else {
            return (
              <li key={pid}>
                <SelectHomepage
                  setValue={() => {
                    setPid(pid);
                  }}
                >
                  {name}
                </SelectHomepage>
              </li>
            );
          }
        })}
      </>
    );
  };

 

  // -------- 사이트 적용 모달 ----------
  const rotateRullet = (endDeg) => {
    let rolLength = 2;
    let panel = document.querySelector(".rouletter-wacu");
    let deg = [];
    for (let i = 1, len = rolLength; i <= len; i++) {
      deg.push((360 / len) * i);
    }

    let baseDeg = 3600;
    let stopDeg = endDeg; // 나중에는 데이터에서 주는 값을 써야 함
    panel.animate(
      [
        { transform: "rotate(0deg)" },
        { transform: "rotate(" + stopDeg + "deg)" },
      ],
      {
        fill: "forwards",
        duration: 1500,
        easing: "ease-out",
      }
    );

    const $rulletAll = document.querySelector("#rullet-all");
    const $rulletLayout = document.querySelector(".rouletter");
    setTimeout(() => {
      $rulletAll.style.display = "none";
      $rulletLayout.innerHTML = `
      <div class="wrap-result">
        <img class="fail-img" src=${successRullet} alt="이미 참여한 이벤트입니다."/>
      </div>
      `;
    }, 2500)
  };

  const [siteModal, setSiteModal] = useState(false);
  const iframeRef = useRef();
  const clickAdjacentSite = () => {
    return (
      <SiteDemoModal>
        <WrapIframe>
          <article className="section-rullet">
            <p className="rullet-title">😎 팝업 이벤트를 경험해보세요!</p>
            <div id="event-rullet" className="event-rullet">
              <div className="rouletter">
                <div id="rullet-all">
                  <div className="rouletter-bg">
                    <div className="rouletter-wacu"></div>
                  </div>
                  <div className="rouletter-arrow">
                    <img src={reulloetArrow} alt="" />
                  </div>
                  <button className="rouletter-btn" onClick={() => rotateRullet(3000)}>START</button>
                </div>
              </div>
            </div>
          </article>
          <CloseIframeBtn onClick={(e) => {e.stopPropagation(); setSiteModal(false)}}>참여 그만두기</CloseIframeBtn>
        </WrapIframe>
      </SiteDemoModal>
    )
  }
 
  return (
    <Layout>
      {siteModal && clickAdjacentSite()}
      <PageWrapper>
        <DashbaordH2>스크립트 설치 안내</DashbaordH2>
        <Message>스크립트 설치를 안내해드립니다.</Message>
        <TopAlign>
          <WrapHomepages>{renderProjectBtns()}</WrapHomepages>
        </TopAlign>
        <DemoArticle>
          <form action="">
            <WrapInputDiv>
              <Label htmlFor="">1. 홈페이지 명</Label>
              <Input type="text" readOnly value={myPushProject.name}/>
            </WrapInputDiv>
            <WrapInputDiv>
              <Label htmlFor="">2. 설치 도메인</Label>
              <Input type="text" readOnly value={myPushProject.projectUrl.includes("https://") ? myPushProject.projectUrl : "https://" +  myPushProject.projectUrl}/>
            </WrapInputDiv>
            <WrapInputDiv>
              <Label htmlFor="">3. 홈페이지 ID</Label>
              <Input type="text" readOnly value={myPushProject.pid}/>
            </WrapInputDiv>
            <WrapInputDiv>
              <Label htmlFor="">4. 푸시수신요청 팝업형태 선택</Label>
              <TypeClickInput>
              <DropboxInput
              type="text"
              value={type}
              id="type"
              readOnly={true}
              placeholder={'템플릿 타입'}
              handleClick={() => {setOpenTypeDrop(openTypeDrop ? false : true)}}
              />
              {openTypeDrop &&
              <CategoryInputArrow up src={dropArrow} alt="" />              
              }
              {!openTypeDrop &&
                <CategoryInputArrow src={dropArrow} alt="" />              
              }
            </TypeClickInput>
            {openTypeDrop && 
              <TemplateDropbox
                arrList={arrList} 
                ver="42px"
                hor="278px"
                width="208px"
                handleClick={handleType}
              />
            }
            </WrapInputDiv>
            <WrapInputDiv2>
              <Label htmlFor="">5. 팝업 데모</Label>
              {typeCode === "1" && 
                <WrapDemoImg>
                  <StandardP>* 홈페이지 상단에 고정됩니다.</StandardP>
                  <DemoSiteImg src={eventImg1} alt="배너 타입 팝업 이미지" style={{width: "680px", borderRadius: 0}}/>
                </WrapDemoImg>
              }
              {typeCode === "2" && 
                <WrapDemoImg>
                  <StandardP>* 사진을 클릭해보세요</StandardP>
                  <StandardP>* 홈페이지 우측 하단에 고정됩니다.</StandardP>
                  <DemoSiteImg src={eventImg2} alt="이벤트 타입 팝업 이미지" onClick={() => setSiteModal(true)}/>
                </WrapDemoImg>
              }
              {typeCode === "3" && 
                <WrapDemoImg>
                  <StandardP>* 홈페이지 하단에 고정됩니다.</StandardP>
                  <DemoSiteImg src={eventImg3} alt="모달 타입 팝업 이미지" style={{width: "650px"}}/>
                </WrapDemoImg>
              }
              {/* {typeCode === "4" && 
                <WrapDemoImg>
                  <DemoSiteImg big src={eventImg4} alt="로고 타입 이미지" style={{width: "650px"}}/>
                </WrapDemoImg>
              } */}
              {typeCode === "5" && 
                <WrapDemoImg>
                  <StandardP>* 설치 도메인이 https만 가능합니다.</StandardP>
                  <StandardP>* 현재 개발 중인 서비스로 설치 불가합니다.</StandardP>
                  <DemoSiteImg big src={eventImg5} alt="바로가기 타입 이미지" style={{width: "650px"}}/>
                </WrapDemoImg>
              }
            </WrapInputDiv2>
            <WrapInputDiv2>
              <Label htmlFor="">6. Script 복사</Label>
              {copyScript && 
                <TxtBox readOnly={true} value={copyScript}></TxtBox>
              }
              <WrapButton>
                {!copyScript && <BeforeCopy>복사하기</BeforeCopy>}
                {copyScript && (
                  <AfterCopy
                    handleCopyScript={() => {
                      handleCopyScript(copyScript);
                    }}
                  >
                    복사하기
                  </AfterCopy>
                )}
              </WrapButton>
            </WrapInputDiv2>
            {/* {typeCode === "4" &&
              <>
                <WrapInputDiv2>
                  <Label htmlFor="">7. ServiceWorker 파일 다운로드</Label>
                  <StandardBox>
                      <PsdDiv>
                        <PsdLink href={"#none"} download={"아이프로모션_이미지_레이아웃_가이드"} name="아이프로모션_이미지_레이아웃_가이드">
                          <span>ipromotion_ServiceWorker</span>
                          <PsdImg src={downCloud} alt="레이아웃 다운로드 아이콘" />
                        </PsdLink>
                      </PsdDiv>
                      <StandardP>* Service Worker 파일을 다운받아 웹사이트의 Root 경로(/)에 저장하세요.</StandardP>
                      <StandardP style={{color:`${error1}`}}>* 파일명을 수정하지 마세요.</StandardP>
                    </StandardBox>
                </WrapInputDiv2>
                <WrapInputDiv last>
                  <Label htmlFor="">8. ServiceWorker 업로드 경로</Label>
                  <Input type="text" readOnly value={myPushProject.projectUrl.includes("https://") ? myPushProject.projectUrl + "promotion-sw.js" : "https://" +  myPushProject.projectUrl + "promotion-sw.js"}/>
                </WrapInputDiv>
              </>
            } */}
            
          </form>
        </DemoArticle>
      </PageWrapper>
    </Layout>
  );
}


const PageWrapper = styled.section`
  padding: 40px 40px 0;
  gap: 40px;
`;
const DashbaordH2 = styled.h2`
  color: ${primary5};
  font-size: 32px;
  font-weight: 700;
`
const Message = styled.p`
  color: ${grey7};
  font-size: 14px;
  padding: 16px 0 40px;
`;
const TopAlign = styled.ul`
  display: flex;
  gap: 10px;
  position: relative;
  margin-bottom: 40px;
  justify-content: space-between;

  ::after {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 2px;
    background-color: ${grey4};
    bottom: -20px;
    left: 0;
  }
`;
const WrapHomepages = styled.ul`
  display: flex;
  gap: 10px;
  position: relative;
  align-items: center;
`;

const TypeClickInput = styled.div`
  display: flex;
  position: relative;
`
const CategoryInputArrow = styled.img`
  width: 10px;
  height: 10px;
  position: absolute;
  right: 13px;
  top: 15px;
  rotate: ${props => props.up ? "180deg" : null};
`
const DemoArticle = styled.article`
  width:100%;
  height: auto;
  margin: 60px auto;
  box-sizing: border-box;
  background: ${grey1};
  position: relative;
  padding: 0 20px;
`
const WrapInputDiv = styled.div`
  display: flex;
  gap: 60px;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;
  width: 100%;
  border-bottom: 1px solid ${grey4};
  border-bottom: ${props => props.last ? null : "1px solid ${grey4}"};

`
const WrapInputDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;
  border-bottom: 1px solid ${grey4};
  border-bottom: ${props => props.last ? null : "1px solid ${grey4}"};
`

const Input = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  color: ${grey7};
  width: calc(100% - 270px);
`
const Label = styled.label`
  font-size: 16px;
  color: ${grey9};
  font-weight: 600;
  width: 220px;
`

const TxtBox = styled.textarea`
  width:calc(100% - 40px);
  height: 40px ;
  background-color: ${grey3};
  border-radius: 8px;
  padding: 20px;
  font-size: 14px;
  color: ${grey8};
  border: none;
  overflow-y: scroll;
  display: block;
  resize: none;
  outline: none;
  
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${primary3};
    border-radius: 4px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: none;
    border-radius: 4px;
  }
`

const WrapButton = styled.div`
  width: 180px;
  margin: 0px auto 32px;
  border-radius: 32px;
`;

const WrapDemoImg = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: ${grey1};
  margin: 0;
  overflow: hidden;
`
const DemoSiteImg = styled.img`
  width: ${props => props.big ? "320px" : "250px"};
  display: block;
  border-radius: 16px;
  cursor: pointer;
`
const SiteBtn = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  border: none;
  margin: 0 0 12px;
  padding: 6px 14px;
  border-radius: 32px;
  background: ${grey6};
  color: ${grey1};
  line-height: 1;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`

const ArrowIcon = styled.img`
  width: 20px;
`
// 사이트 데모에 적용해보기 
const SiteDemoModal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0,0,0,0.4);
`

const WrapIframe = styled.div`
  width: 450px;
  height: 480px;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
`

const CloseIframeBtn = styled.button`
  position: absolute;
  display: block;
  width: 100px;
  left: 50%;
  right: 50%;
  bottom: -12px;
  transform: translateX(-50%);
  color: ${grey1};
  font-size: 12px;
  font-weight: 600;
`

// 파일 다운로드
const StandardBox = styled.div`
width: 100%;

`
const StandardP = styled.p`
// margin-left: 130px;
color: ${grey6};
font-size: 12px;
font-weight: 500;
line-height: 1.2;
`

const PsdDiv = styled.div`
width: 240px;
height: 35px;
border-radius: 8px;
border: 1px solid ${primary2};
display: flex;
align-items: center;
justify-content: flex-start;
margin: 0 0 12px 0;
box-sizing: border-box;
padding: 12px;
`
const PsdLink = styled.a`
width: 100%;
color: ${primary3};
font-size: 12px;
font-weight: 400;
text-decoration: none;  
display: flex;
align-items: center;
justify-content: space-between;
`
const PsdImg = styled.img`
width: 22px;
`