import styled from "styled-components"
import { grey4, grey6, grey8 } from "../../constants/color";
import secessionImg from '../../assets/images/img-secession.png'
import { GoHomeBtn, GoPrevPageBtn } from "../../components/buttons/NotfoundButtons";
import { useNavigate } from "react-router-dom";

const Section = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  font-family: "Pretendard";
`;


const Box = styled.div`
  display: flex;
  flex-direction:column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 60px;
`;

const Text = styled.p`
  font-weight: 700;
  font-size: 72px;
  color:${grey4}
`;

const Text2 = styled.p`
  font-weight: 700;
  font-size: 28px;
  margin-top: 16px;
  color: ${grey8};
  text-align: center;
`;

const Text3 = styled.p`
  font-size: 18px;
  margin-top: 12px;
  color: ${grey6};
  margin-bottom: 40px;
  text-align: center;
  line-height: 1.2;
`;

const Img = styled.img`
  width: 494px;
`;

const WrapButtons = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`


export default function CompleteSecession() {
  const nav = useNavigate();

  return (
      <Section>
        <h1 className='ir'>에러페이지</h1>
          <Box>
            <Img src={secessionImg} alt="" />
            <div>
              <Text2>회원탈퇴가 되었습니다.</Text2>
              <Text3>그 동안 DMPUSH 서비스를 이용해주셔서 감사합니다. <br/> 보다 나은 서비스를 위해 발전하는 DMPUSH가 되겠습니다.</Text3>
              <WrapButtons>
                <GoHomeBtn handleGoHome={() => {nav('/')}}>로그인화면으로 이동</GoHomeBtn>
              </WrapButtons>
            </div>
          </Box>
      </Section>
  )
}
