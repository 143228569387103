import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { instanceAxios } from "../../api/axios";
import {
  AlertMessage,
  IsAlertOpen,
  MyCategory,
  MyProject,
  MyPushProject,
} from "../../atom/Atom";
import {
  grey11,
  grey1,
  grey2,
  primary4,
  grey5,
  grey3,
  grey6,
  primary3,
} from "../../constants/color";
import { getCookie } from "../../api/member/logout";
import { InputGroup } from "../inputs/InputGroups";
import closeModalIcon from "../../assets/images/ico-close-modal.svg"
import ErrorLabel from "../../error/ErrorLabel";
import ErrorAlert from "../../error/ErrorAlert";


const ProjectModal = ({setIsOpenModal}) => {
  const [step, setStep] = useState(1);
  const [homepage, setHomepage] = useState("");
  const [scdCatList, setScdCatList] = useState([]);
  const [cat1, setCat1] = useState("");
  const [cat2, setCat2] = useState("");
  const [url, setUrl] = useState("");
  const [myProject, setMyProject] = useRecoilState(MyProject);
  const [myCategory, setMyCategoy] = useRecoilState(MyCategory);
  const [myPushProject, setMyPushProject] = useRecoilState(MyPushProject);
  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);
  // error Type
  const [isErrorType, setIsErrorType] = useState(null);
  // error Func
  const commonErrorPopup = (msg, type) => {
    setIsAlertOpen(true);
    setAlertMessage(msg);
    // 함수는 () => 함수이름
    // 바로 작동하면 안됨
    setIsErrorType(type);
  };

  // Step 01 > Step 02
  const goStep02 = (e) => {
    if(!cat1) {
      return commonErrorPopup("1차 카테고리를 선택해주세요.", null);
    }else {
      setStep(2);
    }
  };

  // Step 01 < Step 02
  const goStep01 = () => {
    return setStep(1);
  };
  
  const handleSelectFisrtCat = (code) => {
    setCat1(code); 
    getSecondCategory(code);
  }

  useEffect(() => {
    console.log("📁1차: ",myCategory);
  }, [])
  
  const getSecondCategory = async(code) => {
    try {
      const response = await instanceAxios.get(`/category/list/${code}` ,{
        headers: {
          'Cache-Control': 'max-age=1000'
        }
      });
      setScdCatList(response.data.data);
    } catch (err) {
      console.error(err);
    }
  }

  const handleSelectCat = () => {
    if(!cat1) {
      return commonErrorPopup("카테고리를 선택해주세요.", null);
    } else {
      // setIsOpenModal(false);
      registerCateogry();
      return;
    }
  }



  const [resultErr, setResultErr] = useState([]);
  const [idxErr, setIdxErr] = useState([]);
  // --- 최종 등록---
  const registerCateogry = async() => {
    setResultErr([]);
    setIdxErr([]);
    try {
      const catNum = cat2 ? cat2 : cat1;
      const response = await instanceAxios.post(`/category/${catNum}`, {
        idx : myPushProject.pid
      });
      if(response.status === 200) {
        setIsOpenModal(false);
        checkProject();
      }
     
    } catch (err) {
      const errData = err.response.data.data;
      if(errData.result) setResultErr(errData.result);
      if(errData.idx) setIdxErr(errData.idx);
    }
  }

  const checkProject = async () => {
    try {
      const response = await instanceAxios.get("/all");
      if (response.status === 200) {
        // 1. 프로젝트 리스트 recoil 담기.
        setMyProject(response.data); 
        // 1차 카테고리 불러오기
        // 2. 현재 선택된 프로젝트 recoil 담기
        if (
          response.data.filter((item) => item.expiryDate === null)
          .length > 0
          ) {
          setMyPushProject(
            response.data.filter((item) => item.expiryDate === null)[response.data.length - 1]
            );
          } 
      }
    } catch (err) {
      // login yet
      console.error(err); 
    }
  };

  // --- 카테고리 1단계 ----
  const renderMakeHomeModal = () => {
    return (
      <WrapContents>
        <form action="post">
          {myCategory.map(({ name, code }) => {
            if (code === cat1) {
              return (
                <div key={code}>
                  <SelectCatContents>
                    {name}
                  </SelectCatContents>                    
                </div>
              );
            } else {
              return (
                <div key={code}>
                <CatContents onClick={() => handleSelectFisrtCat(code)}>
                  {name}
                </CatContents>
                </div>
              );
            }
          })}
        </form>
      </WrapContents>
 
    );
  };

  // --- 카테고리 2단계 ----
  const renderMakeCatModal = () => {
    return (
      <WrapContents>
        <form action="post">
          {scdCatList.map(({ name, code, parentCode }) => {
            if (code === cat2) {
              return (
                <div key={code}>
                  <SelectCatContents>
                    {name}
                  </SelectCatContents>                    
                </div>
              );
            } else {
              return (
                <div key={code}>
                <CatContents onClick={() => setCat2(code)}>
                  {name}
                </CatContents>
                </div>
              );
            }
          })}
        </form>
      </WrapContents>
    );
  };

  return (
    <Wrapper>
      <Modal>
      {resultErr.length > 0 && <ErrorAlert data={resultErr} open={true}/>}
        <ModalWrapper>
          {/* <>{step}</> */}
          <Title>홈페이지 카테고리</Title>
          <SubTitle>운영중인 사이트의 서비스 종류를 선택해주세요</SubTitle>
          {idxErr.length > 0 && <ErrorLabel data={idxErr}/>}
          <ModalContent>
            {step === 1 ? renderMakeHomeModal() : renderMakeCatModal()}
          </ModalContent>
        </ModalWrapper>
        <ButtonWrapper>
          {step === 1 ? (
            <div>
              {scdCatList.length > 0 && <Button onClick={goStep02}>다음</Button>}
              {scdCatList.length === 0 && <Button onClick={handleSelectCat}>선택 완료</Button>}
            </div>
          ) : (
            <div style={{ display: "flex", gap: "12px" }}>
              <Button onClick={goStep01}>뒤로가기</Button>
              <Button onClick={handleSelectCat}>선택 완료</Button>
            </div>
          )}
        </ButtonWrapper>
      </Modal>
    </Wrapper>
  );
};

export default ProjectModal;

const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Pretendard";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${grey1};
  width: 560px;
  padding: 12px 0;
  border-radius: 8px;
`;

const Title = styled.h2`
  color: ${grey11};
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 12px;
  align-items: center;
`;
const SubTitle = styled.h2`
  color: ${grey6};
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 12px;
  align-items: center;
`;
const WrapContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CatContents = styled.div`
  width: 324px;
  display: flex;
  padding: 10px;
  margin: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${grey5};
  &:hover {
    border: 1px solid ${primary3};
  }
`;
const SelectCatContents = styled.div`
  width: 324px;
  display: flex;
  padding: 10px;
  margin: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${primary3};
  color: ${grey1};
`;
const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 16px 16px 0 0;
  font-family: "Pretendard";
`;

const CloseModal = styled.p`
  position: absolute;
  right: 4px;
  top: -22px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  padding: 16px 24px 16px 16px;
  pointer-events: auto;
  border-radius: 8px;
  outline: 0;
`;
const ProjectInputWrap = styled.div`
  width: 399px;
  margin-top: 20px;
`;
const ButtonWrapper = styled.div`
  width: 520px;
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`;
const Button = styled.div`
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  background: ${grey3};
  border: 1px solid ${grey5};
  border-radius: 24px;
  padding: 10px 12px;
  cursor: pointer;
  &:hover {
    background-color: ${primary4};
    color: ${grey1};
  }
`;