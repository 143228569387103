import styled from "styled-components";
import SizeSlider from "../../slider";
import { grey2, grey3, grey4, grey5, grey6, grey8, primary1, primary3, primary4 } from "../../../constants/color";
import { InputGroup } from "../../inputs/InputGroups";
import { useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { SketchPicker } from "react-color";
import ColorPicker from "../../ColorPicker";
import { useRecoilState } from "recoil";
import { Temp_cont_align, Temp_cont_color, Temp_cont_size, Temp_cont_txt, Temp_cont_weight } from "../../../atom/templeteAtom";



export default function TemplateText() {
  // ----- textarea ------ 
  const [temp_text, set_temp_text] = useRecoilState(Temp_cont_txt);
  const [title, setTitle] = useState(temp_text.title);
  const [content, setContent] = useState(temp_text.content);
  const titleRef = useRef();
  const contnetRef = useRef();
  const resizingWriting = useCallback((ref) => {
    if(ref === null || ref.current === null) {
      return;
    }
    ref.current.style.height = '37px'
    ref.current.style.height = ref.current.scrollHeight + "px";
  }, []);
  
  
  // 제목 글자수 제한
  useEffect(() => {
    resizingWriting(titleRef);
    if(title.length > 100) {
      setTitle(titleRef.current.value.substr(0, 100));
      titleRef.current.value = title.substring(0, 100);
      return;
    } 
  },[title])
  // 내용 글자수 제한
  useEffect(() => {
    resizingWriting(contnetRef);
    if(content.length > 100) {
      setContent(content.substring(0, 100));
      contnetRef.current.value = content.substring(0, 100);
      return;
    }; 
  },[content]);

  useEffect(() => {
    set_temp_text({title: title, content: content});
  },[title, content])

  // ----- color picker -------
  const [temp_color, set_temp_color] = useRecoilState(Temp_cont_color); 
  const [titleColor, setTitleColor] = useState(temp_color.title);
  const [contColor, setContColor] = useState(temp_color.content);
  const [titlePicker, setTitlePicker] = useState(false);
  const [contPicker, setContPicker] = useState(false);

  const changeTitleColor = (color) => {
    setTitleColor(color.hex);
    set_temp_color({title: color.hex, content: contColor});
  };

  const changeContentColor = (color) => {
    setContColor(color.hex);
    set_temp_color({title: titleColor, content: color.hex});
  };

  const closeColorPicker = (e) => {
    if(titlePicker) setTitlePicker(false);
    if(contPicker) setContPicker(false);
    return false;
  };

  //  ----------- 글씨 크기 ---------
  const [temp_size, set_temp_size] = useRecoilState(Temp_cont_size);
  const [title_size, set_title_size] = useState(temp_size.title);
  const [content_size, set_content_size] = useState(temp_size.content);
  useEffect(() => {
    set_temp_size({title: title_size, content: content_size});
  },[title_size, content_size]);


  // ----- 글씨 두께 -------
  const [temp_weight, set_temp_weight] = useRecoilState(Temp_cont_weight);
  const [title_weight, set_title_weight] = useState(temp_weight.title);
  const [content_weight, set_content_weight] = useState(temp_weight.content);
  useEffect(() => {
    set_temp_weight({title: title_weight, content: content_weight});
  },[title_weight, content_weight]);


  // ---- 글씨 정렬 -----
  const [temp_align, set_temp_align] = useRecoilState(Temp_cont_align);
  const [title_align, set_title_align] = useState(temp_align.title);
  const [content_align, set_content_align] = useState(temp_align.content);
  const selectTitleAlgin = (e, align) => {
    e.stopPropagation();
    set_title_align(align);
  }
  const selectContentAlign = (e, align) => {
    e.stopPropagation();
    set_content_align(align);
  }
  useEffect(() => {
    set_temp_align({title: title_align, content: content_align});
  },[title_align, content_align]);
  return (
    <>
    {/* ------ 제목 --------- */}
      <WrapCont onClick={closeColorPicker}>
        <div style={{position: 'relative', width:'fit-content', zIndex: '1'}}>
          <SubTitle>제목 설정</SubTitle>
        </div>
        <AlignSet>
          <SetTitle>내용</SetTitle>
          <WrapController>
          <ContentTxtarea 
            maxLength={100}
            placeholder="최대 100자 입력 가능"
            defaultValue={title}
            ref={titleRef}
            onChange={(e) => {setTitle(e.target.value)}}
            ></ContentTxtarea>
          </WrapController>
        </AlignSet>
        <AlignSet>
          <SetTitle>크기</SetTitle>
          <WrapController style={{display: "flex"}}onClick={(e) => set_title_size(e.target.value)}>
            <WrapRadio>
              <RadioInput type="radio" name="t-size" value="16px" id="t-small" />
              <Label htmlFor="t-small" style={{fontSize: '14px'}}>작게</Label>
            </WrapRadio>
            <WrapRadio>
              <RadioInput type="radio" name="t-size" value="18px" id="t-medium"/>
              <Label htmlFor="t-medium" style={{fontSize: '16px'}}>보통</Label>
            </WrapRadio>
            <WrapRadio>
              <RadioInput type="radio" name="t-size" value="20px" id="t-big"/>
              <Label htmlFor="t-big" style={{fontSize: '18px'}}>크게</Label> 
            </WrapRadio>
          </WrapController>
        </AlignSet>
        <AlignSet>
          <SetTitle>두께</SetTitle>
          <WrapController style={{display: "flex"}} onClick={(e) => {set_title_weight(e.target.value)}}>
            <WrapRadio>
              <RadioInput type="radio" name="t-weight" value="normal" id="t-normal"/>
              <Label htmlFor="t-normal" style={{fontWeight: 'normal'}}>보통</Label>
            </WrapRadio>
            <WrapRadio>
              <RadioInput type="radio" name="t-weight" value="bold" id="t-bold"/>
              <Label htmlFor="t-bold" style={{fontWeight: 'bold'}}>굵게</Label> 
            </WrapRadio>
          </WrapController>
        </AlignSet>
        <AlignSet>
          <SetTitle>색상</SetTitle>
          <WrapController>
            <ColorPicker color={titleColor} setColor={setTitleColor} isOpen={titlePicker} setOpen={() => setTitlePicker(true)} changeColor={changeTitleColor}/>
          </WrapController>
        </AlignSet>
        <AlignSet>
          <SetTitle>정렬</SetTitle>
          <WrapController>
            <AlignUl>
              <AlignLi start='true' onClick={(e) => selectTitleAlgin(e,'left')}>
                <AliginSpan long></AliginSpan>
                <AliginSpan middle></AliginSpan>
                <AliginSpan long></AliginSpan>
                <AliginSpan middle></AliginSpan>
                <AliginSpan long></AliginSpan>
              </AlignLi>
              <AlignLi center onClick={(e) => selectTitleAlgin(e,'center')}>
                <AliginSpan long></AliginSpan>
                <AliginSpan middle></AliginSpan>
                <AliginSpan long></AliginSpan>
                <AliginSpan middle></AliginSpan>
                <AliginSpan long></AliginSpan>
              </AlignLi>
              <AlignLi end='true' onClick={(e) => selectTitleAlgin(e,'right')}>
                <AliginSpan long></AliginSpan>
                <AliginSpan middle></AliginSpan>
                <AliginSpan long></AliginSpan>
                <AliginSpan middle></AliginSpan>
                <AliginSpan long></AliginSpan>
              </AlignLi>
            </AlignUl>
          </WrapController>
        </AlignSet>
      </WrapCont>    

      {/* ------ 내용 --------- */}
      <WrapCont onClick={closeColorPicker}>
        <div style={{position: 'relative', width:'fit-content', zIndex: '1'}}>
          <SubTitle>내용 설정</SubTitle>
        </div>
        <AlignSet>
          <SetTitle>내용</SetTitle>
          <WrapController>
            <ContentTxtarea 
              maxLength={100}
              placeholder="최대 100자 입력 가능"
              defaultValue={content}
              ref={contnetRef}
              onChange={(e) => {setContent(e.target.value)}}
              ></ContentTxtarea>
          </WrapController>
        </AlignSet>
        <AlignSet>
          <SetTitle>크기</SetTitle>
          <WrapController style={{display: "flex"}} onClick={(e) => set_content_size(e.target.value)}>
            <WrapRadio>
              <RadioInput type="radio" name="c-size" value="14px" id="c-small"/>
              <Label htmlFor="c-small" style={{fontSize: '14px'}}>작게</Label>
            </WrapRadio>
            <WrapRadio>
              <RadioInput type="radio" name="c-size" value="16px" id="c-medium"/>
              <Label htmlFor="c-medium" style={{fontSize: '16px'}}>보통</Label>
            </WrapRadio>
            <WrapRadio>
              <RadioInput type="radio" name="c-size" value="18px" id="c-big"/>
              <Label htmlFor="c-big" style={{fontSize: '18px'}}>크게</Label> 
            </WrapRadio>
          </WrapController>
        </AlignSet>
        <AlignSet>
          <SetTitle>두께</SetTitle>
          <WrapController style={{display: "flex"}} onClick={(e) => {set_content_weight(e.target.value)}}>
            <WrapRadio>
              <RadioInput type="radio" name="c-weight" value="normal" id="c-normal"/>
              <Label htmlFor="c-normal" style={{fontWeight: 'normal'}}>보통</Label>
            </WrapRadio>
            <WrapRadio>
              <RadioInput type="radio" name="c-weight" value="bold" id="c-bold"/>
              <Label htmlFor="c-bold" style={{fontWeight: 'bold'}}>굵게</Label> 
            </WrapRadio>
          </WrapController>
        </AlignSet>
        <AlignSet>
          <SetTitle>색상</SetTitle>
          <WrapController>
            <ColorPicker color={contColor} setColor={setContColor} isOpen={contPicker} setOpen={() => setContPicker(true)} changeColor={changeContentColor}/>
          </WrapController>
        </AlignSet>
        <AlignSet>
          <SetTitle>정렬</SetTitle>
          <WrapController>
            <AlignUl>
              <div>
                <AlignLi start='true' onClick={(e) => selectContentAlign(e,'left')}>
                  <AliginSpan long></AliginSpan>
                  <AliginSpan middle></AliginSpan>
                  <AliginSpan long></AliginSpan>
                  <AliginSpan middle></AliginSpan>
                  <AliginSpan long></AliginSpan>
                </AlignLi>
              </div>
              <AlignLi center onClick={(e) => selectContentAlign(e,'center')}>
                <AliginSpan long></AliginSpan>
                <AliginSpan middle></AliginSpan>
                <AliginSpan long></AliginSpan>
                <AliginSpan middle></AliginSpan>
                <AliginSpan long></AliginSpan>
              </AlignLi>
              <AlignLi end='true' onClick={(e) => selectContentAlign(e,'right')}>
                <AliginSpan long></AliginSpan>
                <AliginSpan middle></AliginSpan>
                <AliginSpan long></AliginSpan>
                <AliginSpan middle></AliginSpan>
                <AliginSpan long></AliginSpan>
              </AlignLi>
            </AlignUl>
          </WrapController>
        </AlignSet>
      </WrapCont>    
    </>
  )
}

const WrapCont = styled.div`
  margin: 40px 0 80px;
`
const AlignSet = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  gap: 20px;
  margin: 0 0 60px;
`
const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: ${grey8};
  width: 100%;
  padding-bottom: 40px;
  ::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    top: 8px;
    padding: 0 10px;
    background: ${primary1};
    width:100%;
    height: 14px;
    opacity: 0.7;
    z-index: -1;
  }
`
const SetTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: ${grey8};
  width: 200px;
`
const WrapController = styled.div`
  width: 100%;
  position: relative;
`

// ------------ 라디오 css -----------
const Label = styled.label`
  font-size: 16px;
  margin-right: 14px;
  cursor: pointer;
`
  
const RadioInput = styled.input`
  appearance: none;
  border: max(2px, 0.1em) solid gray;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  transition: border 0.2s ease-in-out;

  &:checked {
    border: 0.4em solid ${primary3};
  }
  
  /* &:focus-visible {
    outline-offset: max(2px, 0.1em);
    outline: max(2px, 0.1em) dotted  ${primary3};
  } */

  &:hover {
    box-shadow: 0 0 0 max(4px, 0.2em) lightgray;
    cursor: pointer;
    }

`

const WrapRadio = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const ContentTxtarea = styled.textarea`
  width: 80%;
  height: 37px;
  border:  1px solid ${grey5};
  outline: none;
  border-radius: 4px;
  overflow: hidden;
  resize: none;
  padding: 10px 12px;
  box-sizing: border-box;
  line-height: 1.3;
  :focus {
    border:  1px solid ${primary4};
  }
  ::placeholder {
    font-size: 12px;
  }
`

const AlignUl = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
`
const AlignLi = styled.li`
  background: ${grey3};
  width: 33px;
  height: 36px;
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${props => (props.start ? 'flex-start' : '')};
  align-items: ${props => (props.center ? 'center' : '')};
  align-items: ${props => (props.end ? 'flex-end' : '')};

  &:hover {
    background: ${primary1};
  }
`

const AliginSpan = styled.span`
  display: block;
  height: 3px;
  border-radius: 16px;
  background: ${grey6};
  width: ${props => props.long ? "100%" : null};
  width: ${props => props.middle ? "55%" : null}; 
  
`
